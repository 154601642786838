import React from 'react'

function Sample_report() {
  return <div style={{width:"100vw",height:"100vh"}}>
  <iframe src='https://firebasestorage.googleapis.com/v0/b/aiwell.appspot.com/o/tests%2Fbugspeaks%2FBugSpeaks%20sample%20report(retail).pdf?alt=media&token=051abdb7-b40d-4b85-b7ee-0e766091d522' style={{width:"100vw",height:"100vh"}}>

  </iframe>
  </div>
}

export default Sample_report