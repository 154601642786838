import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { setAuthToken } from '../Auth/setAuthToken';
import CallIcon from '@mui/icons-material/Call';
import { Button } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useNavigate } from 'react-router-dom';
import EventIcon from '@mui/icons-material/Event';
import VideocamIcon from '@mui/icons-material/Videocam';
import Lottie from 'lottie-react';
import noappointment from '../Animations/noappointment.json'
import moment from 'moment/moment';
function CompletedApppointments() {
  let userdetails = localStorage.getItem('Userdetails')
  let refreshtoken = JSON.parse(userdetails)
  let [count, setCount] = useState(0)
  let [appointments, setappointments] = useState([])
  let navigate = useNavigate()

  const getApppointments = async () => {
    try {
      let Token = localStorage.getItem('jwt-token')
      setAuthToken(Token)

      let res = await axios.post('https://api.ayurai.in/api/data/getAllAppointments')
      if (res.status === 200) {
        let ordered = res.data.filter((e) => {
          if (e.Data.appointment_status === 'completed') {
            return e
          }
        })
        setappointments(ordered)
      }
    }
    catch (error) {
      // renew()

      if (error?.response?.data?.error.message === 'jwt expired' || error?.status === 500) {
        renew()
      }
    }
  }

  let renew = async () => {

    try {
      let res = await axios.post(`https://api.ayurai.in/auth/renew`, {
        email: refreshtoken.email,
        token: refreshtoken.refreshToken
      })

      if (res.status === 200) {
        let data = res.data
        localStorage.setItem('jwt-token', data.access_token)

        setCount(count + 1)

      }
    }
    catch (error) {
      // console.log(error,'ll')
    }
  }

  useEffect(() => {
    getApppointments()
  }, [count])

  return <div>
    {
      appointments.length > 0 ?
        <div className='appointment-cards'>
          {
            appointments.sort((a, b) => moment(b.Data.appointment_date) - moment(a.Data.appointment_date) ).map((e, i) => {
              return <div className='Appointment-card' onClick={() => { navigate(`/Appointment/${e.Data.appointmentID}`) }}>
                <div className='doc-card' key={i}  >
                  <img src={e.Data.doctor_photo} ></img>
                  <div>
                    <p>{e.Data.doctor_name}</p>
                    <p>{e.Data.doctor_speciality}</p>
                  </div>
                  <div className='modeofconsultation'>{
                    e.Data.consultation_type === 'Video' ? <VideocamIcon></VideocamIcon> : <CallIcon></CallIcon>}</div>
                </div>

                <div style={{ display: "flex", alignItems: "center", gap: "1rem",alignSelf:"flex-start" }}>
                  <p style={{ width: "1rem", height: "1rem", borderRadius: "50%", backgroundColor: "blue" }}></p> <p>{e.Data.appointment_status}</p>
                </div>
                <div style={{ display: 'flex', justifyContent: "space-evenly",alignSelf:"flex-start",gap:"1rem" }}>
                  <div style={{ display: "flex", gap: "1rem" }}>
                    <EventIcon style={{ color: "lightgreen" }}></EventIcon>
                    <p>{e.Data.appointment_date}</p>
                  </div>
                  <div style={{ display: "flex", gap: "1rem" }}>
                    <AccessTimeIcon style={{ color: "lightgreen" }}></AccessTimeIcon>
                    <p>{e.Data.appointment_time}</p>
                  </div>
                </div>


              </div>
            })
          }
        </div> :
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
          <Lottie animationData={noappointment} loop={true} className='AppointmentBookedanime mt-0' ></Lottie>
          <p id='noappointment'>You have no Appointment</p>
        </div>
    }
  </div>
}

export default CompletedApppointments