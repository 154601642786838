import React from 'react'
import { useEffect, useState, useContext, useRef } from 'react';
import { Button } from '@mui/material';
import RestoreIcon from '@mui/icons-material/Restore';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { addToCart } from './CartSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux/es/hooks/useSelector'
import { Container, Title, Accordion, createStyles, rem } from '@mantine/core';
import { IconButton } from '@mui/material';
import { contextAPI } from '../Context';
import { useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

function Product_Page() {
  let { id } = useParams()
  let navigate = useNavigate()
  let dispatch = useDispatch()
  const [state, setState] = useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  let data = useContext(contextAPI)
  let cart = useSelector((state) => state.cart)
  const [list, setlist] = useState(0)
  const [backgroundPosition, setBackgroundPosition] = useState('0% 0%');

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    setBackgroundPosition(`${x}% ${y}%`);
  };
  useEffect(() => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
  }, [id])


  const useStyles = createStyles((theme) => ({
    wrapper: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
      minHeight: 650,
    },

    title: {
      marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },

    item: {
      borderRadius: theme.radius.md,
      marginBottom: theme.spacing.lg,
      border: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
        }`,
    },
  }));


  const { classes } = useStyles();

  const handleAddtocart = (Product) => {
    dispatch(addToCart(Product))
  }

  const Buynow = (Product) => {
    dispatch(addToCart(Product))
    navigate("/Cart")

  }
 
  
  useEffect(() => {
    let params = new URL(document.location).searchParams;
    let emailTemplate = params.get("key");
    let productType=params.get('ProductType')
    if (emailTemplate === "Newsletter") {
      if(productType==="Tea"){
        MailTracker('Tea');
      }
      if(productType==='Supplement'){
        MailTracker("Supplements")
      }

    }

  }, []);

  const MailTracker = async (value) => {
    const payload = { key: value };
    try {
      const response = await fetch('https://api.ayurai.in/api/data/NewsLetterMailTracker', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };


  return <>
    <div className='Navbar'>
      <div> <Button onClick={() => {
        navigate(-1)
      }}><ArrowBackIcon /></Button></div>
      <div><IconButton
        onClick={() => {
          navigate('/orders')
        }}><RestoreIcon /></IconButton><IconButton
          onClick={() => {
            navigate('/Cart')
          }}>{
            cart.cartitems.length > 0 ?
              <StyledBadge badgeContent={cart.cartitems.length}
                color="error">
                <ShoppingCartIcon />
              </StyledBadge> : <ShoppingCartIcon />}</IconButton></div>
    </div>
    <div>
      {data?.products?.filter((s) => {
        if (id == s?.product_id) {
          return s
        }
      }).map((e, i) => {
        return <div className='Product-Page'>
          <div className='Product-image mb-0'>
            {!e.Other_info && <figure className="figure-zoom" onMouseMove={handleMouseMove} style={{ backgroundImage: `url(${e.imgurl})`, backgroundPosition: backgroundPosition }}>
              <img src={e?.imgurl} style={{ backgroundPosition }} />
            </figure>}
            {e?.Other_info?.images && <figure className="figure-zoom" onMouseMove={handleMouseMove} style={{ backgroundImage: `url(${e?.Other_info?.images[list]?.src})`, backgroundPosition: backgroundPosition }}>
              <img src={e?.Other_info?.images[list]?.src} style={{ backgroundPosition }} />
            </figure>
            }
            {e?.Other_info?.images && <div className='listcardofimages'>{e?.Other_info.images.map((g, index) => {
              return <div className='listcardimg' key={index} onClick={() => {
                setlist(index)
              }}>
                <img src={g.src} style={index === list ? { border: "1px solid black" } : {}}></img>
              </div>
            })}</div>}
            <div className='Product-imp-details mb-0' >
              <p className='mb-0'>{e?.name}&nbsp;<span id='qty'>({e?.qty})</span></p>
              <span>{e?.desc}</span>
              <div style={{ display: "flex", gap: "1rem" }} className='mb-0'>
                <p className='mb-0'>₹ {e?.price}</p> {
                  e?.orgprice > 0 ? <p className='mb-0'><del>₹ {e?.orgprice}</del></p> : <></>}</div>
            </div>
          </div>
          <div className='About-section'>
            {e?.Other_info && <div className='Other_info'
            dangerouslySetInnerHTML={{ __html: e?.Other_info.body_html }} />}
            {e?.detaildesc && <p id="Abt-t" className='mb-0'>About the Product</p>}
            {e?.detaildesc && <p id='abt' className='mb-0'> {e?.detaildesc}</p>}
            {e?.keyingredients && <p id="Abt-t" className='mb-0'>Ingredients</p>}
            <div className='ingredients'>
              {e?.keyingredients &&
                e?.keyingredients.map((ingredient, key) => {
                  return <div >
                    <div key={key} className='ingredient'>
                      <img src={ingredient?.img}></img>
                      <p className='mb-0'>{ingredient?.name}</p>
                    </div>
                  </div>
                })
              }
            </div>
            {e?.keybenefits && <p id="Abt-t">Benefits</p>}
            <div className='ingredients'>
              {e?.keybenefits &&
                e?.keybenefits.map((Benefits, k) => {
                  return <div>
                    <div key={k} className='ingredient'>
                      <img src={Benefits?.img}></img>
                      <p className='mb-0'>{Benefits?.name}</p>
                    </div>
                  </div>
                })
              }
            </div>

            {e?.FAQ && <p id="Abt-t">FAQ</p>}
            <div id="faq">
              <Accordion variant="separated">
                {e?.FAQ?.map((faq, index) => {
                  return (
                    <Accordion.Item className={classes.item} key={index} value={faq.ques}>
                      <Accordion.Control>{faq?.ques}</Accordion.Control>
                      <Accordion.Panel>{faq?.ans}</Accordion.Panel>
                    </Accordion.Item>
                  )
                })}
              </Accordion>
            </div>

            <div className='Shop-btns'>

              <Button id='buynowbtn' onClick={() => { Buynow(e) }}>

                <ShoppingBagIcon style={{ color: 'grey' }} />&nbsp;&nbsp;&nbsp;Buy now</Button>

              <Button id='addtocartbtn' onClick={() => {

                toast.info('Product Added to Cart successfully', {
                  position: "top-center",
                  limit: 1,
                  transition: Zoom,
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: false,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark"
                })

                handleAddtocart(e)

              }}>
                <ShoppingCartIcon />&nbsp;&nbsp;&nbsp;Add to Cart</Button>
            </div>
          </div>

        </div>
      })
      }
    </div>
  </>
}

export default Product_Page