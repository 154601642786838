import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { IconButton } from '@mui/material'
import CallIcon from '@mui/icons-material/Call';
import VideocamIcon from '@mui/icons-material/Videocam';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Phone from '../Animations/phoneconsult.json'
import Video from '../Animations/videoconsult.json'
import axios from 'axios'
import Lottie from 'lottie-react'
import { useContext } from 'react'
import { TextField } from '@mui/material'
import { contextAPI } from '../Context';
import { setAuthToken } from '../Auth/setAuthToken'
function ConfirmAppointment() {
  let navigate = useNavigate()
  let { specialcoupon } = useContext(contextAPI)
  const [PromoInput, setpromoinput] = useState('')
  const data = useSelector((state) => state.Appointment.Appointment)
  const Profile = useSelector((state) => state.Profile.User_Profile)
  const [open, setOpen] = useState(false);
  const [price, setPrice] = useState('200')
  let [hash, sethash] = useState('')
  let [Transcation_id, setTranscation] = useState('')
  let userdetails = JSON.parse(localStorage.getItem('Userdetails'))
  const getTimeformat = (format) => {
    return `${format.getDate()}/${format.getMonth() + 1}/${format.toString().split(' ').splice(3, 2).join(' ')}`
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Bookappointment = async () => {
    try {
      let user = localStorage.getItem('Userdetails')
      let userdetails = JSON.parse(user)
      let today = new Date()
      let Token = localStorage.getItem('jwt-token')
      setAuthToken(Token)
      const appointmentID = `APPOINTMENT_${Date.now()}`
      const AppointmentDB = await axios.post('https://api.ayurai.in/api/data/updateAppointments', {
        appointment_time: data?.Dateandtime?.time,
        doctormessage: "na",
        appointment_status: "upcoming",
        doctor_photo: data?.Doctor?.Data?.photo,
        appointment_date: data?.Dateandtime?.Date,
        consultation_type: data?.Payment?.Mode,
        appointment_pay_status: "success",
        history_appointment_time: data?.Dateandtime?.time,
        doctor_speciality: data.Doctor.Data.speciality,
        history_appointment_date: data?.Dateandtime?.Date,
        appointment_pay: specialcoupon.toLowerCase() === PromoInput.toLowerCase() ? "0" : data?.Payment?.Price,
        appointmentID,
        doctor_uid: data?.Doctor?.UID,
        patient_id: userdetails?.id,
        doctor_id: data?.Doctor?.Data?.PID,
        phone: Profile.phone,
        email: userdetails?.email,
        doctor_status: "pending",
        patient_name: Profile?.firstname,
        doctor_name: data?.Doctor?.Data?.name,
        timestamp: getTimeformat(today)
      })



      if (AppointmentDB.status === 200) {
        navigate(`/AppointmentBooked/${appointmentID}`)
      }
    }
    catch (err) {
      console.log(err)
    }
  }


  const checkout = () => {
    var data = {
      key: 'Ey2EbY',
      hash: hash,
      txnid: Transcation_id.toString(),
      amount: price,
      firstname: Profile?.firstname,
      email: userdetails?.email,
      phone: Profile.phone,
      productinfo: 'Aiwell',
      surl: 'http://thirdparty.com/testresponse.php',
      furl: 'http://thirdparty.com/testresponse.php',
    };

    var handlers = {
      responseHandler: function (BOLT) {
        if (BOLT.response.txnStatus == "SUCCESS") {
          Bookappointment()
          console.log('Your payment has been successful');
        }
        if (BOLT.response.txnStatus == "FAILED") {
          console.log('Payment failed. Please try again.');
        }
        if (BOLT.response.txnStatus == "CANCEL") {
          console.log('Payment failed. Please try again.');
        }
      },
      catchException: function (BOLT) {
        console.log('Payment failed. Please try again.', BOLT);
      }
    };
    window.bolt.launch(data, handlers);
  }



  const initiatePayment = async () => {
    try {
      const response = await axios.post('https://api.ayurai.in/api/data/payment/makepayment',
        {
          amount: data.Payment.Price,
          txnid: Date.now().toString(),
          phone: Profile.phone,
          email: userdetails?.email,
          productinfo: "Aiwell",
          firstname: Profile?.firstname,
        });

      if (response.status === 200) {
        sethash(response.data.hash)
        setTranscation(response.data.TranscationID)
        setPrice(data?.Payment?.Price.toString())
        if (specialcoupon.toLowerCase() === PromoInput.toLowerCase()) {
           Bookappointment()
        }
        else{
          handleClickOpen()
        }
      }
    } catch (error) {
      console.error('Error initiating payment:', error.response);
    }
  };


  return <div>
    <div className='Cart-header'>
      <IconButton onClick={() => {
        navigate(-1)
      }}>
        <ArrowBackIcon />
      </IconButton>
    </div>
    <div className='doc-card'>
      <img src={data.Doctor.Data.photo} ></img>
      <div>
        <p>{data.Doctor.Data.name}</p>
        <p>{data.Doctor.Data.speciality}</p>
      </div>
      <div className='modeofconsultation'>{
        data?.Payment?.Mode === 'Video' ? <VideocamIcon></VideocamIcon> : <CallIcon></CallIcon>}</div>
    </div>
    <div className='confirmappointment-page'>
      <h4>Appointment Details</h4>
      <div>
        <div>
          <p>Selected Date</p>
          <p>{data?.Dateandtime?.Date}</p>
        </div>
        <div>
          <p>Selected Time</p>
          <p>{data?.Dateandtime?.time}</p>
        </div>
        <div>
          <p>Phone Number</p>
          <p>{Profile.phone}</p>
        </div>
        <div>
          <p>Enter Promo Code</p>
          <TextField onChange={(e) => setpromoinput(e.target.value)} style={specialcoupon.toLowerCase() === PromoInput.toLowerCase() ? { border: "2px solid green" } : {}} />
          <p style={{ marginTop: '10px', fontSize: "12px", color: "green" }}>{specialcoupon.toLowerCase() === PromoInput.toLowerCase() ? "Free Consultation Code Applied" : ""}</p>
        </div>

        <div>
          <p>Payment details</p>
          <div className='consultation-payment-details'>
            <div>
              <p>Appointment Cost</p>
              <p id='consultperhr' className='mb-0'>consultation fee for 1hour</p>
            </div>
            <div>
              <p>₹ {data?.Payment?.actualPrice}</p>
            </div>
            <div>
              <p>Admin fee</p>
            </div>
            <div>
              <p><del>₹ 0</del></p>
            </div>
            <div>
              <p>To Pay</p>
            </div>
            <div style={{ display: 'flex', gap: "1rem" }}>
              <p><del>₹ {data?.Payment?.actualPrice}</del></p> <p>₹ {specialcoupon.toLowerCase() === PromoInput.toLowerCase() ? 0 : data?.Payment?.Price}</p>
            </div>
          </div>
        </div>
        <div>
          <p>Mode of Consultation</p>
          <div className='consultation-mode'>

            <div>
              <p >{data?.Payment?.Mode}</p>
              <div><p ><del>₹ {data?.Payment?.actualPrice}</del></p><p >₹ {data?.Payment?.Price}</p></div>
              <p style={{ fontWeight: '400' }}>*Inclusive of all Tax</p>
            </div>
            <div className='animecontainer'>
              <Lottie animationData={data?.Payment?.Mode === "Video" ? Video : Phone} className='modeanime'></Lottie>
            </div>
          </div>
        </div>
      </div>

      <div className='mt-2 paynconfirm' >
        <Button style={{ backgroundColor: '#0076BE', color: "white" }} id='bookappointmentbtn' onClick={() => {
          initiatePayment()
        }}>Pay and confirm</Button>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">

          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you want to Proceed Payment ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <>
              <Button onClick={handleClose}>No</Button>
              <Button onClick={checkout}>
                Yes
              </Button>
            </>

          </DialogActions>
        </Dialog>
      </div>
    </div>
  </div>
}

export default ConfirmAppointment