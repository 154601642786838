import React from 'react'
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { toast,Zoom} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import { Email } from '@mui/icons-material';
function Signup() {
    const [showPassword, setShowPassword] = useState(false);
    const [Terms,setTerms]=useState(true)
    const [name,setName]=useState('')
    const [cpassword,setcpassword]=useState("")
    const [email,setEmail]=useState("")
    const [password,setPassword]=useState("")

    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
    const navigate=useNavigate()

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
      '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
      },
      '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
      },
    }));
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
  const Register=async()=>{
      if(name&&Email&&password&&cpassword){
         if(Terms){
           if(password===cpassword){
              try{
                let res=await axios.post('https://api.ayurai.in/auth/signup',{
                  email,
                  name,
                  password
                })
                if(res.status===200){
                  localStorage.setItem('newpass',password)
                  navigate(`/Verify/${email}`)
                }
              }
              catch(error){
                 console.log(error?.response?.data)
              }
           }
           else{
            toast.error("Confirm password Should be same as password", {
              position: "top-center",
              limit: 1,
              transition: Zoom,
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark"
            })
           }
         }
         else{
          toast.error("Please Agree Terms and conditions", {
            position: "top-center",
            limit: 1,
            transition: Zoom,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark"
          })
         }
      }
      else{
        toast.error("All fields are mandatory", {
          position: "top-center",
          limit: 1,
          transition: Zoom,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark"
        })
      }
  }

  return (
  <div className='SignIN_page'>
  <div className='Signin'>
      <h4>Registration</h4>
      <p>Please Enter your Name and mail id</p>
      <TextField id="outlined-basic" label="Name" variant="outlined" onChange={(e)=>{
        setName(e.target.value)
      }}/>
      <TextField id="outlined-basic" label="Email" variant="outlined" onChange={(e)=>{
        setEmail(e.target.value)
      }}/>

      <FormControl variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={showPassword ? 'text' : 'password'}
          onChange={(e)=>{
             setPassword(e.target.value)
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
        />
        
      </FormControl>
      <FormControl variant="outlined">
        <InputLabel htmlFor="fullWidth">Confirm Password</InputLabel>
        <OutlinedInput
          id="fullWidth"
          
          type={showPassword ? 'text' : 'password'}
          onChange={(e)=>{
             setcpassword(e.target.value)
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
        />
        
      </FormControl>
      <Button id='lgbtn' onClick={Register}>Register!</Button>
      <p id='rgacc'>Already have a account ? <span style={{color:" #0d8aed",cursor:"pointer"}}
      onClick={()=>{
        navigate("/Signin")
      }}>Login!</span></p>
     <p id='rgacc'>By pressing register, you agree with our <span style={{color:" #0d8aed",cursor:"pointer"}} onClick={handleClickOpen}>Terms and Conditions</span></p>
  </div>

  <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
       <div className='terms'>
        <h3>Terms and Conditions</h3>
        <img src='./Untitled design.png' id='tcimg'></img>
        <p>our <span style={{color:" #0d8aed",cursor:"pointer"}}><a href='https://ayurai.io/termsandconditionaiwell.html'  target='_Blank'>Terms and Conditions</a></span> and <span style={{color:" #0d8aed",cursor:"pointer"}}><a href='https://www.ayurai.io/privacypolicyaiwell.html' target='_Blank'>Privacy Policy</a></span> have been recently updated to
        contiue using our app,please review and agree to our terms and condition.</p>
        <div style={{display:"flex",justifyContent:"center",alignItems:"center",gap:"1rem"}}> 
        {Terms?<input type='checkbox' checked onClick={()=>{
          setTerms(!Terms)
        }}></input>:<input type='checkbox' onClick={()=>{
          setTerms(!Terms)
        }}></input>}
        <p>I have read and agree to the terms and condition</p>
        </div>
        {Terms?
        <Button id='Termsandcondition' onClick={handleClose}>Continue</Button>:<Button disabled>Continue</Button>
        }
       </div>
      </BootstrapDialog>
</div>
)
}

export default Signup