import React from 'react'
import Otp from '../Animations/enterotp.json'
import OTPInput from 'react-otp-input'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, IconButton } from '@mui/material';
import Lottie from 'lottie-react'
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { contextAPI } from '../Context';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, Zoom } from 'react-toastify';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
function VerifyCode() {
  let navigate = useNavigate()
  let contextdata = useContext(contextAPI)
  let { Email } = useParams()

  const ConfirmPassword = async (code) => {
    try {
      let newpassword = localStorage.getItem('newpass')
      let res = await axios.post('https://api.ayurai.in/auth/code', {
        user: Email,
        code,
      })

      if (res.status === 200) {
        let login = await axios.post('https://api.ayurai.in/auth/login', {
          email: Email,
          password: newpassword
        })

        localStorage.removeItem('newpass')
        if (login.status === 200) {
          navigate('/Profile')
          localStorage.setItem('Userdetails', JSON.stringify({
            accesToken: login?.data?.accesToken,
            id: login.data?.id,
            email: Email,
            Name: login?.data?.Name,
            idToken:login?.data?.idToken,
            refreshToken: login?.data?.refreshToken
          }))
          localStorage.setItem('jwt-token', login?.data?.accesToken)
        }
      }
    }
    catch (error) {
      toast.error(error?.response?.data?.error?.message, {
        position: "top-center",
        limit: 1,
        transition: Zoom,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      })
    }
  }

  const SubmitOtp = () => {
    if (contextdata.otp.length === 6) {
          ConfirmPassword(contextdata.otp)
    }
    else {
      toast.error("You can't continue without entering a 6 digit OTP  Code.", {
        position: "top-center",
        limit: 1,
        transition: Zoom,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      })
    }
  }

  return <>
    <div className='Cart-header'>
      <IconButton onClick={() => {
        navigate(-1)
      }}>
        <ArrowBackIcon />
      </IconButton>
    </div>
    <div className='fp-page'>
      <Lottie animationData={Otp} loop={true} id="fp-animation"></Lottie>
      <div className='fg-content'>
        <h2>Enter OTP</h2>
        <p>A 6 digit code has been sent to your mail id</p>
        <OTPInput
          value={contextdata.otp}
          onChange={(e) => {
            contextdata.setOtp(e)
          }}
          numInputs={6}
          inputStyle="otpinput"
          containerStyle={{ padding: "5px", gap: "1rem" }}
          renderInput={(props) => <input {...props} />}
        />
        <Button id='lgbtn' onClick={SubmitOtp} >submit</Button>
      </div>
    </div>
  </>
}

export default VerifyCode