import React from 'react'
import { useContext, useState, useEffect } from 'react';
import { contextAPI } from '../Context';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SwipeableViews from 'react-swipeable-views';
import { getDatabase, ref, onValue, } from "firebase/database";
import { IconButton } from '@mui/material';
function Pranayama_Page() {
    let { step } = useParams()
    let navigate = useNavigate()
    const [activeStep, setActiveStep] = useState(0);
    const [steps, setSteps] = useState([])
    const maxSteps = steps?.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const getSteps = async () => {

        try {
            const db = getDatabase();
            const Ref = ref(db, `/pranayama/${step}`);
            onValue(Ref, async (snapshot) => {
                try {
                    const data = await snapshot.val()
                    setSteps(Object.values(data.steps))

                }
                catch (error) {
                    console.log(error)
                }

            })

        }
        catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        getSteps()
    }, [step])

    useEffect(() => {
        let params = new URL(document.location).searchParams;
        let emailTemplate = params.get("key");
        if (emailTemplate === "Newsletter") {
            MailTracker('Pranayama');
        }
    }, []);

    const MailTracker = async (value) => {
        const payload = { key: value };
        try {
            const response = await fetch('https://api.ayurai.in/api/data/NewsLetterMailTracker', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            console.log(data);
        } catch (error) {
            console.error('Error:', error);
        }
    };


    return <div>
        <div className="yoga-step-page">
            <div className='medstepname'>
                <ArrowBackIcon className="backarrow" onClick={() => {
                    navigate(-1)
                }} />
                <p className='mb-0'>{step}</p>
            </div>

            {<div className='meditation-content'>

                <SwipeableViews
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >
                    {steps?.map((e) => {
                        return <div className='steps-info'>
                            <div className='steps-card'>
                                <img src={e.link}></img>
                                <p>{e.head}</p>
                                <div>
                                    <ul>
                                        {
                                            e.points && e?.points?.map((point) => {
                                                return (
                                                    <li>{point?.name}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    })}
                </SwipeableViews>

                <MobileStepper
                    steps={maxSteps}
                    activeStep={activeStep}
                    nextButton={activeStep != maxSteps - 1 ?
                        <IconButton
                            size="large"
                            onClick={handleNext}
                            style={activeStep != maxSteps - 1 ? { backgroundColor: "#007BFF", color: 'white' } : {}}
                            disabled={activeStep === maxSteps - 1}
                        >
                            <ArrowForwardIcon />

                        </IconButton> : <Button style={{ backgroundColor: "#007BFF", color: 'white' }} className='medstepnextbtnct' onClick={() => {
                            navigate(-1)
                        }}>Finish</Button>
                    }
                />
            </div>}
        </div>
    </div>

}

export default Pranayama_Page