import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, IconButton, TextField, Dialog, DialogContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import { addAddress, editAddress } from '../ProfileSlice';
import { useDispatch, useSelector } from 'react-redux';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import axios from 'axios';
import { setAuthToken } from '../Auth/setAuthToken';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';

function Address() {
    let [Address, setAddress] = useState({
        Name: "", Address1: "",
        Address2: "", City: "", State: "", Country: "", Pin: "",
        email: "", Phone: ""
    });

    let [edititem, setedititem] = useState(0);
    let [validationErrors, setValidationErrors] = useState({});

    let profile = useSelector((State) => State?.Profile);
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleClickOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);

    let [EditAddress, setEditAddress] = useState({
        Name: "", Address1: "",
        Address2: "", City: "", State: "", Country: "", Pin: "",
        email: "", Phone: ""
    });

    const validateForm = (address) => {
        let errors = {};

        if (!address.Name) errors.Name = "Name is required";
        if (!address.Address1) errors.Address1 = "Address Line 1 is required";
        if (!address.City) errors.City = "City is required";
        if (!address.State) errors.State = "State is required";
        if (!address.Country) errors.Country = "Country is required";
        if (!address.Pin || !/^\d{5,6}$/.test(address.Pin)) errors.Pin = "Valid postal code is required";
        if (!address.email || !/\S+@\S+\.\S+/.test(address.email)) errors.email = "Valid email is required";
        if (!address.Phone || !/^\d{10}$/.test(address.Phone)) errors.Phone = "Valid phone number is required";

        return errors;
    };

    const handleSubmit = async () => {
        let errors = validateForm(Address);
        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
            return;
        }

        dispatch(addAddress(Address));
        try {
            let Token = localStorage.getItem('jwt-token');
            setAuthToken(Token);
            let address = profile.Address.map((e) => ({
                name: e?.Name,
                addressLine1: e?.Address1,
                addressLine2: e?.Address2,
                postalcode: e?.Pin,
                city: e?.City,
                state: e?.State,
                country: e?.Country,
                email: e.email,
                phone: e.phone,
            }));

            let res = await axios.post('https://api.ayurai.in/api/data/updateAddress', [...address]);
            if (res.status === 200) {
                handleClose();
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleEditSubmit = async () => {
        let errors = validateForm(EditAddress);
        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
            return;
        }

        dispatch(editAddress(EditAddress));
        try {
            let Token = localStorage.getItem('jwt-token');
            setAuthToken(Token);
            let address = profile.Address.map((e) => ({
                name: e?.Name,
                addressLine1: e?.Address1,
                addressLine2: e?.Address2,
                postalcode: e?.Pin,
                city: e?.City,
                state: e?.State,
                country: e?.Country,
                email: e.email,
                phone: e.phone,
            }));

            let res = await axios.post('https://api.ayurai.in/api/data/updateAddress', [...address]);
            if (res.status === 200) {
                handleClose2();
            }
        } catch (err) {
            console.log(err);
        }
    };

    const HandleAddress = (fieldName, value) => {
        setAddress(prev => ({
            ...prev,
            [fieldName]: value
        }));
    };

    const HandleEditAddress = (fieldName, value) => {
        setEditAddress(prev => ({
            ...prev,
            [fieldName]: value
        }));
    };

    useEffect(() => {
        if (profile.Address.length !== 0) {
            setEditAddress({
                Name: profile?.Address[edititem]?.Name,
                Address1: profile?.Address[edititem]?.Address1,
                Address2: profile?.Address[edititem].Address2,
                City: profile?.Address[edititem]?.City,
                State: profile?.Address[edititem]?.State,
                Country: profile?.Address[edititem].Country,
                Pin: profile?.Address[edititem]?.Pin,
                email: profile?.Address[edititem]?.email,
                Phone: profile?.Address[edititem]?.Phone,
                id: profile?.Address[edititem]?.id,
            });
        }
    }, [profile.Address, edititem]);

    return (
        <>
            <div className='Cart-header'>
                <IconButton onClick={() => navigate(-1)}>
                    <ArrowBackIcon />
                </IconButton>
                &nbsp; &nbsp; &nbsp;
                Add Address
            </div>

            <div className='Address-page'>
                <div className='Address-list'>
                    {profile?.Address?.map((e, i) => (
                        <div className='Address-card' key={i}>
                            <div className='Address-contact-details'>
                                <p id='addressname'>{e?.Name}</p>
                                <IconButton onClick={() => {
                                    setedititem(i);
                                    handleClickOpen2();
                                }}>
                                    <EditLocationAltIcon />
                                </IconButton>
                            </div>
                            <p id='address'>{`${e?.Address1},${e?.Address2},${e?.City},${e?.State},${e?.Country},${e?.Pin}`}</p>
                            <div className='Address-contact-details'>
                                <p className='Address-contact-details'>
                                    <PhoneIcon style={{ fontSize: "medium" }} />
                                    {e?.Phone}
                                </p>
                                <p className='Address-contact-details'>
                                    <MailIcon style={{ fontSize: "medium" }} />{e?.email}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
                <Button id="addAddressbtn" onClick={handleClickOpen}>Add Address</Button>
            </div>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <div className='Address-Input'>
                        <div className='gps'><GpsFixedIcon /><p>Get my Current location</p></div>
                        <TextField
                            id="outlined-basic"
                            className='Addressinput'
                            label="Name"
                            variant="outlined"
                            onChange={(e) => HandleAddress("Name", e.target.value)}
                            error={!!validationErrors.Name}
                            helperText={validationErrors.Name}
                        />
                        <TextField
                            id="outlined-basic"
                            className='Addressinput'
                            label="Address Line 1"
                            variant="outlined"
                            onChange={(e) => HandleAddress("Address1", e.target.value)}
                            error={!!validationErrors.Address1}
                            helperText={validationErrors.Address1}
                        />
                        <TextField
                            id="outlined-basic"
                            className='Addressinput'
                            label="Address Line 2"
                            variant="outlined"
                            onChange={(e) => HandleAddress("Address2", e.target.value)}
                        />
                        <div className='Address-Input2'>
                            <TextField
                                id="outlined-basic"
                                label="City"
                                variant="outlined"
                                onChange={(e) => HandleAddress("City", e.target.value)}
                                error={!!validationErrors.City}
                                helperText={validationErrors.City}
                            />
                            <TextField
                                id="outlined-basic"
                                label="State"
                                variant="outlined"
                                onChange={(e) => HandleAddress("State", e.target.value)}
                                error={!!validationErrors.State}
                                helperText={validationErrors.State}
                            />
                            <TextField
                                id="outlined-basic"
                                label="Country"
                                variant="outlined"
                                onChange={(e) => HandleAddress("Country", e.target.value)}
                                error={!!validationErrors.Country}
                                helperText={validationErrors.Country}
                            />
                            <TextField
                                id="outlined-basic"
                                label="Postal Code"
                                variant="outlined"
                                onChange={(e) => HandleAddress("Pin", e.target.value)}
                                error={!!validationErrors.Pin}
                                helperText={validationErrors.Pin}
                            />
                        </div>
                        <TextField
                            id="outlined-basic"
                            className='Addressinput'
                            label="Mail id"
                            variant="outlined"
                            onChange={(e) => HandleAddress("email", e.target.value)}
                            error={!!validationErrors.email}
                            helperText={validationErrors.email}
                        />
                        <TextField
                            id="outlined-basic"
                            className='Addressinput'
                            label="Mobile number"
                            variant="outlined"
                            onChange={(e) => HandleAddress("Phone", e.target.value)}
                            error={!!validationErrors.Phone}
                            helperText={validationErrors.Phone}
                        />
                        <Button id='add_address' onClick={handleSubmit}>ADD Address</Button>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog open={open2} onClose={handleClose2}>
                <DialogContent>
                    <div className='Address-Input'>
                        <TextField
                            id="outlined-basic"
                            className='Addressinput'
                            label="Name"
                            variant="outlined"
                            value={EditAddress?.Name}
                            onChange={(e) => HandleEditAddress("Name", e.target.value)}
                            error={!!validationErrors.Name}
                            helperText={validationErrors.Name}
                        />
                        <TextField
                            id="outlined-basic"
                            className='Addressinput'
                            label="Address Line 1"
                            variant="outlined"
                            value={EditAddress?.Address1}
                            onChange={(e) => HandleEditAddress("Address1", e.target.value)}
                            error={!!validationErrors.Address1}
                            helperText={validationErrors.Address1}
                        />
                        <TextField
                            id="outlined-basic"
                            className='Addressinput'
                            label="Address Line 2"
                            variant="outlined"
                            value={EditAddress?.Address2}
                            onChange={(e) => HandleEditAddress("Address2", e.target.value)}
                        />
                        <div className='Address-Input2'>
                            <TextField
                                id="outlined-basic"
                                label="City"
                                variant="outlined"
                                value={EditAddress?.City}
                                onChange={(e) => HandleEditAddress("City", e.target.value)}
                                error={!!validationErrors.City}
                                helperText={validationErrors.City}
                            />
                            <TextField
                                id="outlined-basic"
                                label="State"
                                variant="outlined"
                                value={EditAddress?.State}
                                onChange={(e) => HandleEditAddress("State", e.target.value)}
                                error={!!validationErrors.State}
                                helperText={validationErrors.State}
                            />
                            <TextField
                                id="outlined-basic"
                                label="Country"
                                variant="outlined"
                                value={EditAddress?.Country}
                                onChange={(e) => HandleEditAddress("Country", e.target.value)}
                                error={!!validationErrors.Country}
                                helperText={validationErrors.Country}
                            />
                            <TextField
                                id="outlined-basic"
                                label="Postal Code"
                                variant="outlined"
                                value={EditAddress?.Pin}
                                onChange={(e) => HandleEditAddress("Pin", e.target.value)}
                                error={!!validationErrors.Pin}
                                helperText={validationErrors.Pin}
                            />
                        </div>
                        <TextField
                            id="outlined-basic"
                            className='Addressinput'
                            label="Mail id"
                            variant="outlined"
                            value={EditAddress?.email}
                            onChange={(e) => HandleEditAddress("email", e.target.value)}
                            error={!!validationErrors.email}
                            helperText={validationErrors.email}
                        />
                        <TextField
                            id="outlined-basic"
                            className='Addressinput'
                            label="Mobile number"
                            variant="outlined"
                            value={EditAddress?.Phone}
                            onChange={(e) => HandleEditAddress("Phone", e.target.value)}
                            error={!!validationErrors.Phone}
                            helperText={validationErrors.Phone}
                        />
                        <Button id='add_address' onClick={handleEditSubmit}>Save Changes</Button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default Address;
