import React from 'react'
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState, useEffect } from 'react';
import { IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useNavigate } from 'react-router-dom'
import { Outlet, NavLink } from 'react-router-dom';
function samePageLinkNavigation(event) {
    if (
        event.defaultPrevented ||
        event.button !== 0 ||
        event.metaKey ||
        event.ctrlKey ||
        event.altKey ||
        event.shiftKey
    ) {
        return false;
    }
    return true;
}

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                if (samePageLinkNavigation(event)) {
                    event.preventDefault();
                }
            }}
            {...props}
        />
    );
}
function Appointmentpage() {
    const [value, setValue] = useState(0);

    const navigate = useNavigate()

    const handleChange = (event, newValue) => {
        if (event.type !== 'click' || (event.type === 'click' && samePageLinkNavigation(event))) {
            setValue(newValue);
        }
    };
    
    useEffect(() => {
        navigate('UpcomingAppointments')
    }, [])

    return <>
        <div className='Cart-header'>
            <IconButton onClick={() => {
                navigate('/Tele')
            }}>
                <ArrowBackIcon />
            </IconButton>
        </div>
        <div>
            <Tabs value={value} onChange={handleChange} aria-label="nav tabs example" centered>

                <LinkTab label="Upcoming" id='linktab' onClick={() => {
                    navigate('UpcomingAppointments')
                }}

                >
                </LinkTab>

                <LinkTab label="Completed" to='UpcomingAppointments' id='linktab'
                    onClick={() => {
                        navigate('CompletedAppointments')
                    }} />

                <LinkTab label="Cancelled" id='linktab'
                    onClick={() => {
                        navigate('CancelledAppoinments')
                    }} />
            </Tabs>
        </div>
        <>
            <Outlet></Outlet>
        </>
    </>
}

export default Appointmentpage