import React from "react";
import SlideRuler from "slide-ruler";

class SlideRulerPage extends React.Component {
  constructor(props) {
    super();

    this.handleValue = this.handleValue.bind(this);
    this._renderSlideRuler = this._renderSlideRuler.bind(this);
  }

  componentDidMount() {
    this._renderSlideRuler();
  }

  handleValue(value) {
    this.props.func(value)
  }

  _renderSlideRuler() {
    return new SlideRuler({
      el: this.refs.slideRuler,
      maxValue: 230,
      minValue:0,
      currentValue:40,
      handleValue: this.handleValue,
      precision: 1
    });
  }

  render() {
    return <div ref="slideRuler" />;
  }
}

export default SlideRulerPage;
