import React from 'react'
import { useContext, useState, useEffect } from 'react';
import { contextAPI } from '../Context';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SwipeableViews from 'react-swipeable-views';
import { getDatabase, ref, onValue, } from "firebase/database";
import { IconButton } from '@mui/material';
function Meditation_Page() {
    const theme = useTheme();

    let { meditation } = useContext(contextAPI)
    let { step } = useParams()
    let navigate = useNavigate()
    const [activeStep, setActiveStep] = useState(0);
    const [steps, setSteps] = useState([])
    const maxSteps = steps?.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };
    const getSteps = async () => {

        try {
            const db = getDatabase();
            const Ref = ref(db, `/meditation/${step}`);
            onValue(Ref, async (snapshot) => {
                try {
                    const data = await snapshot.val()
                    setSteps(Object.values(data.steps))

                }
                catch (error) {
                    console.log(error)
                }

            })

        }
        catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        getSteps()
    }, [step])


    return <div>
        <div className="yoga-step-page">
            <div className='medstepname'>
                <ArrowBackIcon className="backarrow" onClick={() => {
                    navigate(-1)
                }} />
                <p className='mb-0'>{step}</p>
            </div>

            {<div className='meditation-content'>

                <SwipeableViews
                    // axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >
                    {steps?.map((e) => {
                        return <div className='steps-info'>
                            <div className='steps-card'>
                                <img src={e.link}></img>
                                <p>{e.head}</p>
                                <div>
                                    <ul>
                                        {
                                            e.points && e?.points?.map((point, index) => {
                                                return (
                                                    <li>{point?.name}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    })}
                </SwipeableViews>

                <MobileStepper
                    steps={maxSteps}
                    activeStep={activeStep}
                    nextButton={activeStep != maxSteps - 1 ?
                        <IconButton
                            size="large"
                            onClick={handleNext}
                            style={activeStep != maxSteps - 1 ? { backgroundColor: "#007BFF", color: 'white' } : {}}
                            disabled={activeStep === maxSteps - 1}
                        >
                            <ArrowForwardIcon />

                        </IconButton> : <Button style={{ backgroundColor: "#007BFF", color: 'white' }} className='medstepnextbtnct' onClick={() => {
                            navigate(-1)
                        }}>Finish</Button>
                    }
                />
            </div>}
        </div>
    </div>

}

export default Meditation_Page