import React from 'react'
import AppBar from '@mui/material/AppBar';
import { NavLink, Outlet } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal'
import ReportIcon from '@mui/icons-material/Report';
import PersonIcon from '@mui/icons-material/Person';
import loader from './Animations/loadingaiwell.json'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import HomeIcon from '@mui/icons-material/Home';
import Divider from '@mui/material/Divider';
import LogoutIcon from '@mui/icons-material/Logout';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ListItem from '@mui/material/ListItem';
import SlideRulerPage from './Slider';
import ListItemButton from '@mui/material/ListItemButton';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import ListItemIcon from '@mui/material/ListItemIcon';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import SettingsIcon from '@mui/icons-material/Settings';
import RestoreIcon from '@mui/icons-material/Restore';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Lottie from 'lottie-react';
import { useNavigate } from 'react-router-dom';
import { setAuthToken } from './Auth/setAuthToken';
import others_img from './Animations/others.png'
import { useState, useEffect, createRef } from 'react'
import male from './Animations/male.png'
import female from './Animations/female.png'
import axios from 'axios';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/en-gb';

import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
function Profile_page(props) {
  const ref = createRef(null)
  const { window } = props;
  const navigate = useNavigate()
  const [count, setCount] = useState(0)
  const [mobileOpen, setMobileOpen] = useState(false);
  const [loading, setloading] = useState(false)
  const [height, setheight] = useState(0)
  const drawerWidth = 250;
  const Token = localStorage.getItem('jwt-token')
  let userdetails = localStorage.getItem('Userdetails')
  let refreshtoken = JSON.parse(userdetails)
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const [key, setkey] = useState('');
  const [value, setvalue] = useState('')
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [firstname, setfirstname] = useState('')
  const [lastname, setlastname] = useState('')
  const [selectedWeight, setSelectedWeight] = useState(70);
  const minWeight = 0;
  const maxWeight = 150;
  const step = 5;
  const formattedDate = dayjs(value).format('DD/MM/YYYY');
  const scaleLabels = Array.from({ length: (maxWeight - minWeight) / step + 1 }).map((_, index) => (
    <span key={index}>{minWeight + index * step}&nbsp;</span>
  ));

  const handleClickOpen = (key) => {
    setkey(key)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleweightChange = (value) => {
    setSelectedWeight(value);
    setvalue(`${value} kg`)
  };

  const handleheightChange = (value) => {
    setheight(value)
    setvalue(`${value} cm`)
  }
  const [feet, setFeet] = useState(5);
  const [inches, setInches] = useState(0);
  const [heightpreference, setheightpereference] = useState('ft')

  const handleFeetChange = (value) => {
    setFeet(value);
    setvalue(`${value} ft ${inches} in`)
  };

  const handleInchesChange = (value) => {
    setInches(value);
    setvalue(`${feet} ft ${value} in`)
  };


  const [Profile, setProfile] = useState([])

  const drawer = (
    <Box
      role="presentation"
    >
      <List className='sidebar'>
        <img src='aiwelllogoa512x512.png' id='aiwell-logo'></img>

        <ListItem disablePadding>
          <ListItemButton onClick={() => { navigate('/') }}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <p id='listitemtext' className='mb-0'  >
              Home
            </p>
          </ListItemButton>

        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <p id='listitemtext' className='mb-0'>
              Profile
            </p>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => { navigate('/Reports') }}>
            <ListItemIcon>
              <InsertDriveFileIcon />
            </ListItemIcon>
            <p id='listitemtext' className='mb-0'>
              Reports
            </p>
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <p id='pharm' className='mb-0'>E-Pharm</p>
      <List>

        <ListItem disablePadding>
          <ListItemButton onClick={() => { navigate('/Shop') }}>
            <ListItemIcon>
              <ShoppingBagIcon />
            </ListItemIcon>
            <p id='listitemtext' className='mb-0'>
              Shop
            </p>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton onClick={() => { navigate('/Orders') }}>
            <ListItemIcon>
              <ShoppingCartIcon />
            </ListItemIcon>
            <p id='listitemtext' className='mb-0'>
              My Orders
            </p>
          </ListItemButton>
        </ListItem>

      </List>
      <Divider />
      <p id='tele' className='mb-0'>Teleconsultation</p>
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => { navigate("/Tele") }}>
            <ListItemIcon>
              <PersonSearchIcon />
            </ListItemIcon>
            <p id='listitemtext' className='mb-0'>
              Find Doctor
            </p>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton onClick={() => { navigate("/Appointment_page") }}>
            <ListItemIcon>
              <PermContactCalendarIcon />
            </ListItemIcon>
            <p id='listitemtext' className='mb-0' >
              My Appointments
            </p>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <FavoriteIcon />
            </ListItemIcon>
            <p id='listitemtext' className='mb-0' >
              My Doctors
            </p>
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <p id='Settings' className='mb-0'>General</p>
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => { navigate("/Settings") }}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <p id='listitemtext' className='mb-0'>
              Settings
            </p>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>

  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  const getProfile = async () => {
    try {
      setAuthToken(Token)
      let profile = await axios.post('https://api.ayurai.in/api/data/getProfile')
      if (profile.status === 200) {
        setProfile(profile.data)
        setloading(true)
      }
    }
    catch (error) {
      if (error?.response?.data?.error.message === 'jwt expired' || error?.response?.data?.error.status === 500) {
        renew()
      }
    }
  }

  const UpdateOneProfile = async () => {
    try {
      setAuthToken(Token)
      let payload1 = { key: "firstname", value: `${firstname}` }
      let payload2 = { key: "lastname", value: `${lastname}` }
      let res2
      let res
      if (key === 'Name') {
        res = await axios.post(`https://api.ayurai.in/api/data/updateOneProfile`, payload1)
        res2 = await axios.post(`https://api.ayurai.in/api/data/updateOneProfile`, payload2)
      }
    
      
      res = await axios.post(`https://api.ayurai.in/api/data/updateOneProfile`, {
        key, value
      })

      if (res.status === 200) {
        setkey('')
        setvalue('')
        setCount(count + 1)
        handleClose()
      }
    }
    catch (error) {
      if (error?.response?.data?.error.message === 'jwt expired' || error?.response?.data?.error.status === 500) {
        renew()
      }
    }
  }

  let renew = async () => {

    try {
      let res = await axios.post(`https://api.ayurai.in/auth/renew`, {
        email: refreshtoken.email,
        token: refreshtoken.refreshToken
      })
      if (res.status === 200) {

        let data = res.data
        localStorage.setItem('jwt-token', data.access_token)

        setCount(count + 1)

      }
    }
    catch (error) {
      console.log(error)
    }
  }
  const logout = () => {
    localStorage.removeItem('Userdetails')
    localStorage.removeItem('jwt-token')
    localStorage.removeItem('Profile')
    navigate('/')
  }

  useEffect(() => {
    getProfile()
  }, [count])

  const dateFormat = (date) => {
    const originalDate = new Date(date);
    const formattedDate = new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(originalDate);
    setvalue(formattedDate)
  }
  return <>
    {loading ? <Box sx={{ display: 'flex' }}>
      <AppBar className='Navbar'
        style={{ backgroundColor: "white", display: "flex", flexDirection: "row" }}
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}>

        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon style={{ color: "blue" }} />
        </IconButton>
        <div></div>
        <div style={{ alignSelf: "flex-end" }}><Button onClick={() => { navigate('/Orders') }}><RestoreIcon /></Button  ><Button onClick={() => { navigate('/Cart') }}><ShoppingCartIcon /></Button></div>
      </AppBar>
      <div>


        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
      </div>
      <Box
        component="main"
        sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <div className='question-page pt-5' ref={ref} style={{ height: '100%' }}>
          <div className='Profile-page mt-5'>
            <div>
              <img src={Profile?.Data?.gender === 'Male' ? male : female} style={{ width: "10rem", height: "10rem", borderRadius: "50%", border: "1px solid silver" }}></img>
            </div>
            <div className='Profile-list'>
              <div onClick={() => {
                handleClickOpen('Name')
              }}>
                <p className='mb-0'>Name</p>
                <p className='mb-0'>{Profile?.Data?.firstname} {Profile?.Data?.lastname}</p>
              </div>
              <div onClick={() => {
                handleClickOpen('gender')
              }}>
                <p className='mb-0'>Gender</p>
                <p className='mb-0'>{Profile?.Data?.gender}</p>
              </div>

              <div onClick={() => {
                handleClickOpen('mothertounge')
              }}>
                <p className='mb-0'>Mother Tongue</p>
                <p className='mb-0'>{Profile?.Data?.mothertounge}</p>
              </div>
              <div onClick={() => {
                handleClickOpen('dob')
              }}>
                <p className='mb-0'>DOB</p>
                <p className='mb-0'>{Profile?.Data?.dob}</p>
              </div>
              <div onClick={() => {
                handleClickOpen('height')
              }}>
                <p className='mb-0'>Height</p>
                <p className='mb-0'>{Profile?.Data?.height}</p>
              </div>
              <div onClick={() => {
                handleClickOpen('weight')
              }}>
                <p className='mb-0'>Weight</p>
                <p className='mb-0'>{Profile?.Data?.weight}</p>
              </div>
              <div onClick={() => {
                handleClickOpen('phone')
              }}>
                <p className='mb-0'>Mobile number</p>
                <p className='mb-0'>{Profile?.Data?.phone}</p>
              </div>
            </div>
            <div className='logout'>
              <Button className='logout' style={{ backgroundColor: "#0076BE", color: "white", padding: '1rem' }} onClick={() => {
                logout()
              }}>Logout &nbsp; &nbsp; <LogoutIcon /></Button>
            </div>
          </div>

          <Dialog
            fullWidth={true}
            maxWidth={'md'}

            fullScreen={fullScreen}
            className='kas'
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >


            {
              key === 'Name' ? <div className='profile_input m-5 d-flex'>
                <p id='responsive-dialog-title' className='mb-0'>Change your Name</p>
                <p style={{ fontSize: "14px", fontWeight: "400" }}>We'll use your name to personalize your experience in the app</p>
                <TextField id="outlined-basic" label="FirstName" variant="outlined" onChange={(e) => {
                  setfirstname(e.target.value)
                }} />
                <TextField id="outlined-basic" label="LastName" variant="outlined"
                  onChange={(e) => {
                    setlastname(e.target.value)
                  }} />
              </div> : key === 'gender' ?
                <div className='profile_input m-3 d-flex'>
                  <p id='responsive-dialog-title' className='mb-0'>Change your gender</p>
                  <p style={{ fontSize: "14px", fontWeight: "400" }}>This information helps us understand our users better and tailor the app to your needs</p>
                  <div className='genders'>
                    <div className='gender'
                      style={value != "" && value != "Male" ? { opacity: "0.2" } : value === "Male" ? { backgroundColor: "whitesmoke" } : {}}
                      onClick={() => { setvalue("Male") }}><p>Male</p>
                      <img src={male}></img>
                    </div>
                    <div className='gender' style={value != "" && value != "Female" ? { opacity: "0.2" } : value === "Female" ? { backgroundColor: "whitesmoke" } : {}} onClick={() => { setvalue("Female") }} >
                      <p>Female</p>
                      <img src={female}></img>
                    </div>
                    <div className='gender' style={value != "" && value != "Others" ? { opacity: "0.2" } : value === "Others" ? { backgroundColor: "whitesmoke" } : {}}
                      onClick={() => { setvalue("Others") }}>
                      <p>Others</p>
                      <img src={others_img}></img>
                    </div>
                  </div>
                </div> : key === 'mothertounge' ?
                  <div className='profile_input m-5 d-flex'>
                    <p id='responsive-dialog-title' className='mb-0'>Change your Mother Tongue?</p>
                    <p style={{ fontSize: "14px", fontWeight: "400" }}>This information helps us understand our users better and tailor the app to your needs</p>
                    <TextField id="outlined-basic" label="Mother Tongue" variant="outlined"
                      onChange={(e) => {
                        setvalue(e.target.value)
                      }} />
                  </div> : key === 'dob' ?
                    <div className='profile_input m-5 d-flex'>
                      <p id='responsive-dialog-title' className='mb-0'>Change your DOB?</p>
                      <p style={{ fontSize: "14px", fontWeight: "400" }}>This information helps us understand our users better and tailor the app to your needs</p>
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                          <DemoContainer components={['DatePicker']}>
                            <DatePicker label="D.O.B"
                              inputFormat="DD/MM/YYYY"
                              onChange={(date) => {
                                dateFormat(date)
                              }} />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                    </div> : key == 'height' ?
                      <div className='profile_input m-5 d-flex'>
                        <p id='responsive-dialog-title' className='mb-0'>What's your height?</p>
                        <p style={{ fontSize: "14px", fontWeight: "400" }} className='mb-0'>To help us provide you with the best possible therapies,<br></br>it's
                          importantant for us to know your height.<br></br>Your height can affect the dosage of medications and the effectiveness of therapies</p>
                        <div className='height-preference'>
                          <div className={`htbox  ${heightpreference === 'cm' ? 'selected' : ""}`} onClick={() => {
                            setheightpereference('cm')
                          }}>
                            <p className='mb-0'>cm</p>
                          </div>
                          <div className={`htbox ${heightpreference === 'ft' ? 'selected' : ""}`} onClick={() => {
                            setheightpereference('ft')
                          }}>
                            <p className='mb-0'>ft</p>
                          </div>
                        </div>
                        {heightpreference === 'ft' ?
                          <div className="height-selector">
                            <p id='selectedweight' className='mb-0'>
                              {feet}&nbsp;<span>ft</span>&nbsp;{inches}&nbsp;<span>in</span>
                            </p>
                            <div className="height-spinner">
                              <label>Feet:</label>
                              <div className="spinner">
                                {[...Array(8).keys()].map((value) => (
                                  <div
                                    key={value}
                                    className={`spinner-item ${feet === value + 1 ? 'selected' : ''}`}
                                    onClick={() => handleFeetChange(value + 1)}
                                  >
                                    {value + 1}
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className="height-spinner">
                              <label>Inches:</label>
                              <div className="spinner">
                                {[...Array(12).keys()].map((value) => (
                                  <div
                                    key={value}
                                    className={`spinner-item ${inches === value ? 'selected' : ''}`}
                                    onClick={() => handleInchesChange(value)}
                                  >
                                    {value}
                                  </div>
                                ))}
                              </div>

                            </div>


                          </div>
                          :
                          <div ><SlideRulerPage func={handleheightChange} />
                            <p id='selectedweight'>{height}&nbsp;<span>CM</span></p></div>
                        }
                      </div> : key == 'weight' ?
                        <div className='profile_input mt-5 d-flex'>
                          <p>What's your Weight?</p>
                          <SlideRulerPage func={handleweightChange} />
                          <p id='selectedweight'>{selectedWeight}&nbsp;<span>Kg</span></p>
                        </div> :
                        key == 'phone' ? <div className='profile_input mt-5 d-flex p-3'>
                          <p>Enter your Phone Number?</p>
                          <TextField id="outlined-basic" label="Phone Number" variant="outlined"
                            onChange={(e) => {
                              setvalue(e.target.value)
                            }} />
                        </div> :
                          <></>
            }

            <DialogActions>
              <Button autoFocus onClick={handleClose}>
                Disagree
              </Button>
              <Button onClick={UpdateOneProfile} autoFocus>
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Box>
    </Box>
      : <div className='loading'>
        <Lottie animationData={loader} className='loader-animation' />
        <p>Did you Know ?</p>
        <span>Ayurveda has been around as long as the practice of yoga,and is considered to be the 'sister science' to yoga</span>
      </div>}
  </>
}
export default Profile_page
