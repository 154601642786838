import React from 'react'
import { useRef, useState, useEffect, useContext } from 'react'
import { BodyComponent } from 'reactjs-human-body';
import { Fade, Slide } from "react-awesome-reveal";
import { getDatabase, ref, onValue, } from "firebase/database";
import { useNavigate } from 'react-router-dom';
import { contextAPI } from './Context';
import { db } from './firebase'
import axios from 'axios';
import CheckIcon from '@mui/icons-material/Check';
import { setAuthToken } from './Auth/setAuthToken';
import { SystemSecurityUpdateGood } from '@mui/icons-material';
function Vikiriti() {
  const [Systems, setsystems] = useState(new Map())
  const Symptoms = useRef(new Map())
  const [symptomslist, setSymptomslist] = useState([]);
  const [show, setshow] = useState(false)
  const [symptom, setSymptoms] = useState([])
  const [selectedSymptoms, setSelectedSymptoms] = useState(new Map());
  const [diseases, setDisease] = useState([])
  const [selectedSymptomslist, setSelectedSymptomslist] = useState(new Map());
  const scrolldown = useRef()
  let navigate = useNavigate()
  let { setvikritiresult } = useContext(contextAPI)
  let queries = {
    xs: '(max-width: 320px)',
    sm: '(max-width: 840px)',
    md: '(max-width: 1024px)'
  }
  let media_screen_width = window.matchMedia(queries.sm);

  useEffect(() => {
    getData()
  }, [])

  let getData = (Data) => {
    try {
      const db = getDatabase();
      const Ref = ref(db, `/Disease`);
      onValue(Ref, async (snapshot) => {
        try {
          const data = await snapshot.val()
          setDisease(data)

        }
        catch (error) {
          console.log(error)
        }

      })

    }
    catch (error) {
      console.log(error)
    }
  }

  const AddSymptomslist = (key, value) => {
    setsystems((prev) => {
      const newsystems = new Map(prev);
      if (newsystems.has(key)) {
        newsystems.delete(key);

      } else {
        newsystems.set(key, Object.values(value));
      }
      return newsystems;
    });

    handleSymptomslist(key, Object.values(value))
    if (value && !Systems.has(key)) {
      setshow(true)
    }
  }

  const handleSymptomslist = (Key, value) => {
    setSymptomslist(prevSymptoms => {
      const existingSymptomIndex = prevSymptoms.findIndex(symptom => symptom.Key === Key);
      if (existingSymptomIndex !== -1) {
        const updatedSymptoms = [...prevSymptoms];
        updatedSymptoms.splice(existingSymptomIndex, 1);
        return updatedSymptoms;
      } else {
        return [...prevSymptoms, { Key, value }];
      }
    });
  };

  const handleSymptomClick = (existingsymptom,obj) => {
    setSelectedSymptoms((prev) => {
      const newSymptoms = new Map(prev);
      if (newSymptoms.has(obj?.Symptom)) {
        newSymptoms.delete(obj?.Symptom);
        const updatedItems = symptom.filter(item => item.Symptom !== existingsymptom);
        setSymptoms(updatedItems)
      } else {
        newSymptoms.set(obj?.Symptom, obj)
        setSymptoms((prev) => [...prev, obj])
      }
      return newSymptoms;
    });
  }



  let sympotoms = (sympotom) => {

    if (sympotom === 'head') {
      getData('ENT')
      getData("Mental")
      getData("Sleep")
    }
    if (sympotom === 'stomach') {
      getData('Gastro')
      getData("Urology")

    }

    if (sympotom === 'rightShoulder' || sympotom === 'leftShoulder' || sympotom === 'rightArm' || sympotom === 'leftArm' || sympotom === 'rightLeg' || sympotom === 'leftLeg' || sympotom === 'rightFoot' || sympotom === 'leftFoot') {
      getData('Physical')
      getData('Neurology')
      getData('Musculo')
    }

    if (sympotom === 'rightHand' || sympotom === 'leftHand') {
      getData('Skin')
    }
    if (sympotom === 'chest') {
      getData('Respiratory')
    }
  }

  const Upload = async (listofsymptoms, vata, pitta, kapha) => {
    try {
      let data = localStorage.getItem('Userdetails')
      let userdetails = JSON.parse(data)
      let Token = localStorage.getItem('jwt-token')
      setAuthToken(Token)
      let res = await axios.post('https://api.ayurai.in/api/data/updateVikriti', {
        vatascore: vata,
        pittascore: pitta,
        kaphascore: kapha,
        sympotom: listofsymptoms,
        timestamp: Date.now(),
        date: new Date(),
        count:symptom.length,
        VIKRITIVATAPULSE: 0,
        VIKRITIPITTAPULSE: 0,
        VIKRITIKAPHAPULSE: 0,
      }) 
      let vikritiresult = []

      if (vata / Symptoms.size > 0) {
        vikritiresult.push('V')
      }
      if (pitta / Symptoms.size > 0) {
        vikritiresult.push('P')
      }
      if (kapha / Symptoms.size > 0) {
        vikritiresult.push('K')
      }
      setvikritiresult(vikritiresult.join(''))
      if(res.status===200){
        navigate('/Result/vikriti')
      }
    }
    catch (error) {
      console.log(error)
      if (error?.response?.data?.error.message === 'jwt expired' || error?.response?.data?.error.status === 500) {
        renew()
      }
    }
  }

  let renew = async () => {

    try {
      let data = localStorage.getItem('Userdetails')
      let userdetails = JSON.parse(data)
      let res = await axios.post(`https://api.ayurai.in/auth/renew`, {
        email: userdetails?.email,
        token: userdetails?.refreshToken
      })
      if (res.status === 200) {

        let data = res.data

        localStorage.setItem('jwt-token', data.access_token)

        Upload()

      }
    }
    catch (error) {
      console.log(error)
    }
  }

  const handleData = () => {
    let symptomsdata = []
    let vata = 0;   
    let pitta = 0;
    let kapha = 0;
    let data = symptom.map((e, i) => {
      if (e.Increase.split(',').includes('V')) {
        vata += 1
      }
      if (e.Increase.split(',').includes('P')) {
        pitta += 1
      }
      if (e.Increase.split(',').includes('K')) {
        kapha += 1
      }
      if (e.Decrease.split(',').includes('V')) {
        vata -= 1
      }
      if (e.Decrease.split(',').includes('P')) {
        pitta -= 1
      }
      if (e.Decrease.split(',').includes('K')) {
        kapha -= 1
      }

      symptomsdata.push(e.Symptom)

    })

     Upload(symptomsdata.join(','), vata, pitta, kapha)
  }

  let systemsContainer = []

  for (let [key, value] of Object.entries(diseases)) {
    systemsContainer.push(
      <div className='system-container' onClick={() => { AddSymptomslist(key, value) }} >
        <div className='image'>
          <img src={value.img}
            style={Systems?.has(key) ? { filter: "blur(2px)" } : {}}></img>

        </div>
        <p className='mb-0'>{key}</p>

        {Systems.has(key) ?
          <div style={Systems?.has(key) ? { position: "absolute", display: "flex", justifyContent: "center", alignItems: "center" } : { display: "none" }}>
            <div style={{ width: "2rem", height: "2rem", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "blue" }}>
              <CheckIcon style={{ color: "white" }} />
            </div>
          </div> : <></>}
      </div>)
  }

  return <div className='question-page' >
    <div className='content-page'>
      {/* <Slide direction='down'>
        <BodyComponent onClick={(id) => { sympotoms(id) }} />
      </Slide> */}
      {show ? <Fade>
        <div className='symptom-section'>
          <h1>Do you have any of the following disorders ?</h1>
          <div className='symptoms' ref={scrolldown}>
            {
              symptomslist.map((e, i) => (
                e.value
                  .filter((item) => typeof item !== 'string' && item?.Symptom)
                  .map((obj, index) => (
                    <div key={index}>
                      {obj.Symptom && (
                        <>
                          <p
                            className={selectedSymptoms.has(obj.Symptom) ? "symptom" : "symptom-unselected"}
                            onClick={() => {
                              handleSymptomClick(obj?.Symptom,obj);
                            }}
                          >
                            {obj?.Symptom}
                          </p>
                        </>
                      )}
                    </div>
                  ))
              ))
            }

          </div>

        </div>
      </Fade> : <></>}
      <Fade>
        <div className='systems-section'>
          <p style={{ textAlign: "center" }}>Please select the systems that are affected!</p>

          <div className='systems'>
            {systemsContainer}
          </div>

          {media_screen_width.matches ? <></> :
            <div className='next-btn' onClick={() => {
              handleData()
            }}>Next</div>
          }
        </div>
      </Fade>

      {media_screen_width.matches ?
        <div className='btm-btn' onClick={() => {
          handleData()
        }}>
          Next
        </div>
        : <></>}
    </div>
  </div>
}

export default Vikiriti
