import React, { useState } from 'react'
import { useEffect, useRef, useContext } from 'react';
import { getDatabase, ref, onValue, orderByChild, query } from "firebase/database";
import { Fade, Slide } from "react-awesome-reveal";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { contextAPI } from './Context';
import { db } from './firebase'
import 'animate.css';
import ProgressBar from "@ramonak/react-progress-bar";
import axios from 'axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { setAuthToken } from './Auth/setAuthToken';
function Prakriti() {
  let [Data, setData] = useState([])
  let [keys, setkey] = useState(0)
  let [vata, setvata] = useState(0)
  let [pitta, setpitta] = useState(0)
  let [kapha, setkapha] = useState(0)
  let [vataTotal, setvataTotal] = useState(0)
  let [pittaTotal, setpittaTotal] = useState(0)
  let [kaphaTotal, setkaphatotal] = useState(0)
  let contextdata = useContext(contextAPI)
  let [isData, setdata] = useState(false)
  let navigate = useNavigate()
  const [hashMapRef, sethashmaphref] = useState(new Map());
  const [answers, setAnswers] = useState({})
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [open, setOpen] = useState(false);
  const [questions, setquestions] = useState([])


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [pTagActiveStates, setPTagActiveStates] = useState({});

  const handlePTagTouchStart = (index) => {
    setPTagActiveStates((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };


  const handlePTagTouchEnd = (index) => {
    setPTagActiveStates((prevState) => ({
      ...prevState,
      [index]: false,
    }));
  };

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContentre-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  const getQuestions = () => {
    if (questions != undefined && questions.length > 0) {
      const value = questions[currentQuestion]
      setdata(true)
      setData(value)
    }
  }

  let getData = async () => {
    try {
      const db = getDatabase();
      const Ref = ref(db, '/prakriti');
      const data = query(Ref, orderByChild('answers/ans0/score'))
      onValue(data, async (snapshot) => {
        try {
          const data = await snapshot.val()
          setquestions(Object.values(data).sort((a, b) => b?.answers?.ans0?.score - a?.answers?.ans0?.score))
          const key = currentQuestion
          const value = Object.values(data).sort((a, b) => b.answers.ans0.score - a.answers?.ans0?.score)
          setdata(true)
          setData(value)
        }
        catch (error) {
          console.log(error)
        }

      })

    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const handleQuestion = () => {
    setCurrentQuestion(currentQuestion + 1)
  }

  const previousQuestion = () => {
    setCurrentQuestion(currentQuestion - 1)
  }

  function findVPK(arrayList) {
    const datamap = {
      "V": arrayList[0],
      "P": arrayList[1],
      "K": arrayList[2]
    };

    const datamap1 = {
      [arrayList[0]]: "V",
      [arrayList[1]]: "P",
      [arrayList[2]]: "K"
    };

    const arrayList1 = [...arrayList];
    arrayList1.sort((a, b) => a - b);

    const v = arrayList1[2];
    const p = arrayList1[1];
    const k = arrayList1[0];

    arrayList.sort((a, b) => a - b);

    if (v - p > 20) {
      return datamap1[v];
    } else if (v - p <= 20 && p - k > 5) {
      if (v === p) {
        const arrayList2 = [];
        for (const [key, value] of Object.entries(datamap)) {
          if (v === value) {
            arrayList2.push(key);
          }
          console.log("vpkval", key, value);
        }
        return arrayList2[0] + arrayList2[1];
      } else {
        return datamap1[v] + datamap1[p];
      }
    } else {
      return datamap1[v] + datamap1[p] + datamap1[k];
    }
  }

  const UploadData = async () => {
    let All = [vataTotal, pittaTotal, kaphaTotal]
    const result = findVPK(All)
    try {
      let data = localStorage.getItem('Userdetails')
      let userdetails = JSON.parse(data)
      let answerArray = [...hashMapRef].map(([key, value]) => { return { [key]: value } })
      let Token = localStorage.getItem('jwt-token')
      setAuthToken(Token)
      let res = await axios.post('https://api.ayurai.in/api/data/updatePrakriti', {
        date: new Date(),
        result: result,
        prakriti: answerArray,
        vata: vataTotal,
        pitta: pittaTotal,
        kapha: kaphaTotal,
        timestamp: Date.now()
      })

      if (res.status === 200) {
        contextdata.setPrakritiresult(result.toString())
        navigate('/Result/Prakriti')
      }
    }
    catch (error) {
      if (error?.response?.data?.error.message === 'jwt expired' || error?.response?.data?.error.status === 500) {
        renew()
      }
    }
  }


  let renew = async () => {

    try {
      let data = localStorage.getItem('Userdetails')
      let userdetails = JSON.parse(data)
      let res = await axios.post(`https://api.ayurai.in/auth/renew`, {
        email: userdetails?.email,
        token: userdetails?.refreshToken
      })
      if (res.status === 200) {

        let data = res.data

        localStorage.setItem('jwt-token', data.access_token)

        UploadData()

      }
    }
    catch (error) {
      console.log(error)
    }
  }

  const SubmitAnswer = (ans) => {
    let vataper = vata / (pitta + vata + kapha) * 100
    let pittaper = pitta / (pitta + vata + kapha) * 100
    let kaphaper = kapha / (pitta + vata + kapha) * 100

    if (hashMapRef.size > 30 && (hashMapRef.size - 1) % 6 === 0) {
      setvataTotal(vataper)
      setpittaTotal(pittaper)
      setkaphatotal(kaphaper)

    }
    if (vataper - vataTotal <= 5.0 && pittaper - pittaTotal <= 5.0 && kaphaper - kaphaTotal <= 5.0 && (hashMapRef.size) % 6 === 0) {
      setvataTotal(vataper)
      setpittaTotal(pittaper)
      setkaphatotal(kaphaper)
      UploadData()
    }
    else {
      handleQuestion()

      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        [Data[currentQuestion].question]: ans.answer,

      }));


      sethashmaphref((prev) => {
        const newQuestions = new Map(prev);
        if (newQuestions.has(Data[currentQuestion]?.id)) {
          newQuestions.set(Data[currentQuestion]?.id, ans)
        } else {
          newQuestions.set(Data[currentQuestion]?.id, ans)
        }
        return newQuestions;
      });


      if (ans?.vpk === 'p') {
        setpitta(pitta + Number(ans.score))
      }
      if (ans?.vpk === 'v') {
        setvata(vata + Number(ans.score))
      }
      if (ans?.vpk === 'k') {
        setkapha(kapha + Number(ans.score))
      }
    }
  }


  return <div className='question-page' style={{ height: "100vh", backgroundImage: "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)" }}>

    <div className='Question-box'>{

      isData && <>
        <div className='progressbar'><ProgressBar completed={currentQuestion} maxCompleted={30} customLabel=" " bgColor='blue' className='progress' /></div>
        <Fade><div className='question-text'>{Data[currentQuestion].question}</div></Fade>
        <div className='answers'>
          {
            Object.values(Data[currentQuestion]?.answers).map((e, i) => {
              return <div key={i} className='Answer' >
                <Slide>  <img src={e?.image} className='answerimg animate__backInLeft'></img></Slide>

                <Slide><p className={answers[Data[currentQuestion].question] === e.answer ? 'Active' : 'Answer_content'} key={i} onClick={() => { SubmitAnswer(e, i) }} onPointerUp={() => handlePTagTouchStart(i)}
                  onPointerLeave={() => handlePTagTouchEnd(i)}

                >{e?.answer}</p> </Slide>


              </div>
            })
          }
        </div>
        <div className='action-buttons'>
          {
            currentQuestion != 0 ?
              <div className='PrevQ'>

                <IconButton>
                  <ArrowBackIcon onClick={previousQuestion} />
                </IconButton>
              </div> :
              <></>
          }
          {
            answers[Data[currentQuestion]?.question] != undefined ?
              <div className='PrevQ'>
                <IconButton>
                  <ArrowForwardIcon onClick={handleQuestion} />
                </IconButton>

              </div> : <></>}
        </div>
      </>}

    </div>
  </div>


}

export default Prakriti
