import React from 'react'
import axios from 'axios'
import { IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom'
import { setAuthToken } from '../Auth/setAuthToken';
import { useEffect, useState } from 'react';
import placed from '../Animations/order_placed.json'
import cancelled from '../Animations/order_cancelled.json'
import confirmed from '../Animations/order_confirmed.json'
import delivered from '../Animations/order_delivered.json'
import shipped from '../Animations/order-shipped.json'
import EmptyCart from '../Animations/animation_llq4z1kr.json'
import Lottie from 'lottie-react';
import { Button } from '@mui/material';
function Orders() {
  let navigate = useNavigate()
  let [orders, setOrders] = useState([])
  let userdetails = localStorage.getItem('Userdetails')
  let refreshtoken = JSON.parse(userdetails)
  let [count, setCount] = useState(0)

  let getOrders = async () => {
    try {
      let Token = localStorage.getItem('jwt-token')
      setAuthToken(Token)
      let res = await axios.post('https://api.ayurai.in/api/data/getOrders')
      if (res.status === 200) {
        setOrders(res.data)
      }
    }
    catch (error) {
      if (error?.response?.data?.error.message === 'jwt expired' || error?.response?.data?.error.status === 500) {
        renew()
      }
    }
  }

  let renew = async () => {

    try {
      let res = await axios.post(`https://api.ayurai.in/auth/renew`, {
        email: refreshtoken.email,
        token: refreshtoken.refreshToken
      })
      if (res.status === 200) {

        let data = res.data
        console.log(data)
        localStorage.setItem('jwt-token', data.access_token)

        setCount(count + 1)

      }
    }
    catch (error) {
      console.log(error)
    }
  }

  function sortDateTime(dateTimeStr) {
    const [date, time] = dateTimeStr.split(' ');
    const [day, month, year] = date.split('/');
    const [hours, minutes, seconds] = time.split(':');
    return new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`);
  }

  useEffect(() => {
    getOrders()
  }, [count])

  return <>
    <div className='Cart-header'>
      <IconButton onClick={() => {
        navigate(-1)
      }}>
        <ArrowBackIcon />
      </IconButton>
      &nbsp; &nbsp; &nbsp;
      Order History </div>

    {orders?.length > 0 ?
      <div className='Orders-page'>
        {
          orders.sort((a, b) => sortDateTime(b.Data.dateandtime) - sortDateTime(a.Data.dateandtime))?.map((e, i) => {
            return <div className='Orders-card' key={i} onClick={() => {
              navigate(`/Order/${e?.Data?.orderid}`)
            }}>
              <div className='Orders-card-elements'>
                <div>
                  <p className='mb-0'>Order id &nbsp;&nbsp;&nbsp;<span>{e?.Data?.orderid}</span></p>
                  <p className='mb-0'>Placed on&nbsp;&nbsp;&nbsp;<span>{e?.Data?.dateandtime}</span></p>
                  <p className='mb-0'>Purchase Total &nbsp;&nbsp;&nbsp;<span style={{ color: 'green', fontFamily: "cursive" }}>₹{(Number(e?.Data?.total) + Number(e?.Data?.deliverycharges)) - Number(e?.Data?.discount)}</span></p>
                  
                </div>
                <div>
                  <Lottie animationData={e?.Data?.orderstatus === "placed" ? placed : e?.Data?.orderstatus === "confirmed" ? confirmed : e?.Data?.orderstatus === "delivered" ? delivered : e?.Data?.orderstatus === "cancelled" ? cancelled : e?.Data?.orderstatus === "shipped" ? shipped : shipped}></Lottie>
                </div>
              </div>
              <div className='Order-details'>
                <p className='mb-0'>Your order is {e?.Data?.orderstatus}</p>
                <div style={e?.Data?.orderstatus === 'cancelled' ? { color: "red", padding: '10px', backgroundColor: "#FFCCCB", borderRadius: '8px' } : e?.Data?.orderstatus === 'received' ? { color: "yellow", backgroundColor: '#FFFFE0', borderRadius: '8px', padding: '10px' } : e.Data.orderstatus === 'delivered' ? { color: 'green', backgroundColor: 'lightcyan', borderRadius: '8px', padding: '10px' } : e.Data.orderstatus === 'received' ? { color: "yellow", backgroundColor: '#FFFFE0', borderRadius: '8px', padding: '10px' } : e.Data.orderstatus === 'shipped' ? { color: 'blue', backgroundColor: '#ADD8E6', borderRadius: '8px', padding: '10px' } : {
                  color: "green", padding: '10px', backgroundColor: "#FFFFE0", borderRadius: '8px'
                }}>Order {e?.Data?.orderstatus}</div>
              </div>
            </div>
          })
        }
      </div> : <div className='Cart-empty'>
        <Lottie animationData={EmptyCart} className='Cart-empty-animation' />
        <p>Your Order List is empty</p>
        <Button id='Checkout' style={{ marginTop: "0" }} onClick={() => {
          navigate('/Shop')
        }}>Continue</Button>
      </div>}
  </>
}

export default Orders