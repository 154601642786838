import React from 'react'
import Lottie from 'lottie-react'
import axios from 'axios'
import { Navigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { setAuthToken } from '../Auth/setAuthToken'
import { useNavigate } from 'react-router-dom'
import AppointmentSuccess from '../Animations/animation_lnb9cj0j.json'
function AppointmentBooked() {
    const { appointmentID } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
     setTimeout(()=>{
       navigate(`/Appointment/${appointmentID}`)
     },2000)
    }, [])

    return <div className='appointment-booked'>
        <Lottie animationData={AppointmentSuccess} className='AppointmentBookedanime'></Lottie>
        <p>Appointment Booked</p>
    </div>
}

export default AppointmentBooked