import { createSlice } from "@reduxjs/toolkit";

const initialState={
    Appointment:localStorage.getItem("Doc")?JSON.parse(localStorage.getItem("Doc")):[],
}
const AppointmentSlice=createSlice({
    name:'Appointment',
    initialState,
    reducers:{
        AddDoctor(state,action){
          state.Appointment={
          Doctor:action.payload.Doctor,
          Dateandtime:{},Payment:{},appointmentID:`APPOINTMENT_${Date.now()}`}
          localStorage.setItem('Doc',JSON.stringify(state.Appointment))
        },
        AppointmentDate(state,action){
           state.Appointment['Dateandtime']=action.payload
           localStorage.setItem('Doc',JSON.stringify(state.Appointment))
        },
        Updatepayment(state,action){
           state.Appointment['Payment']=action.payload
           localStorage.setItem('Doc',JSON.stringify(state.Appointment))
        },
        RemoveAppointment(state){
            state.Appointment.splice(0)
            localStorage.setItem('Doc',JSON.stringify(state.Appointment))
        }}
    })

export const {AddDoctor,AppointmentDate,Updatepayment,RemoveAppointment}=AppointmentSlice.actions

export default AppointmentSlice.reducer