import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuid } from 'uuid';
import { jsonSchema } from "uuidv4";
const id = uuid();

const initialState = ({
    Address: localStorage.getItem("Address") ? JSON.parse(localStorage.getItem("Address")) :
        [],
    Shipping_Address: localStorage.getItem("Shipping_Address") ? JSON.parse(localStorage.getItem("Shipping_Address")) :
        [],
    User_Profile: localStorage.getItem("Profile") ? JSON.parse(localStorage.getItem("Profile")) : []

})

const ProfileSlice = createSlice({
    name: 'Profile',
    initialState,
    reducers: {
        addAddress(state, action) {
            const isDuplicate = state.Address.some(address => {
                const { id: _, ...restAddress } = address;
                return JSON.stringify(restAddress) === JSON.stringify(action.payload);
            });

            if (!isDuplicate) {
                state.Address.push({ ...action.payload, id: id });
                localStorage.setItem("Address", JSON.stringify(state.Address));
            }
        },
        editAddress(state, action) {
            let findAddress = state.Address.findIndex((e) => e.id == action.payload.id)
            state.Address[findAddress] = action.payload
            localStorage.setItem("Address", JSON.stringify(state.Address))
        },
        Shipping_Address(state, action) {
            state.Shipping_Address = { ...action.payload }
            localStorage.setItem("Shipping_Address", JSON.stringify(state.Shipping_Address))
        },
        Add_Profile(state, action) {
            state.User_Profile = action.payload
            localStorage.setItem("Profile", JSON.stringify(state.User_Profile))
        }
    }
})


export const { addAddress, editAddress, Shipping_Address, Add_Profile } = ProfileSlice.actions
export default ProfileSlice.reducer