import React from 'react'
import Lottie from 'lottie-react'
import bg from '../Animations/drt893i1nw (1).json'
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { contextAPI } from '../Context';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, IconButton } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import StarIcon from '@mui/icons-material/Star';
import { useDispatch } from 'react-redux';
import { AddDoctor } from './AppointmentSlice';
import FavoriteIcon from '@mui/icons-material/Favorite';
function Doctor_Page() {
  let data = useContext(contextAPI)
  let navigate = useNavigate()
  let { id } = useParams()
  let dispatch = useDispatch()

  const addDoctor = () => {
    let Doctor = data?.Doctors?.filter((s) => {
      if (s?.Data?.PID === id) {
        return s
      }
    })[0]

    dispatch(
      AddDoctor({ Doctor })
    )

    navigate('/BookAppointment')

  }

  return <>{
    data?.Doctors?.filter((s) => {
      if (s?.Data?.PID === id) {
        return s
      }
    }).map((e) => {
      return <>
        <div className='Navbar' style={{ backgroundColor: '#0076BE', border: "none" }}>
          <div>
            <IconButton onClick={() => {
              
              navigate(-1)
            }} style={{ backgroundColor: "white" }}>
              <ArrowBackIcon />

            </IconButton>
          </div>
          <div><IconButton style={{ backgroundColor: 'white', marginRight: "1rem" }}><FavoriteIcon /></IconButton></div>
        </div>
        <div style={{ backgroundColor: '#0076BE', display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>

          <Lottie animationData={bg} loop={true} style={{ backgroundColor: '#0076BE', width: "24rem" }} />

          <div className='Doc-img-container'>
            <div className='Doc-img'>

              <img style={{ width: "12rem", height: "12rem", borderRadius: "50%", objectFit: "cover",objectPosition:"top" }} src={e.Data.photo}>

              </img>

            </div>
          </div>
          
          <div className='Doc-img-container'>
            <div className='Doc-moredetails'>
              <div style={{ backgroundColor: 'white' }} className='Doc-details' >
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className='Doc-basic-detail'>
                  <div>
                    <p className='mb-0'>{e?.Data?.name}</p>
                    <p className='mb-0'>{e?.Data?.speciality}</p>
                  </div>
                  <div>
                    <p className='mb-0'>₹{e?.Data?.pay}/hr</p>
                  </div>
                </div>
                <div className='Doc-portfolio'>
                  <div>
                    <PersonIcon style={{ color: "blue", fontSize: "2rem" }} />
                    <p className='mb-0'>{e?.Data?.treated}</p>
                    <p className='mb-0'>Patients treated</p>
                  </div>
                  <div>
                    <WorkIcon style={{ color: "#60ac68", fontSize: "2rem" }} />
                    <p className='mb-0'>{e?.Data?.experience}</p>
                    <p className='mb-0'>Experience</p>
                  </div>
                  <div>
                    <StarIcon style={{ color: "#FFD700", fontSize: "2rem" }} />
                    <p className='mb-0'>{e?.Data?.rating}</p>
                    <p className='mb-0'>Rating</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className='abt-doc'>
          <h2>Description</h2>
          <p className='mb-0'>{e?.Data?.about}</p>
          <h2>Languages Known</h2>
          <div className='Known-languages'>
            {
              e?.Data?.language_known.split(",").map((lang) => {
                return <div>
                  {lang}
                </div>
              })
            }
          </div>
          <h2>Available Timings</h2>
          <div className='Known-languages'>
            
              
                 <div>
                  {e?.Data?.timing.split(",")[0]} -  {e?.Data?.timing.split(",")[e?.Data?.timing.split(",").length-1]}
                </div>
              
            
          </div>

        </div>
        <div className='bookappointmentbtn'>
            <Button style={{ backgroundColor: '#0076BE', color: "white"}} id='bookappointmentbtn' onClick={()=>{
              addDoctor()
            }}>Book Appointment</Button>
          </div>
      </>
    })}</>
}

export default Doctor_Page