import React, { useState } from 'react'
import forgotbg from '../Animations/forgotpassword.json'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, IconButton } from '@mui/material';
import Lottie from 'lottie-react'
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
function Forgot() {
    let navigate = useNavigate()
    let [email, setEmail] = useState('')
    const forgotPassword = async () => {
        try {
            let res = await axios.post('https://api.ayurai.in/auth/forgotPassword', {
                email
            })

            if (res.status === 200) {
                navigate(`/Otp/${email}`)
            }

        }
        catch (error) {
            alert(error.response.data.error)
        }
    }
    return <>
        <div className='Cart-header'>
            <IconButton onClick={() => {
                navigate(-1)
            }}>
                <ArrowBackIcon />
            </IconButton>
        </div>
        <div className='fp-page'>
            <Lottie animationData={forgotbg} loop={true} id="fp-animation"></Lottie>
            <div className='fg-content'>
                <h2>Forgot Password?</h2>
                <p>Don't worry it happens.please enter the address associated with your account</p>

                <TextField id="outlined-basic" label="Email" variant="outlined" className='fp-text-field' onChange={(e) => {
                    setEmail(e.target.value)
                }} />
                <Button id='lgbtn' onClick={forgotPassword}>submit</Button>
            </div>
        </div>
    </>
}

export default Forgot