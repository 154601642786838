import React from 'react'
import { useEffect, useState, useContext, useRef } from 'react';
import { Button } from '@mui/material';
import RestoreIcon from '@mui/icons-material/Restore';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { addToCart } from './CartSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux/es/hooks/useSelector'
import { Container, Title, Accordion, createStyles, rem } from '@mantine/core';
import { IconButton } from '@mui/material';
import { contextAPI } from '../Context';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { useNavigate } from 'react-router-dom';
import { InsertEmoticon } from '@mui/icons-material';
// import Lab_test from './Lab-test';
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));
function Lab_test_page() {
  let { id } = useParams()
  let navigate = useNavigate()
  let dispatch = useDispatch()
  const [state, setState] = useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  let data = useContext(contextAPI)
  let cart = useSelector((state) => state.cart)

  useEffect(() => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
  }, [id])


  const useStyles = createStyles((theme) => ({
    wrapper: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
      minHeight: 650,
    },

    title: {
      marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },

    item: {
      borderRadius: theme.radius.md,
      marginBottom: theme.spacing.lg,
      border: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
        }`,
    },
  }));


  const { classes } = useStyles();

  const handleAddtocart = (Product) => {
    dispatch(addToCart(Product))
  }

  const Buynow = (Product) => {
    dispatch(addToCart(Product))
    navigate("/Cart")
  }
  return <>
    <div className='Navbar'>
      <div> <Button onClick={() => {
        navigate(-1)
      }}><ArrowBackIcon /></Button></div>
      <div style={{ marginRight: "2rem" }}><IconButton><RestoreIcon /></IconButton><IconButton
        onClick={() => {
          navigate('/Cart')
        }}>{
          cart.cartitems.length > 0 ?
            <StyledBadge badgeContent={cart.cartitems.length}
              color="error">
              <ShoppingCartIcon />
            </StyledBadge> : <ShoppingCartIcon />}</IconButton></div>
    </div>
    <div>
      {data.lab_tests.filter((s) => {
        if (id == s.product_id) {
          return s
        }
      }).map((e, i) => {
        return <div className='Product-Page' key={i}>
          <div className='Product-image mb-0'>
            <img src={e?.imgurl} ></img>
            <div className='Product-imp-details mb-0' >
              <p className='mb-0'>{e?.name}&nbsp;<span id='qty'>({e?.qty})</span></p>
              <span className='mb-0'>{e?.desc}</span>
              <div style={{ display: "flex", gap: "1rem" }} className='mb-0'>
                <p className='mb-0'>₹ {e?.price}</p> {
                  e?.orgprice > 0 ? <p className='mb-0'><del>₹ {e?.orgprice}</del></p> : <></>}</div>
            </div>


          </div>
          <div className='About-section'>
            {e?.detaildesc && <p id="Abt-t" className='mb-0'>About the Product</p>}
            {e?.detaildesc && <div id='abt' className='mb-0'> {e?.detaildesc.split('//').map((e) =>
              <p>{e}</p>)}</div>}

            <div className='Shop-btns'>
              <Button id='buynowbtn'
                onClick={() => {
                  Buynow(e)
                }}><ShoppingBagIcon style={{ color: 'grey' }} />&nbsp;&nbsp;&nbsp;Buy now</Button>
              <Button id='addtocartbtn' onClick={() => {
                handleAddtocart(e)
              }}><ShoppingCartIcon />&nbsp;&nbsp;&nbsp;Add to Cart</Button>
            </div>
            {id==='9545783'?
              <div className='sample-pdf' onClick={() => {
                navigate('/Sample_report')
              }}>
                <InsertDriveFileIcon style={{ color: "lightgreen", fontSize: "4rem" }}></InsertDriveFileIcon>
                <div>
                  <p className='mb-0'>Bugspeaks Sample Report</p>
                  <p className='mb-0'>Click to see the report</p>
                </div>
              </div>:<div className='sample-pdf' style={{visibility:"hidden"}}></div>
            }
          </div>

        </div>
      })
      }
    </div>
  </>
}

export default Lab_test_page