import React, { useEffect } from 'react'
import RestoreIcon from '@mui/icons-material/Restore';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, IconButton } from '@mui/material';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { SearchOutlined } from '@mui/icons-material'
import { useState, useContext } from 'react';
import ReactStars from "react-rating-stars-component";
import { contextAPI } from '../Context';
import axios from 'axios';
import { setAuthToken } from '../Auth/setAuthToken';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
function TeleConsultation_page() {
  let navigate = useNavigate()
  const [search, setsearch] = useState('')
  let Token = localStorage.getItem('jwt-token')
  let userdetails = localStorage.getItem('Userdetails')
  let refreshtoken = JSON.parse(userdetails)
  let data = useContext(contextAPI)
  let [count,setCount]=useState(0)

  let getDoctors = async () => {
    try {
      setAuthToken(Token)
      let response = await axios.post('https://api.ayurai.in/api/data/getallDoctors')
      data?.SetDoctors(response?.data)
    }
    catch (error) {
      if (error?.response?.data?.error.message === 'jwt expired' || error?.status === 500) {
        renew()
      }
    }
  }

  let renew = async () => {

    try {
        let res = await axios.post(`https://api.ayurai.in/auth/renew`, {
            email: refreshtoken?.email,
            token: refreshtoken?.refreshToken
        })
        if (res.status === 200) {

            let data = res.data

            localStorage.setItem('jwt-token', data?.access_token)

            setCount(count + 1)

        }
    }
    catch (error) {
        console.log(error)
    }
}

  useEffect(() => {
    getDoctors()
  }, [count])

  return <><div className='Navbar'>
    <div><IconButton onClick={() => {
      navigate('/')
    }}>
      <ArrowBackIcon />
    </IconButton></div>
    <div><Button><RestoreIcon /></Button><Button><ShoppingCartIcon /></Button></div>
  </div>
    <div className='tele'>
      <div className='Search-bar'>
        <div className='search-box2'>
          <SearchOutlined style={{ fontSize: '3rem' }} />
          <input type="text" placeholder="Search" onChange={(e) => { setsearch(e.target.value) }} />

        </div>
      </div>
      <div className='tele-list'>
        <div className='tele-list-card' onClick={() => {
          navigate('/Appointment_page')
        }}>
          <div className='tele-list-icon'><PermContactCalendarIcon /></div>
          <p className='mb-0'>My Appointments</p>
        </div>
        <div className='tele-list-card'>
          <div className='tele-list-icon'><i class="fa-solid fa-user-doctor"></i></div>
          <p className='mb-0'>My Doctors</p>
        </div>
      </div>
    </div>
    {/* <div className='upcoming-schedules'>
      <p className='mb-0'>Your upcoming schedules</p>
    </div> */}
    <div className='Doctors-list'>
      <p className='mb-0'>Top Doctors</p>
      
      <div className='All-Doctor'>
        {
          data?.Doctors.map((e, i) => {
            return <div className='All-Doctor-card' key={i} onClick={() => {
              navigate(`/Doc/${e.Data.PID}`)
            }}>
              <div>
                <img className='mb-0' src={e.Data.photo}></img>
              </div>

              <div>
                <p className='mb-0'>{e.Data.name}</p>
                <p className='mb-0'>{e.Data.speciality}</p>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px" }}><ReactStars value={parseInt(e.Data.rating)} size={30} edit={false} /><span>{e.Data.rating}</span></div>
              </div>
            </div>
          })
        }
      </div>
    </div>
  </>
}

export default TeleConsultation_page