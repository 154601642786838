import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { contextAPI } from './Context';
import axios from 'axios';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { getDatabase, ref, onValue, } from "firebase/database";
import { useMediaQuery } from '@mui/material';
import { useContext, useEffect } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import ReactStars from "react-rating-stars-component";
import { setAuthToken } from './Auth/setAuthToken';
function Result() {
  let { type } = useParams()
  let navigate = useNavigate()
  let Token = localStorage.getItem('jwt-token')
  let data = localStorage.getItem('Userdetails')
  let userdetails = JSON.parse(data)
  let [count, setCount] = useState(0)
  let [PrakritiDominant, setPrakritiDominant] = useState([])
  let [PrakritiResult, setPrakritiResult] = useState('vatta')
  let [PrakritiDisease, setPrakritiDisease] = useState('pitta_disease')
  let { setPrakritiresult, setvikritiresult, setinterest, setDisease, vikritiresult, prakritiresult, setvikriti, prakriti_data, setPrakriti, vikriti_data, Doctors, SetDoctors } = useContext(contextAPI)

  let results = {
    V: "VATA",
    P: "PITTA",
    K: "KAPHA",
    VP: "VATA PITTA",
    KP: "KAPHA PITTA",
    PV: "PITTA VATA",
    PK: "PITTA KAPHA",
    VPK: "VATA PITTA KAPHA",
    KPV: "KAPHA PITTA KAPHA",
    PKV: "PITTA KAPHA VATTA",
    PVK: "PITTA VATA KAPHA",
    KVP: "KAPHA VATA PITTA",
    VK: "VATA KAPHA",
    KV: "KAPHA VATA",
  }

  let getDoctors = async () => {
    try {
      setAuthToken(Token)
      let response = await axios.post('https://api.ayurai.in/api/data/getallDoctors')
      SetDoctors(response?.data)
    }
    catch (error) {
      if (error?.response?.data?.error.message === 'jwt expired' || error?.status === 500) {
        renew()
      }
    }
  }

  let renew = async () => {

    try {
      let res = await axios.post(`https://api.ayurai.in/auth/renew`, {
        email: userdetails?.email,
        token: userdetails?.refreshToken
      })
      if (res.status === 200) {

        let data = res.data

        localStorage.setItem('jwt-token', data?.access_token)

        setCount(count + 1)

      }
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getDoctors()
  }, [count])

  let PrakritiResults = () => {
    const db = getDatabase();
    const Ref = ref(db, `/prakritiresult`);
    onValue(Ref, async (snapshot) => {
      try {
        const data = await snapshot.val()

        if (PrakritiDominant === "Vata") {
          setPrakritiResult(data?.vata)
          setPrakritiDisease(data?.vata_disease)
        }
        if (PrakritiDominant === 'Pitta') {
          setPrakritiResult(data?.pitta)
          setPrakritiDisease(data?.pitta_disease)
        }
        if (PrakritiDominant === 'Kapha') {
          setPrakritiResult(data?.kapha)
          setPrakritiDisease(data?.kapha_disease)
        }

      }
      catch (error) {
        console.log(error)
      }

    })
  }

  let getPrakriti = async () => {
    try {
      let res = await axios.post('https://api.ayurai.in/api/data/getPrakritiforDoctors', {
        UID: userdetails?.id
      })

      if (res.status === 200) {

        setPrakriti(res?.data?.Data)
        let alldata = [res?.data?.Data?.vata, res?.data?.Data?.pitta, res?.data?.Data?.kapha]
        let DominantScore = Math.max(...alldata)
        if (res?.data?.Data?.vata === DominantScore) {
          setPrakritiDominant("Vata")
        }

        if (res?.data?.Data?.pitta === DominantScore) {
          setPrakritiDominant("Pitta")
        }

        if (res?.data?.Data?.kapha === DominantScore) {
          setPrakritiDominant("Kapha")
        }
        setPrakritiresult(res?.data?.Data?.result)
        PrakritiResults()

      }
    }

    catch (error) {
      if (error?.response?.data === 'No Document') {

      }
    }

  }

  function calculateVikriti(vatfinal, pitfinal, kapfinal) {
    let stringJoiner = new Array();
    let unsortMap = new Map();

    function addToMap(condition, key, value, message) {
      if (condition) {
        if (stringJoiner.length >= 1) {
          if (value > 10) {
            unsortMap.set(key, value);
          }
        } else {
          unsortMap.set(key, value);
          console.log(message.toLowerCase());
        }
      }
    }

    addToMap(vatfinal > 0, "V", vatfinal, "V");
    addToMap(pitfinal > 0, "P", pitfinal, "P");
    addToMap(kapfinal > 0, "K", kapfinal, "K");

    let sortedMapAsc = new Map([...unsortMap.entries()].sort((a, b) => b[1] - a[1]));
    sortedMapAsc.forEach((value, key) => {
      stringJoiner.push(key);
    });

    return stringJoiner.join('');
  }
  let getvikriti = async () => {

    try {
      setAuthToken(Token)
      let res = await axios.post('https://api.ayurai.in/api/data/getVikriti')

      if (res.status === 200) {

        setvikriti(res?.data?.Data)
        let vikritiresult = res?.data?.Data.VIKRITIVATAPULSE == 0 && res?.data?.Data?.VIKRITIPITTAPULSE == 0 && res?.data?.Data?.VIKRITIKAPHAPULSE == 0 ?
          [((res?.data?.Data?.vatascore / res.data.Data?.count) * 100 * 0.7), ((res?.data?.Data?.pittascore / res?.data?.Data?.count) * 100 * 0.7),

          ((res?.data?.Data?.kaphascore / res?.data?.Data?.count) * 100 * 0.7)] : res?.data?.Data?.count === 0 ?

            [((
              res?.data?.Data?.
                VIKRITIVATAPULSE) / (res?.data?.Data?.
                  VIKRITIVATAPULSE + res?.data?.Data?.
                    VIKRITIPITTAPULSE + res?.data?.Data?.
                    VIKRITIKAPHAPULSE) * 100 * 0.3), ((
                      res?.data?.Data?.
                        VIKRITIPITTAPULSE) / (res?.data?.Data?.
                          VIKRITIVATAPULSE + res?.data?.Data?.
                            VIKRITIPITTAPULSE + res?.data?.Data?.
                            VIKRITIKAPHAPULSE) * 100 * 0.3),

            ((res?.data?.Data?.
              VIKRITIKAPHAPULSE) / (res?.data?.Data?.
                VIKRITIVATAPULSE + res?.data?.Data?.
                  VIKRITIPITTAPULSE + res?.data?.Data?.
                  VIKRITIKAPHAPULSE) * 100 * 0.3)]
            :
            [((res?.data?.Data?.vatascore / res?.data?.Data?.count) * 100 * 0.7) + ((
              res?.data?.Data?.
                VIKRITIVATAPULSE) / (res?.data?.Data?.
                  VIKRITIVATAPULSE + res?.data?.Data?.
                    VIKRITIPITTAPULSE + res?.data?.Data?.
                    VIKRITIKAPHAPULSE) * 100 * 0.3), ((res?.data?.Data?.pittascore / res?.data?.Data?.count) * 100 * 0.7) + ((
                      res?.data?.Data?.
                        VIKRITIPITTAPULSE) / (res?.data?.Data?.
                          VIKRITIVATAPULSE + res?.data?.Data?.
                            VIKRITIPITTAPULSE + res?.data?.Data?.
                            VIKRITIKAPHAPULSE) * 100 * 0.3),

            ((res?.data?.Data?.kaphascore / res?.data?.Data?.count) * 100 * 0.7) + ((
              res?.data?.Data?.
                VIKRITIKAPHAPULSE) / (res?.data?.Data?.
                  VIKRITIVATAPULSE + res?.data?.Data?.
                    VIKRITIPITTAPULSE + res?.data?.Data?.
                    VIKRITIKAPHAPULSE) * 100 * 0.3)]


        const result = calculateVikriti(vikritiresult[0], vikritiresult[1], vikritiresult[2])
        setvikritiresult(result)
      }
    }
    catch (error) {
      if (error?.response?.data?.error.message === 'jwt expired' || error?.status === 500) {
        renew()
      }
    }
  }
  useEffect(() => {
    if (type === 'Prakriti') {
      getPrakriti()
    }
    else {
      getvikriti()
    }

  }, [count])

  useEffect(() => {
    PrakritiResults()
  }, [PrakritiDominant])

  const palette = ['rgb(100,181,246)', 'rgb(229,115,115)', 'rgb(129,199,132)'];

  const isDesktop = useMediaQuery('(min-width: 1024px)');


  return <div>
    <div className='P-page'>
      <IconButton onClick={() => {
        navigate(-1)
      }}>
        <ArrowBackIcon />
      </IconButton>
      {/* <Lottie animationData={Bot} style={{ width: "5rem" }}></Lottie> */}
    </div>
    <>{
      type === 'Prakriti' ? <div>

        <div className='Prakriti-result'>
          <p>Your Prakriti is</p>
          <p>{results[prakritiresult]}</p>
        </div>
        <div className='Pie-chart'>
          <div>
            <PieChart
              colors={palette}

              width={350}
              height={300}
              series={[
                {
                  arcLabel: (item) => `${item.name} \n${item.value.toFixed(1)}%`,
                  data: [{ value: prakriti_data?.vata, name: 'Vata', }, { value: prakriti_data?.pitta, name: 'Pitta' }, { value: prakriti_data?.kapha, name: 'Kapha' }]
                },
              ]}
              sx={{
                [`& .${pieArcLabelClasses.root}`]: {
                  fill: 'white',
                  fontWeight: 'bold',
                },
              }}

            // {...size}
            />

          </div>

          <p >
            Prakriti literally means nature. In this context, Prakriti refers to baseline healthy nature of an individual. Prakriti signifies the inherent proportion of three doshas and the deviation from the baseline doshas causes disease (Vikriti). It is holistically assessed from the physiological, anatomical, psychological and physical characteristics of an individual
          </p>
        </div>

        <div className='Prakriti-Dominance-result'>
          <div>
            <h4>You have {results[prakritiresult]} Prakriti</h4>
            <ul>
              {PrakritiResult &&
                PrakritiResult.split('/n').map((e) => {
                  return <li><span><DoubleArrowIcon style={{ color: "rgb(129,199,132)" }} /></span>&nbsp;&nbsp;{e}</li>
                })
              }
            </ul>
          </div>
          <div>
            <h4>Susceptible disorders for {results[prakritiresult]} Prakriti</h4>
            <div>
              {
                PrakritiDisease.split(',').map((e) => {
                  return <div>{e}</div>
                })
              }
            </div>

          </div>
          <div className='p-n-btn'>
            <Button style={{
              backgroundColor: "#007BFF",
              color: "white",
              padding: "10px",
              width: '100%'

            }}
              onClick={() => { navigate('/') }}>Next</Button>
          </div>
        </div>


      </div> : <div>
        <div className='Vikriti-Bar-chart'>
          {vikritiresult != '' ?
            <>
              {vikriti_data.VIKRITIVATAPULSE == 0 && vikriti_data.VIKRITIPITTAPULSE == 0 && vikriti_data.VIKRITIKAPHAPULSE == 0 ?
                <BarChart
                  width={!isDesktop ? 300 : 500}
                  height={300}
                  colors={palette}
                  series={[{
                    data: [((vikriti_data?.vatascore / vikriti_data?.count) * 100 * 0.7 || 0), ((vikriti_data.pittascore / vikriti_data.count) * 100 * 0.7 || 0),

                    ((vikriti_data.kaphascore / vikriti_data.count) * 100 * 0.7 || 0)],
                    type: 'bar'
                  }]}

                  xAxis={[{ scaleType: 'band', data: ['Vata', 'Pitta', 'Kapha'] }]}

                /> : vikriti_data?.count === 0
                  ? <BarChart

                    width={!isDesktop ? 250 : 500}
                    height={300}
                    colors={palette}
                    series={[{
                      data: [((
                        vikriti_data?.
                          VIKRITIVATAPULSE) / (vikriti_data?.
                            VIKRITIVATAPULSE + vikriti_data?.
                              VIKRITIPITTAPULSE + vikriti_data?.
                              VIKRITIKAPHAPULSE) * 100 * 0.3 || 0), ((
                                vikriti_data?.
                                  VIKRITIPITTAPULSE) / (vikriti_data?.
                                    VIKRITIVATAPULSE + vikriti_data?.
                                      VIKRITIPITTAPULSE + vikriti_data?.
                                      VIKRITIKAPHAPULSE) * 100 * 0.3 || 0),

                      ((
                        vikriti_data?.
                          VIKRITIKAPHAPULSE) / (vikriti_data?.
                            VIKRITIVATAPULSE + vikriti_data?.
                              VIKRITIPITTAPULSE + vikriti_data?.
                              VIKRITIKAPHAPULSE) * 100 * 0.3 || 0)],
                      type: 'bar'
                    }]}
                    xAxis={[{ scaleType: 'band', data: ['Vata', 'Pitta', 'Kapha'] }]}

                  /> : <BarChart

                    width={!isDesktop ? 250 : 500}
                    height={300}
                    colors={palette}
                    series={[{
                      data: [((vikriti_data?.vatascore / vikriti_data?.count) * 100 * 0.7) + ((vikriti_data?.
                        VIKRITIVATAPULSE) / (vikriti_data?.
                          VIKRITIVATAPULSE + vikriti_data?.
                            VIKRITIPITTAPULSE + vikriti_data?.
                            VIKRITIKAPHAPULSE) * 100 * 0.3 || 0), ((vikriti_data.pittascore / vikriti_data.count) * 100 * 0.7) + ((
                              vikriti_data?.
                                VIKRITIPITTAPULSE) / (vikriti_data?.
                                  VIKRITIVATAPULSE + vikriti_data?.
                                    VIKRITIPITTAPULSE + vikriti_data?.
                                    VIKRITIKAPHAPULSE) * 100 * 0.3 || 0),

                      ((vikriti_data.kaphascore / vikriti_data.count) * 100 * 0.7) + ((
                        vikriti_data?.
                          VIKRITIKAPHAPULSE) / (vikriti_data?.
                            VIKRITIVATAPULSE + vikriti_data?.
                              VIKRITIPITTAPULSE + vikriti_data?.
                              VIKRITIKAPHAPULSE) * 100 * 0.3) || 0], type: 'bar'
                    }]}

                    xAxis={[{ scaleType: 'band', data: ['Vata', 'Pitta', 'Kapha'] }]} />}

              {vikritiresult != 'na' &&
                <div className='Prakriti-result'>
                  <p>Your aggravated Dosha is</p>
                  <p>{results[vikritiresult]?.toUpperCase()} <TrendingUpIcon style={{ color: "red" }} /></p>
                </div>
              }</>
            : <>
              {vikriti_data?.count === 0 ?
                <p>"Since no symptoms were selected for Vikriti, recommendations will be based on your Prakriti"</p>
                : <p>"Since there are no changes in your Vikriti score, the recommendations will be made according to your Prakriti".</p>}

            </>
          }
          
          <div className='mb-5 Consultationcard'>
          <p>For a better understanding of your health, consult our health coach now</p>
            {
              Doctors.map((e, i) => {
                return <div className='All-Doctor-card m-2' key={i} onClick={() => {
                  navigate(`/Doc/${e.Data.PID}`)
                }}>
                  <div>
                    <img className='mb-0' src={e.Data.photo}></img>
                  </div>

                  <div>
                    <p className='mb-0'>{e.Data.name}</p>
                    <p className='mb-0'>{e.Data.speciality}</p>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px" }}><ReactStars value={parseInt(e.Data.rating)} size={30} edit={false} /><span>{e.Data.rating}</span></div>
                  </div>
                </div>
              })
            }
          </div>
          <div className='p-n-btn'>
            <Button style={{
              backgroundColor: "#007BFF",
              color: "white",
              padding: "10px",
              width: '100%'

            }}
              onClick={() => { navigate('/') }}>Next</Button>
          </div>
        </div>
      </div>
    }
    </>
  </div>
}

export default Result
