import React from 'react'
import { useContext } from 'react';
import { contextAPI } from '../Context';
import { useNavigate } from 'react-router-dom';
function Supplements() {

    let { interests, products, disease } = useContext(contextAPI)
    let data = ''
    if (interests?.interest && typeof interests.interest === 'string') {
        data += interests.interest.split(',').join(',');
    }
    if (disease?.disease && typeof disease.disease === 'string') {
        data += disease.disease.split(',').join(',');
    }
    let Interest = data.split(',').map((item) => item.trim());
    let navigate = useNavigate()

    return <div className='Nutraceutical mt-3'>
        <div className='Nutraceutical-list'>
            <div className='interest-list'>
                <p>Your Interest</p>
                <div style={{ display: "flex", justifyContent: 'space-evenly', alignItems: "center", gap: "1rem" }}>
                    {data?.split(',').map((e) => {
                        return <div >
                            <div style={{ border: "1px solid blue", padding: "12px", textAlign: "center", borderRadius: "10px" }}>{e}</div>
                        </div>
                    })

                    }</div>
            </div>
        </div>
        <div className='Product-list3'>{
            products?.filter((s) => {
                const SubTypes = s?.subtype.split(',').map(item => item?.trim());
                const Disease = s?.disease.split(',').map(item => item?.trim());
                if (Interest?.find(item => SubTypes?.includes(item)) || Interest?.find(item => Disease?.includes(item))) {
                    return s
                }
            }).sort((a, b) => a?.price - b?.price).map((e, i) => {
                return (
                    <div className='Product-card' key={i} onClick={() => {
                        navigate(`/Product/${e?.product_id}`)
                    }}>
                        {e.orgprice && e.orgprice != 0 && e.orgprice != e.price && e.orgprice > e.price ?
                            <div style={{ position: "absolute", display: "flex", alignSelf: "flex-end" }}>
                                <div className='discount-percentage top-0 '>
                                    <p className='mb-0'>{(((e.orgprice - e.price) / e.orgprice) * 100).toFixed(0) + "%" + " " + "off"}</p>
                                </div>
                            </div> :
                            <></>}
                        <img src={e?.imgurl} className='mb-0'></img>

                        <div className='product-card-details'>
                            <p className='mb-0'>{e?.name}</p>
                            <p className='mb-0'>{e?.desc}</p>

                            <div className='product-card-detail'>
                                <p className='mb-0'>Recommended for</p>
                                <div id="product-recommended-list" onClick={(event) => { event?.stopPropagation(); }}>
                                    <p className='mb-0'>{e?.subtype?.split(",")[0]}</p>
                                    {e?.subtype?.split(",")[1] && <p className='mb-0'>{e?.subtype?.split(",")[1]}</p>}
                                    {!e?.disease?.split(",")[0].includes("na") && <p className='mb-0'>{!e?.disease?.split(",").includes("na") && e?.disease?.split(",")[0]}</p>}
                                    {e?.disease?.split(",")[1] && <p className='mb-0'>{e?.disease?.split(",").includes("na") ? <></> : e?.disease?.split(",")[1]}</p>}
                                </div>
                                {/* <p>{e.subtype}</p> */}
                                <p style={{ display: "flex", gap: "1rem" }} className='mb-0'>
                      <p className='mb-0'>₹ {e?.price}</p> 
                      {
                        e?.orgprice > 0 && e.orgprice != e.price && e.orgprice > e.price ? <p className='mb-0'><del>₹ {e?.orgprice}</del></p> : <></>}</p>
                            </div>
                        </div>
                    </div>
                )
            })

        }
        </div>
    </div>
}

export default Supplements