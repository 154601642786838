import React from 'react'
import { IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { isSunday, isSaturday } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import noappointment from '../Animations/animation_lmyeh6ov.json'
import axios from 'axios';
import { setAuthToken } from '../Auth/setAuthToken';
import Lottie from 'lottie-react';
import { toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Updatepayment } from './AppointmentSlice';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { AppointmentDate } from './AppointmentSlice';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Phone from '../Animations/phoneconsult.json'
import Video from '../Animations/videoconsult.json'
function BookAppointment() {
    let navigate = useNavigate()
    const [selectedDay, setSelectedDay] = useState(null);
    const [formattedDay, setformated] = useState('')
    let userdetails = localStorage.getItem('Userdetails')
    let refreshtoken = JSON.parse(userdetails)
    let [count, setCount] = useState(0)
    let [Bookeddays, setBookeddays] = useState([])
    let dispatch = useDispatch()
    let data = useSelector((state) => state.Appointment.Appointment)
    let [open, setOpen] = useState(false);
    let [checked, setchecked] = useState(false)
    let today = new Date()

  
   


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleform = () => {
        if (checked) {
            navigate('/ConfirmAppointment')
        }
        else {

        }
    }

    const handleSubmit = () => {
        if (!data.Dateandtime.Date) {
            toast.info('Please select a date', {
                position: "top-center",
                limit: 1,
                transition: Zoom,
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark"
            })
        }

        if (!data.Dateandtime.time) {
            toast.info('Please select a time', {
                position: "top-center",
                limit: 1,
                transition: Zoom,
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark"
            })
        }

        if (!data.Payment.Mode) {
            toast.info('Please select a mode of consultation', {
                position: "top-center",
                limit: 1,
                transition: Zoom,
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark"
            })
        }
        else if (data.Dateandtime.Date && data.Dateandtime.time && data.Payment.Mode) {
            handleClickOpen()
        }
    }

    const handledate = (date) => {
        setSelectedDay(date)
        const inputDate = new Date(date);
        const day = inputDate.getDate();
        const month = inputDate.toLocaleString('default', { month: 'short' });
        const year = inputDate.getFullYear();
        const formattedDate = `${inputDate.toLocaleDateString('en-US', { weekday: 'short' })}, ${day} ${month} ${year}`;
        setformated(formattedDate)
    }


    const getDoctorsAppointment = async () => {
        try {
            let Token = localStorage.getItem('jwt-token')
            setAuthToken(Token)
            let res = await axios.post('https://api.ayurai.in/api/data/getDoctorsAppointment', {
                doctor_id: data?.Doctor?.Data?.PID
            })
            if (res.status === 200) {
                setBookeddays(res.data)

            }
        }
        catch (error) {
            if (error?.response?.data?.error.message === 'jwt expired' || error?.response?.data?.error.status === 500) {
                renew()
            }
        }
    }


    let renew = async () => {

        try {
            let res = await axios.post(`https://api.ayurai.in/auth/renew`, {
                email: refreshtoken?.email,
                token: refreshtoken?.refreshToken
            })
            if (res.status === 200) {

                let data = res.data

                localStorage.setItem('jwt-token', data?.access_token)

                setCount(count + 1)

            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const findBookedAppointments = Bookeddays.filter((e) => {
        if (e?.Data?.appointment_date === formattedDay && e?.Data?.appointment_status != 'cancelled') {
            return e
        }
    }).map((e) => {
        return e?.Data?.appointment_time
    })



    useEffect(() => {
        getDoctorsAppointment()
    }, [selectedDay])

    useEffect(() => {
        handledate(new Date())
    }, [])


    const consultationmodes = [{
        Mode: 'phone',
        Price: 200,
        actualPrice: 300
    }, {
        Mode: 'video',
        Price: 300,
        actualPrice: 400
    }]

    const modifiers = {
        saturday: (date) => isSaturday(date),
        sunday: (date) => isSunday(date),
    };

    const dayPickerProps = {
        mode: "single",
        selected: selectedDay,
        onSelect: (date) => {
            handledate(date);
        },
        disabled: (date) => date <= new Date(),
        modifiers,
        modifiersStyles: {
            saturday: { color: "red" },
            sunday: { color: "red" },
        },

    };

    return <>
        <div className='Cart-header'>
            <IconButton onClick={() => {
                navigate(-1)
            }}>
                <ArrowBackIcon />
            </IconButton>
        </div>

        <div className='BookAppointment-page'>
            <h4>Choose a date & time period for consultation</h4>
            <div className='Datepick'>
                {/* <div className='pickdate'> */}
                {/* <Calendar
                    value={selectedDay}
                    onChange={(date) => { handledate(date) }}
                    minimumDate={utils().getToday()}
                    shouldHighlightWeekends
                /> */}
                <DayPicker
                    {...dayPickerProps}
                />
                {
                    formattedDay.split(',').includes('Sat') || formattedDay.split(',').includes("Sun") ?
                        <div className='slotsunavailable'>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <p>Slots Unavailable</p>
                                <Lottie animationData={noappointment} style={{ width: '8rem' }}></Lottie>
                            </div>
                            <p>Sorry for the inconvenience caused. But the Doctor has no slots left for the day</p>
                        </div>
                        : <div>
                            <p id='timings'>Timings</p>
                            <div className='timings'>
                                {

                                    data?.Doctor?.Data?.timing.split(',').filter((e) => {


                                        const inputTimeStr = data?.Dateandtime?.time || '2:00 pm';
                                        const timeRegex = /^(\d{1,2}:\d{2} [apAP][mM])$/;

                                        if (!timeRegex.test(inputTimeStr)) {
                                            console.log("Invalid input time format. Please use the format 'hh:mm am/pm'.");
                                        } else {
                                            const currentTime = new Date().toLocaleTimeString('en-US', { hour12: true });
                                            function convertTo24Hour(timeStr) {
                                                const [time, period] = timeStr.split(' ');
                                                const [hours, minutes] = time.split(':');
                                                let hours24 = parseInt(hours);
                                                if (period.toLowerCase() === 'pm' && hours24 !== 12) {
                                                    hours24 += 12;
                                                }
                                                return `${hours24.toString().padStart(2, '0')}:${minutes}`;
                                            }


                                            const inputTime = convertTo24Hour(e);
                                            const currentTime24 = convertTo24Hour(currentTime);
                                            const Today = new Date().getDate()
                                            const Month = new Date().getMonth()
                                            const Fullyear = new Date().getFullYear()

                                            const selectedday = new Date(selectedDay).getDate()
                                            const selctedmonth = new Date(selectedDay).getMonth()
                                            const selectedyear = new Date(selectedDay).getFullYear()

                                            if (parseInt(Today) === parseInt(selectedday) && parseInt(Month + 1) === parseInt(selctedmonth + 1) && parseInt(Fullyear) === parseInt(selectedyear)) {
                                                if (currentTime24 < inputTime) {
                                                    return e
                                                }
                                            }
                                            else {
                                                return e
                                            }
                                        }
                                    }).map((e, i) => {
                                        return <div className='slots mb-0' key={i} style={data?.Dateandtime?.Date === formattedDay && data?.Dateandtime?.time === e ? { backgroundColor: "#0d8aed" } : findBookedAppointments?.find((s) => {
                                            if (s === e) {
                                                return true
                                            }
                                        }) ? { backgroundColor: "#90EE90" } : {}}>
                                            <p className='mb-0' style={data?.Dateandtime?.Date === formattedDay && data?.Dateandtime?.time === e || findBookedAppointments?.find((s) => {
                                                if (s === e) {
                                                    return true
                                                }
                                            }) ? { color: "white" } : { color: "black" }} onClick={() => {
                                                if (findBookedAppointments?.find((s) => {
                                                    if (s === e) {
                                                        return true
                                                    }
                                                })) {
                                                    toast.info('Slot is already Booked', {
                                                        position: "top-center",
                                                        limit: 1,
                                                        transition: Zoom,
                                                        autoClose: 2000,
                                                        hideProgressBar: false,
                                                        closeOnClick: false,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                        theme: "dark"
                                                    })
                                                }
                                                else {
                                                    dispatch(AppointmentDate({ Date: formattedDay, time: e }))
                                                }
                                            }}>{e}</p>

                                            {findBookedAppointments?.find((s) => {
                                                if (s === e) {
                                                    return true
                                                }
                                            }) ? <p className='mb-0'>Booked</p> : <p className='mb-0'>Free</p>}
                                        </div>
                                    })
                                }
                            </div>

                        </div>}

                {/* </div> */}
            </div>
            <h4 className='mt-5'>Choose your mode of consultation</h4>
            <div className='consultation-modes mt-5'>
                {
                    consultationmodes.map((e, i) => {

                        return <div className='consultation-mode' style={data?.Payment?.Mode != e.Mode ? { backgroundColor: "whitesmoke" } : { backgroundColor: "white" }} onClick={() => {
                            dispatch(Updatepayment(e))
                        }}>

                            <div>
                                <p >{e.Mode}</p>
                                <div><p ><del>₹ {e.actualPrice}</del></p><p >₹ {e.Price}</p></div>
                                <p style={{ fontWeight: '400' }}>*Inclusive of all Tax</p>
                            </div>
                            <div className='animecontainer'>
                                {data?.Payment?.Mode === e.Mode ? <img src='/check.png'></img> : <></>}
                                <Lottie animationData={e.Mode === "Video" ? Video : Phone} className='modeanime'></Lottie>
                            </div>
                        </div>

                    })
                }

            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <iframe className="conset-form" src='https://aiwellbucket.s3.ap-south-1.amazonaws.com/consent.html'>

                    </iframe>

                </DialogContent>
                <div className='agreeandcontinue'>
                    <div style={{ display: "flex", alignContent: "center", gap: "1rem" }} className='mb-3'>
                        <input type="checkbox" onClick={(e) => {
                            setchecked(e.target.checked)
                        }} value={checked}></input>
                        <p className='mb-0'>Agree and Continue</p>
                    </div>
                    <div>
                        <p>Date and time of giving consent</p>
                        <p>{today.toString().split(' ').splice(0, 5).join(' ')}</p>
                    </div>
                </div>
                <DialogActions>
                    <Button onClick={handleform} >
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
        <div className='bookappointmentbtn'>
            <Button style={{ backgroundColor: '#0076BE', color: "white" }} id='bookappointmentbtn' onClick={() => {
                handleSubmit()
            }}>Book Appointment</Button>
        </div>
    </>
}

export default BookAppointment
 
