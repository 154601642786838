import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    cartitems: localStorage.getItem("Cartitems") ?
    JSON.parse(localStorage.getItem("Cartitems")) :
    [],
    quanity: 0,
    Total: 0,
    deliverycharges: 0,
}

const CartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToCart(state, action) {
            const findindex = state.cartitems.findIndex((item) =>
                item.name === action.payload.name
            )
            if (findindex >= 0) {
                state.cartitems[findindex].cartQuantity += 1
            }
            else {
                const cartitem = { ...action.payload, cartQuantity: 1 }
                state.cartitems.push(cartitem);
            }
            localStorage.setItem("Cartitems", JSON.stringify(state.cartitems))
        },
        removeitem(state, action) {
            const cartitem = state.cartitems.filter(
                (item) =>
                    item.name !== action.payload.name);
            state.cartitems = cartitem
            localStorage.setItem("Cartitems", JSON.stringify(state.cartitems))
        },
        DecreaseQuantity(state, action) {
            const findindex = state.cartitems.findIndex((item) =>
                item.name === action.payload.name
            )
            if (state.cartitems[findindex].cartQuantity > 1) {
                state.cartitems[findindex].cartQuantity -= 1
            }
            else if (action.payload.cartQuantity === 1) {
                const cartitem = state.cartitems.filter(
                    (item) =>
                        item.name !== action.payload.name);
                state.cartitems = cartitem

            }
            localStorage.setItem("Cartitems", JSON.stringify(state.cartitems))
        },
        CartTotal(state) {
            let { total } = state.cartitems.reduce((total, item) => {
                const { price, cartQuantity } = item;
                const itemTotal = price * cartQuantity;

                total.total += itemTotal

                return total

            }, {
                total: 0
            })
            state.Total = total
        },

        Shipping_Charges(state) {
            let Product = 0;
            for (let e of state.cartitems) {
                if (e.deliverycharges > 0) {
                    Product = e.deliverycharges;
                    break;
                }
            }
        state.deliverycharges = Product;
        },
        

        RemoveCart(state) {
            state.cartitems.splice(0);
            localStorage.setItem("Cartitems", JSON.stringify(state.cartitems))
        }
    }

})
export const { addToCart, removeitem, DecreaseQuantity, CartTotal, RemoveCart,Shipping_Charges} = CartSlice.actions
export default CartSlice.reducer