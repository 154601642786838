import React from 'react'
import { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getDatabase, ref, onValue, } from "firebase/database";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { setAuthToken } from '../Auth/setAuthToken';
import logo from './ayurailogofull.png'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { contextAPI } from '../Context';
import { Button } from '@mui/material';
function Prescription() {
    let [yogas, setyogas] = useState([])
    let [Prescription, setPrescription] = useState({})
    let { id } = useParams()
    let navigate = useNavigate()
    let [docname, setname] = useState('')
    let userdetails = localStorage.getItem('Userdetails')
    let refreshtoken = JSON.parse(userdetails)
    let [count, setCount] = useState(0)
    let Token = localStorage.getItem('jwt-token')
    let data = useContext(contextAPI)

    const getApppointments = async () => {
        try {
            setAuthToken(Token)

            let res = await axios.post('https://api.ayurai.in/api/data/getOneAppointments', {
                appointmentID: id
            })

            if (res.status === 200) {
                setPrescription(res.data.Prescription)
                setname(res.data.Data.doctor_name)
            }
        }
        catch (error) {
            // renew()

            if (error?.response?.data?.error.message === 'jwt expired' || error?.status === 500) {
                renew()
            }
        }
    }



    let renew = async () => {

        try {
            let res = await axios.post(`https://api.ayurai.in/auth/renew`, {
                email: refreshtoken.email,
                token: refreshtoken.refreshToken
            })

            if (res.status === 200) {
                let data = res.data
                localStorage.setItem('jwt-token', data.access_token)

                setCount(count + 1)

            }
        }
        catch (error) {
            console.log(error)
        }
    }

    let getData = (Data) => {
        try {
            const db = getDatabase();
            const Ref = ref(db, `/yoga`);
            onValue(Ref, async (snapshot) => {
                try {
                    const data = await snapshot.val()
                    setyogas(Object.values(data))
                }
                catch (error) {
                    console.log(error)
                }

            })

        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        getApppointments()
    }, [])



    return <div>
        <div className='Cart-header'>
            <IconButton onClick={() => {
                navigate(-1)
            }}>
                <ArrowBackIcon />
            </IconButton>
        </div>

        <div className='precription-container'>
            <div className='header'>
                <img src={logo} />
                <div className='doctor-details'>
                    <p>{docname}</p>
                    <p>B.A.M.S,M.D.(Ayu.)</p>
                    <p>Ayurvedic physician</p>
                </div>
            </div>
            <div className='patient-information'>
                <p id='Patient-Information'>Patient Information</p>
                {Prescription?.Prescription_link && <div id='dpt'><a href={Prescription?.Prescription_link} download><Button style={{ padding: "10px", borderRadius: "2rem" }} variant="contained" startIcon={<ArrowCircleDownIcon />}>Download Prescription</Button></a></div>}
                <div className='information'>
                    <div>
                        <p id='info-t'>Name</p>
                        <p>{Prescription?.Name}</p>
                    </div>
                    <div>
                        <p id='info-t'>Age</p>
                        <p>{Prescription?.Age}</p>
                    </div>
                    {Prescription?.email
                        && <div>
                            <p id='info-t'>Email</p>
                            <p>{Prescription?.email}</p>
                        </div>}
                    <div>
                        <p id='info-t'>Gender</p>
                        <p>{Prescription?.Gender}</p>
                    </div>
                </div>

                <div>
                    <p id='Patient-Information'>Medical Details</p>
                    <div className='information'>
                        <div>
                            <p id='info-t'>Complaints</p>
                            <p>{Prescription?.Medicaldetails?.Complaints}</p>
                        </div>
                        <div>
                            <p id='info-t'>Examination /Lab findings</p>
                            <p>{Prescription?.Medicaldetails?.lab}</p>
                        </div>
                        <div>
                            <p id='info-t'>Medical history</p>
                            <p>{Prescription?.Medicaldetails?.history}</p>
                        </div>
                        <div>
                            <p id='info-t'>Diagnosis</p>
                            <p>{Prescription?.Medicaldetails?.diagnosis}</p>
                        </div>
                    </div>
                </div>
                <div>
                    <p id='Patient-Information'>List of Prescribed Medications</p>
                    <table id='table1' >
                        <thead>
                            <tr>
                                <th>S.no</th>
                                <th>Medicine Name</th>
                                <th>Dose</th>
                                <th>Quantity</th>
                                <th>Duration</th>
                                <th>instruction</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Prescription?.Medicines &&
                                Prescription?.Medicines.map((e, i) => {

                                    return <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{e?.medicine?.value ?
                                             e?.medicine?.value : e?.medicine}</td>
                                        <td>{e.dose}</td>
                                        <td>{e.quantity}</td>
                                        <td>{e.duration}</td>
                                        <td>{e.instruction}</td>
                                    </tr>
                                })
                            }

                        </tbody>
                    </table>
                    <p id='Patient-Information'>Diet</p>
                    <div className='diet'>
                        <div>
                            <p id='diet-t'>Diets to be followed.</p>
                            <p id='diet-content'> <ul>
                                {Prescription?.Diets?.Diets_included &&
                                    Prescription?.Diets?.Diets_included.split(",").map((e) => {
                                        return <li>{e}</li>
                                    })}
                            </ul></p>
                        </div>
                        <div>
                            <p id='diet-tl'>Diets to be Avoided.</p>
                            <p id='diet-content'>
                                <ul>
                                    {Prescription?.Diets?.Diets_avoided &&
                                        Prescription?.Diets?.Diets_avoided.split(",").map((e) => {
                                            return <li>{e}</li>
                                        })}
                                </ul></p>
                        </div>
                    </div>
                    {Prescription.Practice && <><p id='Patient-Information'>Practices</p>
                        <p id='Patient-Information' style={{fontSize:"18px",margin:'1rem'}}>Yoga</p>
                        <div className='yoga'>

                            {
                                yogas.filter((e) => {
                                    if (Prescription?.Practice.find((s) => s.name === e.name)) {
                                        return e
                                    }
                                }).map((e, i) => {
                                    return <div className='yoga-card' key={i} onClick={() => {
                                        navigate(`/yoga/${e.name}`)
                                    }}>
                                        <div>
                                            <p>{e.name}</p>
                                            <p id='pose-type'>{e.pose}</p>
                                        </div>

                                    </div>

                                })
                            }

                        </div></>}
                    <p id='Patient-Information'>Lifestyle Changes</p>
                    <div className='supplement'>
                        <ul>
                            {Prescription?.lifestylechanges &&
                                Prescription?.lifestylechanges.split(",").map((e) => {
                                    return <li>{e}</li>
                                })}
                        </ul>
                    </div>
                    <p id='Patient-Information'>Supplements</p>
                    <div className='supplements'>
                        {
                            Prescription?.Supplements &&
                            data?.products.filter((s) => {
                                if (Prescription?.Supplements.map((p) => p.Product_id).includes(s.product_id)) {
                                    return s

                                }
                            }
                            ).map((e, i) => {
                                return <div className='Product-card2' key={i} onClick={() => {
                                    navigate(`/Product/${e.product_id}`)
                                }}>
                                    <img src={e.imgurl} className='mb-0'></img>
                                    <div className='product-card-details'>
                                        <p className='mb-0'>{e.name}</p>
                                        <p className='mb-0'>{e.desc}</p>
                                        <div className='product-card-detail'>
                                            <p className='mb-0'>Recommended for</p>
                                            <div id="product-recommended-list" onClick={(e) => { e.stopPropagation(); }}>
                                                <p className='mb-0'>{e?.subtype?.split(",")[0]}</p>
                                                {e?.subtype?.split(",")[1] && <p className='mb-0'>{e?.subtype?.split(",")[1]}</p>}
                                                {!e?.disease?.split(",")[0].includes("na") && <p className='mb-0'>{!e?.disease?.split(",").includes("na") && e?.disease?.split(",")[0]}</p>}
                                                {e?.disease?.split(",")[1] && <p className='mb-0'>{e?.disease?.split(",").includes("na") ? <></> : e?.disease?.split(",")[1]}</p>}
                                            </div>
                                            {/* <p>{e.subtype}</p> */}
                                            <p className='mb-0'>₹ {e.price}</p>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    {/* <div className='doctor-footer'>
                <img src="https://aiwellassets.s3.ap-south-1.amazonaws.com/Doc_sign/WhatsApp_Image_2023-08-31_at_15.41.56-removebg-preview.png" crossorigin="*" id="doc-sign" />
                <div>
                    <p>${data.Doctors.Data.name}</p>
                    <p>B.A.M.S,M.D.(Ayu.)</p>
                    <p>Ayurvedic physician</p>
                    <p>${day}/${month}/${Year}</p>
                </div>
            </div> */}
                    {/* <p id='disclaimer'>*Please note that this prescription was generated by our Ayurvedic doctor after a teleconsultation.*</p> */}
                </div>
            </div>
        </div>

    </div>
}

export default Prescription