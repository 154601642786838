import React from 'react'
import { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import HomeIcon from '@mui/icons-material/Home';
import Divider from '@mui/material/Divider';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import ListItemIcon from '@mui/material/ListItemIcon';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import SettingsIcon from '@mui/icons-material/Settings';
import { Height, SearchOutlined } from '@mui/icons-material'
import RestoreIcon from '@mui/icons-material/Restore';
import { useEffect, useContext } from "react";
import Skeleton from '@mui/material/Skeleton';
import { contextAPI } from '../Context';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
function Shop() {
  const [search, setsearch] = useState('')

  const Categories = [
    { name: "Tablets", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/asset/Tablet.png", type: "Tablets" },
    { name: "Powders", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/asset/Group+8330.png", type: "Churna (Powders)" },
    { name: "Oils", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/asset/Group+8335.png", type: "Oil" },
    { name: "Ghee", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/asset/ghee.png", type: "Ghee" },
    { name: "Decoctions", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/asset/Group+8332.png", type: "Kashayam (Decoctions)" },
    { name: "Fermented", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/asset/Group+8329.png", type: "Arishta" },
    { name: "Miscellaneous", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/asset/miscellecious+products.png", type: "Miscellaneous" },
    { name: "Syrups", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/asset/Group+8331.png", type: "Syrup" },
    { name: "Tea", img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/asset/Group+8334.png", type: "Tea" }
  ]

  let data = useContext(contextAPI)
  let navigate = useNavigate()

  return <>
    <div className='Navbar'>
      <div> <Button onClick={() => {
        navigate(-1)
      }}><ArrowBackIcon /></Button></div>
      <div><Button onClick={() => {
        navigate('/Orders')
      }}><RestoreIcon /></Button>
        <Button onClick={() => {
          navigate('/Cart')
        }}
        ><ShoppingCartIcon /></Button></div>
    </div>
    <div className='All-Products'>
      <div className='Search-bar'>
        <div className='search-box'>
          <SearchOutlined style={{ fontSize: '3rem' }} />

          <input type="text" placeholder="Search"
            onChange={(e) => { setsearch(e.target.value) }} />

        </div>
      </div>

      <div className='ListofCategories'>
        {Categories.map((e) => {
          return (
            <div onClick={() => {
              if (e?.type?.toLowerCase()?.includes(search?.toLowerCase())) {
                setsearch('all')
              }
              else {
                setsearch(e.type)
              }

            }}>
              <img src={e.img}></img>
              <p className={e?.type?.toLowerCase()?.includes(search?.toLowerCase()) && search != '' ? 'lineactive' : ""}>{e.name}</p>
            </div>
          )

        })}

      </div>

      {data.products.length === 0 ?
        <div className='Product-list'>
          <Skeleton className='Product-card'
            variant="rectangular"

            height={518}>

          </Skeleton>
          <Skeleton className='Product-card' variant="rectangular"

            height={518}>

          </Skeleton>
          <Skeleton className='Product-card'
            variant="rectangular"

            height={518}>

          </Skeleton>
          <Skeleton className='Product-card' variant="rectangular"

            height={518}>

          </Skeleton>
        </div> :
        <div className='Product-list'>{
          data.products.filter((s) => {
            if (search === '') {
              return s
            }
            if (search === 'all') {
              return s
            }
            else if (s?.name?.toLowerCase()?.includes(search?.toLowerCase()) || s?.ProductType?.toLowerCase()?.includes(search?.toLowerCase())) {
              return s
            }
          }).sort((a, b) => a.price - b.price).map((e, i) => {
            return (
              <div className='Product-card' key={i} onClick={() => {
                navigate(`/Product/${e.product_id}`)
              }}>
                {e.orgprice && e.orgprice != 0 && e.orgprice != e.price && e.orgprice > e.price ?
                  <div style={{ position: "absolute", display: "flex", alignSelf: "flex-end" }}>
                    <div className='discount-percentage top-0 '>
                      <p className='mb-0'>{(((e.orgprice - e.price) / e.orgprice) * 100).toFixed(0) + "%" + " " + "off"}</p>
                    </div>
                  </div> :
                  <></>}
                <img src={e.imgurl} className='mb-0'></img>

                <div className='product-card-details'>
                  <p className='mb-0'>{e.name}</p>
                  <p className='mb-0'>{e.desc}</p>

                  <div className='product-card-detail'>
                    <p className='mb-0'>Recommended for</p>
                    <div id="product-recommended-list" onClick={(e) => { e.stopPropagation(); }}>
                      {e?.subtype && <p className='mb-0'>{e?.subtype?.split(",")[0]}</p>}
                      {e?.subtype?.split(",")[1] && <p className='mb-0'>{e?.subtype?.split(",")[1]}</p>}
                      {!e?.disease?.split(",")[0].includes("na") && <p className='mb-0'>{!e?.disease?.split(",").includes("na") && e?.disease?.split(",")[0]}</p>}
                      {e?.disease?.split(",")[1] && <p className='mb-0'>{e?.disease?.split(",").includes("na") ? <></> : e?.disease?.split(",")[1]}</p>}
                    </div>
                    {/* <p>{e.subtype}</p> */}
                    <p style={{ display: "flex", gap: "1rem" }} className='mb-0'>
                      <p className='mb-0'>₹ {e?.price}</p>
                      {
                        e?.orgprice > 0 && e.orgprice != e.price && e.orgprice > e.price ? <p className='mb-0'><del>₹ {e?.orgprice}</del></p> : <></>}</p>
                  </div>
                </div>
              </div>
            )
          })

        }
        </div>
      }
    </div>
  </>
}

export default Shop