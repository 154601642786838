import React from 'react'
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import { setAuthToken } from './Compontents/Auth/setAuthToken';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
function Wellness() {
    const [pkglist, setpkglist] = useState(new Map())
    const navigate = useNavigate()
    let [count, setCount] = useState(0)
    let userdetails = localStorage.getItem('Userdetails')
    let refreshtoken = JSON.parse(userdetails)
    const AddPackages = (key, value) => {

        setpkglist((prev) => {
            const newpkg = new Map(prev);


            if (newpkg?.has(key)) {
                newpkg?.delete(key);

            }

            else {

                newpkg?.set(key, value);

            }
            return newpkg;


        });
    }

    const saveInterest = async () => {
        try {
            let Token = localStorage.getItem('jwt-token')
            setAuthToken(Token)
            let res = await axios.post('https://api.ayurai.in/api/data/updateUserInterest', {
                interest: Array.from(pkglist.values()).join(',')
            })
            if (res.status === 200) {
                navigate('/')
            }

        }
        catch (error) {
            if (error?.response?.data?.error.message === 'jwt expired' || error?.response?.data?.error.status === 500) {
                renew()
            }
        }
    }

    let renew = async () => {

        try {
            let res = await axios.post(`https://api.ayurai.in/auth/renew`, {
                email: refreshtoken.email,
                token: refreshtoken.refreshToken
            })
            if (res.status === 200) {

                let data = res.data
                console.log(data)
                localStorage.setItem('jwt-token', data.access_token)

                setCount(count + 1)

            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const Packages = [{

        Name: 'Physical',
        Desc: "Physical wellness includes adopting healthy habits like",
        points: ['Exercise', 'Good nutrition', 'Proper sleep', 'Sexual health', 'Stress control'],
        img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/images/MicrosoftTeams-image+(38).png"
    },
    {
        Name: "Gastro wellness",
        Desc: "Gastro wellness includes:",
        points: ['Maintaining healthy gut', 'Preventing abdominal discomforts like acidity, bloating', 'Strengthening Immunity'],
        img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/images/MicrosoftTeams-image+(36).png"

    },
    {

        Name: "Women's wellness",
        Desc: "Women's wellness focuses on ",
        points: ['Hormonal and gynecological issues', 'Bone health and mental health', 'Healthy weight control'],
        img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/images/MicrosoftTeams-image+(40).png"

    }, {
        Name: "Mental wellness",
        Desc: "Mental wellness includes:",
        points: ['Ability to handle stress', 'Overcome hardships and challenges', 'Build connections'],
        img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/images/MicrosoftTeams-image+(42).png"
    }, {
        Name: "Respiratory",
        Desc: "Respiratory wellness is essential for",
        points: ['Reducing the chances of diseases like asthma, cough, cold, etc', 'Promoting overall health'],
        img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/images/MicrosoftTeams-image+(39).png"

    }, {
        Name: "Metabolic",
        Desc: "Metabolic wellness includes:",
        points: ['Prevention of obesity, diabetes, and heart disease', 'Improving BMI, blood sugar control and blood pressure.'],
        img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/images/MicrosoftTeams-image+(37).png"
    }, {
        Name: "Hair & Skin",
        Desc: "Hair and skin wellness focuses on:",
        points: ['Maintaining healthier skin and hair', 'Preventing Dandruff and infections'],
        img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/images/MicrosoftTeams-image+(35).png"
    }, {
        Name: "Sexual",
        Desc: "Sexual wellness includes:",
        points: ['Improved overall health', 'Preventing Hormonal imbalances'],
        img: "https://aiwellassets.s3.ap-south-1.amazonaws.com/images/MicrosoftTeams-image+(41).png"
    }]

    return <>
        <div className='wellnesspackages'>
            <p id='crp'>Choose Your Relevant Wellness Interest</p>
            <div className='pkg-list'>
                {
                    Packages.map((e) => {
                        return <div className='Pkg-card' onClick={() => {
                            AddPackages(e.Name, e.Name)
                        }} style={pkglist?.has(e.Name) ? { filter: "blur(0.8px)" } : {}}>
                            <img src={e.img}></img>
                            <p>{e.Name}</p>
                            <div>
                                <div>
                                    <Tooltip id='tooltip' title={<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", flexDirection: "column", padding: "1rem" }}><p id='tooltiptext'>{e.Desc}</p>
                                        <ul>{e.points.map((s) => {
                                            return <li>{s}</li>
                                        })}</ul></div>} arrow enterTouchDelay={0} onClick={(e) => {
                                            e.stopPropagation();
                                        }}>
                                        <IconButton>
                                            <InfoIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                {pkglist?.has(e.Name) ?
                                    <div style={pkglist?.has(e.Name) ? { position: "absolute", display: "flex", justifyContent: "center", alignItems: "center" } : { display: "none" }}>
                                        <div style={{ width: "2rem", height: "2rem", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "blue" }}>
                                            <CheckIcon style={{ color: "white" }} />
                                        </div>
                                    </div> : <></>}
                            </div>

                        </div>
                    })
                }
            </div>
            <Button style={{ textTransform: "none", backgroundColor: "#0076BE", color: "white" }} className='Registerbtn' onClick={saveInterest}>
                Continue
            </Button>
        </div>

    </>
}

export default Wellness