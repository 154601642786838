import React, { useEffect, useState } from 'react';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { useNavigate, useLocation } from 'react-router-dom';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
function Policy() {
    const navigate = useNavigate();
    const location = useLocation();
    const [id, setId] = useState(0);

    const policies = [
        { name: "Privacy Policy", link: "https://www.ayurai.io/privacypolicyaiwell.html" },
        { name: "Return & Refund Policy", link: "https://www.ayurai.io/returnandrefundaiwell.html" },
        { name: "Shipping Policy", link: "https://www.ayurai.io/shippingpolicyaiwell.html" },
        { name: "Terms & Conditions", link: "https://www.ayurai.io/termsandconditionaiwell.html" }
    ];

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const policy = queryParams.get('Policy');
        const policyId = parseInt(policy, 10);

        setId(policyId - 1);

    }, [location, policies.length]);

    return <div>
        <div className='Cart-header'>
            <IconButton onClick={() => {
                navigate(-1)
            }}>
                <ArrowBackIcon />
            </IconButton>
           </div>
        <div className='iframe-container' style={{ position: 'relative', height: 'calc(100vh - 56px)' }}>
            <iframe
                src={policies[id]?.link}
                frameBorder="0"
                style={{
                    border: 'none',
                    height: '100%',
                    width: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0
                }}
                title={policies[id]?.name}
            />
        </div>
    </div>


}

export default Policy;
