import React from 'react'
import { IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import Bot from './Animations/newbot.json'
import Lottie from 'lottie-react';
import TextField from '@mui/material/TextField';
import Next from './Animations/profileokbtn.json'
import male_img from './Animations/male.png'
import female_img from './Animations/female.png'
import others_img from './Animations/others.png'
import { useState, useRef, useEffect } from 'react';
import { CircleSlider } from 'react-circle-slider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SlideRulerPage from './Slider'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/en-gb';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { Add_Profile } from './ProfileSlice';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from 'axios';
import { setAuthToken } from './Auth/setAuthToken';
import { motion } from "framer-motion";
function Profile() {
  let navigate = useNavigate()
  const [sliderValue, setSliderValue] = useState(5);
  const [gender, setgender] = useState(0);
  const [firstname, setfirstname] = useState('')
  const [lastname, setlastname] = useState('')
  const [height, setheight] = useState(0)
  const [mothertounge, setMothertonuge] = useState('')
  const [dob, setdob] = useState(null)
  const [selectedWeight, setSelectedWeight] = useState(70);
  const [heightpreference,setheightpereference]=useState('ft')
  const minWeight = 0;
  const maxWeight = 150;
  const step = 5;
  const [feet, setFeet] = useState(5); 
  const [inches, setInches] = useState(0); 
  
  const handleFeetChange = (value) => {
    setFeet(value);
  };

  const handleInchesChange = (value) => {
    setInches(value);
  };


  const dispatch = useDispatch()

  const handleweightChange = (value) => {
    setSelectedWeight(value);
  };
  const handleheightChange = (value) => {
    setheight(value)
  }
  let Token = localStorage.getItem('jwt-token')
  let userdetails = JSON.parse(localStorage.getItem('Userdetails'))

  // const slideRulerRef = useRef();


  // useEffect(() => {

  //   let ns = {};

  //   ns.s1 = new SlideRuler({
  //     el: slideRulerRef?.current,
  //     maxValue: 180,
  //     minValue: 0,
  //     currentValue: selectedWeight,
  //     handleValue: handleweightChange,
  //     precision: 1
  //   });

  //   return () => {
  //     console.log("destroy ...");
  //     console.log(ns);
  //     delete ns.s1;
  //     console.log("done!");
  //     console.log(ns);
  //   };
  // }, []);

  const SubmitProfile = async () => {
    try {
      setAuthToken(Token)
      let heightValue=heightpreference==='ft'?`${feet} ft ${inches} in`:`${height} cm`
      let Data = {
        firstname,
        lastname,
        dob: formattedDate,
        gender,
        mothertounge,
        weight: `${selectedWeight} Kg`,
        height:heightValue
      }

      let res = await axios.post('https://api.ayurai.in/api/data/updateProfile', Data)
      if (res.status === 200) {
        dispatch(Add_Profile({
          uid: userdetails?.id,
          firstname,
          lastname,
          dob: formattedDate,
          weight: `${selectedWeight} Kg`,
          mothertounge,
          height
        }))

        navigate('/wellness')
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  const handleSliderChange = () => {
    if (sliderValue < 5) {
      setSliderValue(sliderValue + 1)
    }
    else {
      if (firstname, lastname, dob, selectedWeight, height, mothertounge) {
        SubmitProfile()
      }
      else {
        setSliderValue(0)
      }
    }
  };
  const formattedDate = dayjs(dob).format('DD/MM/YYYY');

  const scaleLabels = Array.from({ length: (maxWeight - minWeight) / step + 1 }).map((_, index) => (
    <span key={index}>{minWeight + index * step}&nbsp;</span>
  ));
  const pointerPosition = `${((selectedWeight - minWeight) / (maxWeight - minWeight)) * 100}%`;
  return <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
    <div className='Profile_content'>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className='Profile-page'>
        <IconButton onClick={() => {
          navigate(-1)
        }}>
          <ArrowBackIcon />
        </IconButton>
        <Lottie animationData={Bot} style={{ width: "5rem" }}></Lottie>
      </div>

      {sliderValue === 0 ?
        <div className='profile_input mt-5 d-flex'>
          <p>What's your Name?</p>
          <TextField id="outlined-basic" label="FirstName" variant="outlined" onChange={(e) => {
            setfirstname(e.target.value)
          }} />
          <TextField id="outlined-basic" label="LastName" variant="outlined" className='profile-text-input'
            onChange={(e) => {
              setlastname(e.target.value)
            }} />
        </div> : sliderValue === 1 ?
          <div className='profile_input mt-5 d-flex'>
            <p>What's your Date of Birth?</p>
            <div className='d-flex ' style={{
              gap: '1rem'
            }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                <DemoContainer components={['DatePicker']}>
                  <DatePicker label="D.O.B"
                    inputFormat="DD-MM-YYYY"
                    onChange={(date) => {
                      setdob(date)
                    }} />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div> : sliderValue === 2 ?
            <div className='profile_input mt-5 d-flex'>
              <p>What's your Gender?</p>
              <div className='genders'>
                <div className='gender'
                  style={gender != "" && gender != "Male" ? { opacity: "0.2" } : gender === "Male" ? { backgroundColor: "whitesmoke" } : {}}
                  onClick={() => { setgender("Male") }}><p>Male</p>
                  <img src={male_img}></img>
                </div>
                <div className='gender' style={gender != "" && gender != "Female" ? { opacity: "0.2" } : gender === "Female" ? { backgroundColor: "whitesmoke" } : {}} onClick={() => { setgender("Female") }} >
                  <p>Female</p>
                  <img src={female_img}></img>
                </div>
                <div className='gender' style={gender != "" && gender != "Others" ? { opacity: "0.2" } : gender === "Others" ? { backgroundColor: "whitesmoke" } : {}}
                  onClick={() => { setgender("Others") }}>
                  <p>Others</p>
                  <img src={others_img}></img>
                </div>
              </div>
            </div> : sliderValue === 3 ?
              <div className='profile_input mt-5 d-flex'>
                <p>What's your Mother Tongue?</p>
                <TextField id="outlined-basic" label="Mother Tongue" variant="outlined" className='profile-text-input'
                  onChange={(e) => {
                    setMothertonuge(e.target.value)
                  }} />
              </div> :
              sliderValue === 4 ?
                <div className='profile_input mt-5 d-flex'>
                  <p>What's your Weight?</p>

                  
                  <SlideRulerPage func={handleweightChange} />
                  <p id='selectedweight'>{selectedWeight}&nbsp;<span>Kg</span></p>
                </div> : sliderValue === 5 ?
                  <div className='profile_input mt-5 d-flex'>
                    <p>What's your height?</p>
                  
                    <div className='height-preference'>
                          <div className={`htbox  ${heightpreference === 'cm' ? 'selected' : ""}`} onClick={() => {
                            setheightpereference('cm')
                          }}>
                            <p className='mb-0'>cm</p>
                          </div>
                          <div className={`htbox ${heightpreference === 'ft' ? 'selected' : ""}`} onClick={() => {
                            setheightpereference('ft')
                          }}>
                            <p className='mb-0'>ft</p>
                          </div>
                        </div>
                        {heightpreference === 'ft' ?
                          <div className="height-selector">
                            <p id='selectedweight' className='mb-0'>
                              {feet}&nbsp;<span>ft</span>&nbsp;{inches}&nbsp;<span>in</span>
                            </p>
                            <div className="height-spinner">
                              <label>Feet:</label>
                              <div className="spinner">
                                {[...Array(8).keys()].map((value) => (
                                  <div
                                    key={value}
                                    className={`spinner-item ${feet === value + 1 ? 'selected' : ''}`}
                                    onClick={() => handleFeetChange(value + 1)}
                                  >
                                    {value + 1}
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className="height-spinner">
                              <label>Inches:</label>
                              <div className="spinner">
                                {[...Array(12).keys()].map((value) => (
                                  <div
                                    key={value}
                                    className={`spinner-item ${inches === value ? 'selected' : ''}`}
                                    onClick={() => handleInchesChange(value)}
                                  >
                                    {value}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          :
                          <div ><SlideRulerPage func={handleheightChange} />
                            <p id='selectedweight'>{height}&nbsp;<span>cm</span></p></div>
                        }
                  </div> :
                  <></>
      }

      <div style={{ position: "fixed", bottom: "1rem" }} className="Profile-next-btn">
        <div style={{ position: "relative" }} className='next-container'>

          <div style={{ position: "absolute", }} onClick={handleSliderChange}>
            <Lottie animationData={Next} style={{ width: "7rem" }}>
            </Lottie>
          </div>

          <CircleSlider
            value={sliderValue}
            stepSize={1}
            size={250}
            max={5}
            gradientColorFrom="#21e6a7"
            gradientColorTo="#21e6a7"
            knobRadius={0}
            readOnly={true}
            knobDraggable={false}
            hideKnob={true}
          // circleWidth={1}
          />
        </div>
      </div>
    </div>
  </div>
}

export default Profile