import React from "react";
import { useEffect, useState, useContext } from "react";
import { contextAPI } from '../Context';
import { db } from '../firebase'
import { getDatabase, ref, onValue, } from "firebase/database";
function Diet() {
    let [suitable, setsuitable] = useState([])
    let [Notsuitale, setNotsuitable] = useState([])
    let contextdata = useContext(contextAPI)
    let getData = () => {
        try {
            const db = getDatabase();
            const Ref = ref(db, `/recommendations1`);
            onValue(Ref, async (snapshot) => {
                try {
                    const data = await snapshot.val()
                    if (contextdata.prakritiresult) {
                        let recommendation = Object.values(data)

                        let Not_recommendated = recommendation.filter((e) => {
                            let Not_Suit = e.vikriti_not_suit.split(',').filter((s) => {
                                if (contextdata.prakritiresult.toLowerCase() === s.toLowerCase() || contextdata.vikritiresult.toLowerCase() === s.toLowerCase() || contextdata.vikritiresult.split('').reverse().join(' ').toLowerCase() === s.toLowerCase() || contextdata.prakritiresult.split('').reverse().join('').toLowerCase() === s.toLowerCase()) {
                                    return s
                                }
                                if (contextdata.prakritiresult === 'VPK') {
                                    if (s === 'G') {
                                        return s
                                    }
                                }
                            })

                            return Not_Suit.toString()

                        })

                        setNotsuitable(Not_recommendated)

                        let recommendated = recommendation.filter((e) => {
                            let Suit = e.suitable_prakriti.split(',').filter((g) => {
                                if (contextdata.prakritiresult.toLowerCase() === g.toLowerCase() || contextdata.vikritiresult.toLowerCase() === g.toLowerCase() || contextdata.vikritiresult.split('').reverse().join('').toLowerCase() === g.toLowerCase() || contextdata.prakritiresult.split('').reverse().join('').toLowerCase() === g.toLowerCase()) {
                                    return g
                                }

                                if (contextdata.prakritiresult === 'VPK' || contextdata.prakritiresult.length >= 3) {
                                    if (g === 'G') {
                                        return g
                                    }
                                }

                            })
                            return Suit.toString()
                        })

                        const filterByReference = (arr1, arr2) => {
                            let res = [];
                            res = arr1.filter(el => {
                                return !arr2.some(element => {
                                    return element.english_name
                                        === el.english_name
                                        ;
                                });
                            });
                            return res;
                        }

                        setsuitable(filterByReference(recommendated, Not_recommendated))
                    }

                }
                catch (error) {
                    console.log(error)
                }

            })

        }
        catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        getData()
    }, [contextdata.vikritiresult])



    const notsuitableNames = Notsuitale.map(item => item.english_name)

    return <>
        <div className="diet-section">
            {suitable.filter((e) => {

                if (e.category_eng === 'Cereals & Millets') {
                    return e
                }

            }).length > 0 || Notsuitale.filter((e) => {
                if (e.category_eng === 'Cereals & Millets') {
                    return e
                }
            }).length > 0 ?
                <div className="millets">
                    <p>Cereals & Millets</p>
                    <div className="diet-content">
                        {suitable.filter((e) => {
                            if (e.category_eng === 'Cereals & Millets') {
                                return e
                            }
                        }).length > 0 ? <p className="Foodtobetaken">Food to be taken</p> : <></>}
                        {suitable.filter((e) => {
                            if (e.category_eng === 'Cereals & Millets') {
                                return e
                            }
                        }).length > 0 ?
                            <div className="recommendated_food">

                                {
                                    suitable.filter((e) => {
                                        if (e.category_eng === 'Cereals & Millets') {
                                            return e
                                        }
                                    }).filter((s) => {
                                        if (notsuitableNames.includes(s.english_name)) {
                                            console.log(s.english_name)
                                        }
                                        else {
                                            return s
                                        }
                                    })
                                        .map((e, i) => {
                                            return <div key={i} className="recommendated-card">
                                                <img src={e.image_link}></img>
                                                <p>{e.english_name}</p>
                                            </div>
                                        })
                                }
                            </div> : <></>}
                    </div>
                    <div className="diet-content">
                        {Notsuitale.filter((e) => {
                            if (e.category_eng === 'Cereals & Millets') {
                                return e
                            }
                        }).length > 0 ? <p className="FoodNottobetaken">Food to be avoided</p> : <></>}
                        {Notsuitale.filter((e) => {
                            if (e.category_eng === 'Cereals & Millets') {
                                return e
                            }
                        }).length > 0 ?
                            <div className="recommendated_food">

                                {
                                    Notsuitale.filter((e) => {
                                        if (e.category_eng === 'Cereals & Millets') {
                                            return e
                                        }
                                    }).map((e, i) => {
                                        return <div key={i} className="recommendated-card">
                                            <img src={e.image_link}></img>
                                            <p>{e.english_name}</p>
                                        </div>
                                    })
                                }
                            </div> : <></>
                        }
                    </div>
                </div> : <></>}



            {Notsuitale.filter((e) => {
                if (e.category_eng === 'Fruits') {
                    return e
                }
            }).length > 0 || suitable.filter((e) => {
                if (e.category_eng === 'Fruits') {
                    return e
                }
            }).length > 0 ?
                <div className="millets">
                    <p>Fruits</p>
                    <div className="diet-content">
                        {
                            suitable.filter((e) => {
                                if (e.category_eng === 'Fruits') {
                                    return e
                                }
                            }).length > 0 ? <p className="Foodtobetaken">Food to be taken</p> : <></>}
                        {
                            suitable.filter((e) => {
                                if (e.category_eng === 'Fruits') {
                                    return e
                                }
                            }).length > 0 ?
                                <div className="recommendated_food">

                                    {
                                        suitable.filter((e) => {
                                            if (e.category_eng === 'Fruits') {
                                                return e
                                            }
                                        }).map((e, i) => {
                                            return <div key={i} className="recommendated-card">
                                                <img src={e.image_link}></img>
                                                <p>{e.english_name}</p>
                                            </div>
                                        })
                                    }
                                </div> : <></>}
                    </div>
                    <div className="diet-content">
                        {
                            Notsuitale.filter((e) => {
                                if (e.category_eng === 'Fruits') {
                                    return e
                                }
                            }).length > 0 ? <p className="FoodNottobetaken">Food to be avoided</p> : <></>}
                        {
                            Notsuitale.filter((e) => {
                                if (e.category_eng === 'Fruits') {
                                    return e
                                }
                            }).length > 0 ?
                                <div className="recommendated_food">

                                    {
                                        Notsuitale.filter((e) => {
                                            if (e.category_eng === 'Fruits') {
                                                return e
                                            }
                                        }).map((e, i) => {
                                            return <div key={i} className="recommendated-card">
                                                <img src={e.image_link}></img>
                                                <p>{e.english_name}</p>
                                            </div>
                                        })
                                    }
                                </div> : <></>}
                    </div>
                </div> : <></>}
            {Notsuitale.filter((e) => {
                if (e.category_eng === 'General') {
                    return e
                }
            }).length > 0 || suitable.filter((e) => {
                if (e.category_eng === 'General') {
                    return e
                }
            }).length > 0 ?
                <div className="millets">
                    <p>General</p>
                    <div className="diet-content">
                        {
                            suitable.filter((e) => {
                                if (e.category_eng === 'General') {
                                    return e
                                }
                            }).length > 0 ? <p className="Foodtobetaken">Food to be taken</p> : <></>}
                        {
                            suitable.filter((e) => {
                                if (e.category_eng === 'General') {
                                    return e
                                }
                            }).length > 0 ?
                                <div className="recommendated_food">

                                    {
                                        suitable.filter((e) => {
                                            if (e.category_eng === 'General') {
                                                return e
                                            }
                                        }).map((e, i) => {
                                            return <div key={i} className="recommendated-card">
                                                <img src={e.image_link}></img>
                                                <p>{e.english_name}</p>
                                            </div>
                                        })
                                    }
                                </div> : <>
                                </>}
                    </div>
                    <div className="diet-content">
                        {
                            Notsuitale.filter((e) => {
                                if (e.category_eng === 'General') {
                                    return e
                                }
                            }).length > 0 ? <p className="FoodNottobetaken">Food to be avoided</p> : <></>}
                        {
                            Notsuitale.filter((e) => {
                                if (e.category_eng === 'General') {
                                    return e
                                }
                            }).length > 0 ?
                                <div className="recommendated_food">

                                    {
                                        Notsuitale.filter((e) => {
                                            if (e.category_eng === 'General') {
                                                return e
                                            }
                                        }).map((e, i) => {
                                            return <div key={i} className="recommendated-card">
                                                <img src={e.image_link}></img>
                                                <p>{e.english_name}</p>
                                            </div>
                                        })
                                    }
                                </div> : <></>}
                    </div>
                </div>
                : <></>}

            {suitable.filter((e) => {
                if (e.category_eng === 'Vegetables') {
                    return e
                }
            }).length > 0 || Notsuitale.filter((e) => {
                if (e.category_eng === 'Vegetables') {
                    return e
                }
            }).length > 0 ?
                <div className="millets">
                    <p>Vegetables</p>
                    <div className="diet-content">
                        {suitable.filter((e) => {
                            if (e.category_eng === 'Vegetables') {
                                return e
                            }
                        }).length > 0 ? <p className="Foodtobetaken">Food to be taken</p> : <></>}
                        {suitable.filter((e) => {
                            if (e.category_eng === 'Vegetables') {
                                return e
                            }
                        }).length > 0 ?
                            <div className="recommendated_food">

                                {
                                    suitable.filter((e) => {
                                        if (e.category_eng === 'Vegetables') {
                                            return e
                                        }
                                    }).map((e, i) => {
                                        return <div key={i} className="recommendated-card">
                                            <img src={e.image_link}></img>
                                            <p>{e.english_name}</p>
                                        </div>
                                    })
                                }
                            </div> : <></>}
                    </div>
                    <div className="diet-content">
                        {Notsuitale.filter((e) => {
                            if (e.category_eng === 'Vegetables') {
                                return e
                            }
                        }).length > 0 ? <p className="FoodNottobetaken">Food to be avoided</p> : <></>}
                        {Notsuitale.filter((e) => {
                            if (e.category_eng === 'Vegetables') {
                                return e
                            }
                        }).length > 0 ?
                            <div className="recommendated_food">

                                {
                                    Notsuitale.filter((e) => {
                                        if (e.category_eng === 'Vegetables') {
                                            return e
                                        }
                                    }).map((e, i) => {
                                        return <div key={i} className="recommendated-card">
                                            <img src={e.image_link}></img>
                                            <p>{e.english_name}</p>
                                        </div>
                                    })
                                }
                            </div> : <></>
                        }
                    </div>
                </div> : <></>}

            {suitable.filter((e) => {
                if (e.category_eng === 'Leafy Vegetables') {
                    return e
                }
            }).length > 0 || Notsuitale.filter((e) => {
                if (e.category_eng === 'Leafy Vegetables') {
                    return e
                }
            }).length > 0 ?
                <div className="millets">
                    <p>Leafy Vegetables</p>
                    <div className="diet-content">
                        {suitable.filter((e) => {
                            if (e.category_eng === 'Leafy Vegetables') {
                                return e
                            }
                        }).length > 0 ? <p className="Foodtobetaken">Food to be taken</p> : <></>}
                        {suitable.filter((e) => {
                            if (e.category_eng === 'Leafy Vegetables') {
                                return e
                            }
                        }).length > 0 ?
                            <div className="recommendated_food">

                                {
                                    suitable.filter((e) => {
                                        if (e.category_eng === 'Leafy Vegetables') {
                                            return e
                                        }
                                    }).map((e, i) => {
                                        return <div key={i} className="recommendated-card">
                                            <img src={e.image_link}></img>
                                            <p>{e.english_name}</p>
                                        </div>
                                    })
                                }
                            </div> : <></>}
                    </div>
                    <div className="diet-content">
                        {Notsuitale.filter((e) => {
                            if (e.category_eng === 'Leafy Vegetables') {
                                return e
                            }
                        }).length > 0 ? <p className="FoodNottobetaken">Food to be avoided</p> : <></>}
                        {Notsuitale.filter((e) => {
                            if (e.category_eng === 'Leafy Vegetables') {
                                return e
                            }
                        }).length > 0 ?
                            <div className="recommendated_food">

                                {
                                    Notsuitale.filter((e) => {
                                        if (e.category_eng === 'Leafy Vegetables') {
                                            return e
                                        }
                                    }).map((e, i) => {
                                        return <div key={i} className="recommendated-card">
                                            <img src={e.image_link}></img>
                                            <p>{e.english_name}</p>
                                        </div>
                                    })
                                }
                            </div> : <></>
                        }
                    </div>
                </div> : <></>}

            {suitable.filter((e) => {
                if (e.category_eng === 'Pulses') {
                    return e
                }
            }).length > 0 || Notsuitale.filter((e) => {
                if (e.category_eng === 'Pulses') {
                    return e
                }
            }).length > 0 ?
                <div className="millets">
                    <p>Pulses</p>
                    <div className="diet-content">
                        {suitable.filter((e) => {
                            if (e.category_eng === 'Pulses') {
                                return e
                            }
                        }).length > 0 ? <p className="Foodtobetaken">Food to be taken</p> : <></>}
                        {suitable.filter((e) => {
                            if (e.category_eng === 'Pulses') {
                                return e
                            }
                        }).length > 0 ?
                            <div className="recommendated_food">

                                {
                                    suitable.filter((e) => {
                                        if (e.category_eng === 'Pulses') {
                                            return e
                                        }
                                    }).map((e, i) => {
                                        return <div key={i} className="recommendated-card">
                                            <img src={e.image_link}></img>
                                            <p>{e.english_name}</p>
                                        </div>
                                    })
                                }
                            </div> : <></>}
                    </div>
                    <div className="diet-content">
                        {Notsuitale.filter((e) => {
                            if (e.category_eng === 'Pulses') {
                                return e
                            }
                        }).length > 0 ? <p className="FoodNottobetaken">Food to be avoided</p> : <></>}
                        {Notsuitale.filter((e) => {
                            if (e.category_eng === 'Pulses') {
                                return e
                            }
                        }).length > 0 ?
                            <div className="recommendated_food">

                                {
                                    Notsuitale.filter((e) => {
                                        if (e.category_eng === 'Pulses') {
                                            return e
                                        }
                                    }).map((e, i) => {
                                        return <div key={i} className="recommendated-card">
                                            <img src={e.image_link}></img>
                                            <p>{e.english_name}</p>
                                        </div>
                                    })
                                }
                            </div> : <></>
                        }
                    </div>
                </div> : <></>}
            {suitable.filter((e) => {
                if (e.category_eng === 'Sugar Products') {
                    return e
                }
            }).length > 0 || Notsuitale.filter((e) => {
                if (e.category_eng === 'Sugar Products') {
                    return e
                }
            }).length > 0 ?
                <div className="millets">
                    <p>Sugar Products</p>
                    <div className="diet-content">
                        {suitable.filter((e) => {
                            if (e.category_eng === 'Sugar Products') {
                                return e
                            }
                        }).length > 0 ? <p className="Foodtobetaken">Food to be taken</p> : <></>}
                        {suitable.filter((e) => {
                            if (e.category_eng === 'Sugar Products') {
                                return e
                            }
                        }).length > 0 ?
                            <div className="recommendated_food">

                                {
                                    suitable.filter((e) => {
                                        if (e.category_eng === 'Sugar Products') {
                                            return e
                                        }
                                    }).map((e, i) => {
                                        return <div key={i} className="recommendated-card">
                                            <img src={e.image_link}></img>
                                            <p>{e.english_name}</p>
                                        </div>
                                    })
                                }
                            </div> : <></>}
                    </div>
                    <div className="diet-content">
                        {Notsuitale.filter((e) => {
                            if (e.category_eng === 'Sugar Products') {
                                return e
                            }
                        }).length > 0 ? <p className="FoodNottobetaken">Food to be avoided</p> : <></>}
                        {Notsuitale.filter((e) => {
                            if (e.category_eng === 'Sugar Products') {
                                return e
                            }
                        }).length > 0 ?
                            <div className="recommendated_food">

                                {
                                    Notsuitale.filter((e) => {
                                        if (e.category_eng === 'Sugar Products') {
                                            return e
                                        }
                                    }).map((e, i) => {
                                        return <div key={i} className="recommendated-card">
                                            <img src={e.image_link}></img>
                                            <p>{e.english_name}</p>
                                        </div>
                                    })
                                }
                            </div> : <></>
                        }
                    </div>
                </div> : <></>}

            {Notsuitale.filter((e) => {
                if (e.category_eng === 'Milk products') {
                    return e
                }
            }).length > 0 || suitable.filter((e) => {
                if (e.category_eng === 'Milk products') {
                    return e
                }
            }).length > 0 ?
                <div className="millets">
                    <p>Milk products</p>
                    <div className="diet-content">
                        {suitable.filter((e) => {
                            if (e.category_eng === 'Milk products') {
                                return e
                            }
                        }).length > 0 ? <p className="Foodtobetaken">Food to be taken</p> : <></>}
                        {suitable.filter((e) => {
                            if (e.category_eng === 'Milk products') {
                                return e
                            }
                        }).length > 0 ?
                            <div className="recommendated_food">

                                {
                                    suitable.filter((e) => {
                                        if (e.category_eng === 'Milk products') {
                                            return e
                                        }
                                    }).map((e, i) => {
                                        return <div key={i} className="recommendated-card">
                                            <img src={e.image_link}></img>
                                            <p>{e.english_name}</p>
                                        </div>
                                    })
                                }
                            </div> : <></>}
                    </div>
                    <div className="diet-content">
                        {Notsuitale.filter((e) => {
                            if (e.category_eng === 'Milk products') {
                                return e
                            }
                        }).length > 0 ? <p className="FoodNottobetaken">Food to be avoided</p> : <></>}
                        {Notsuitale.filter((e) => {
                            if (e.category_eng === 'Milk products') {
                                return e
                            }
                        }).length > 0 ?
                            <div className="recommendated_food">

                                {
                                    Notsuitale.filter((e) => {
                                        if (e.category_eng === 'Milk products') {
                                            return e
                                        }
                                    }).map((e, i) => {
                                        return <div key={i} className="recommendated-card">
                                            <img src={e.image_link}></img>
                                            <p>{e.english_name}</p>
                                        </div>
                                    })
                                }
                            </div> : <></>}
                    </div>
                </div> : <></>}
            {suitable.filter((e) => {
                if (e.category_eng === 'Oil and Fats') {
                    return e
                }
            }).length > 0 || Notsuitale.filter((e) => {
                if (e.category_eng === 'Oil and Fats') {
                    return e
                }
            }).length > 0 ?
                <div className="millets">
                    <p>Oil and Fats</p>
                    <div className="diet-content">
                        {suitable.filter((e) => {
                            if (e.category_eng === 'Oil and Fats') {
                                return e
                            }
                        }).length > 0 ? <p className="Foodtobetaken mb-0">Food to be taken</p> : <></>}
                        {
                            suitable.filter((e) => {
                                if (e.category_eng === 'Oil and Fats') {
                                    return e
                                }
                            }).length > 0 ?
                                <div className="recommendated_food">

                                    {
                                        suitable.filter((e) => {
                                            if (e.category_eng === 'Oil and Fats') {
                                                return e
                                            }
                                        }).map((e, i) => {
                                            return <div key={i} className="recommendated-card">
                                                <img src={e.image_link}></img>
                                                <p>{e.english_name}</p>
                                            </div>
                                        })
                                    }
                                </div> : <></>}
                    </div>
                    <div className="diet-content">
                        {Notsuitale.filter((e) => {
                            if (e.category_eng === 'Oil and Fats') {
                                return e
                            }
                        }).length > 0 ? <p className="FoodNottobetaken  mb-0">Food to be avoided</p> : <></>}
                        {Notsuitale.filter((e) => {
                            if (e.category_eng === 'Oil and Fats') {
                                return e
                            }
                        }).length > 0 ?
                            <div className="recommendated_food">

                                {
                                    Notsuitale.filter((e) => {
                                        if (e.category_eng === 'Oil and Fats') {
                                            return e
                                        }
                                    }).map((e, i) => {
                                        return <div key={i} className="recommendated-card">
                                            <img src={e.image_link}></img>
                                            <p>{e.english_name}</p>
                                        </div>
                                    })
                                }
                            </div> : <></>}
                    </div>
                </div> : <></>}

            {
                Notsuitale.filter((e) => {
                    if (e.category_eng === 'Spices') {
                        return e
                    }
                }).length > 0 || suitable.filter((e) => {
                    if (e.category_eng === 'Spices') {
                        return e
                    }
                }).length > 0 ?
                    <div className="millets">
                        <p>Spices</p>
                        <div className="diet-content">
                            {
                                suitable.filter((e) => {
                                    if (e.category_eng === 'Spices') {
                                        return e
                                    }
                                }).length > 0 ? <p className="Foodtobetaken  mb-0">Food to be taken</p> : <></>}
                            {
                                suitable.filter((e) => {
                                    if (e.category_eng === 'Spices') {
                                        return e
                                    }
                                }).length > 0 ?
                                    <div className="recommendated_food">

                                        {
                                            suitable.filter((e) => {
                                                if (e.category_eng === 'Spices') {
                                                    return e
                                                }
                                            }).map((e, i) => {
                                                return <div key={i} className="recommendated-card">
                                                    <img src={e.image_link}></img>
                                                    <p>{e.english_name}</p>
                                                </div>
                                            })
                                        }
                                    </div> : <></>}
                        </div>
                        <div className="diet-content">
                            {
                                Notsuitale.filter((e) => {
                                    if (e.category_eng === 'Spices') {
                                        return e
                                    }
                                }).length > 0 ? <p className="FoodNottobetaken">Food to be avoided</p> : <></>}
                            {
                                Notsuitale.filter((e) => {
                                    if (e.category_eng === 'Spices') {
                                        return e
                                    }
                                }).length > 0 ?
                                    <div className="recommendated_food">

                                        {
                                            Notsuitale.filter((e) => {
                                                if (e.category_eng === 'Spices') {
                                                    return e
                                                }
                                            }).map((e, i) => {
                                                return <div key={i} className="recommendated-card">
                                                    <img src={e.image_link}></img>
                                                    <p>{e.english_name}</p>
                                                </div>
                                            })
                                        }
                                    </div> : <></>}
                        </div>
                    </div> : <></>}

            {
                Notsuitale.filter((e) => {
                    if (e.category_eng === 'Alcoholic Beverages') {
                        return e
                    }
                }).length > 0 || suitable.filter((e) => {
                    if (e.category_eng === 'Alcoholic Beverages') {
                        return e
                    }
                }).length > 0 ?
                    <div className="millets">
                        <p>Alcoholic Beverages</p>
                        <div className="diet-content">
                            {
                                suitable.filter((e) => {
                                    if (e.category_eng === 'Alcoholic Beverages') {
                                        return e
                                    }
                                }).length > 0 ? <p className="Foodtobetaken  mb-0">Food to be taken</p> : <></>}
                            {
                                suitable.filter((e) => {
                                    if (e.category_eng === 'Alcoholic Beverages') {
                                        return e
                                    }
                                }).length > 0 ?
                                    <div className="recommendated_food">

                                        {
                                            suitable.filter((e) => {
                                                if (e.category_eng === 'Alcoholic Beverages') {
                                                    return e
                                                }
                                            }).map((e, i) => {
                                                return <div key={i} className="recommendated-card">
                                                    <img src={e.image_link}></img>
                                                    <p>{e.english_name}</p>
                                                </div>
                                            })
                                        }
                                    </div> : <></>}
                        </div>
                        <div className="diet-content">
                            {
                                Notsuitale.filter((e) => {
                                    if (e.category_eng === 'Alcoholic Beverages') {
                                        return e
                                    }
                                }).length > 0 ? <p className="FoodNottobetaken">Food to be avoided</p> : <></>}
                            {
                                Notsuitale.filter((e) => {
                                    if (e.category_eng === 'Alcoholic Beverages') {
                                        return e
                                    }
                                }).length > 0 ?
                                    <div className="recommendated_food">

                                        {
                                            Notsuitale.filter((e) => {
                                                if (e.category_eng === 'Alcoholic Beverages') {
                                                    return e
                                                }
                                            }).map((e, i) => {
                                                return <div key={i} className="recommendated-card">
                                                    <img src={e.image_link}></img>
                                                    <p>{e.english_name}</p>
                                                </div>
                                            })
                                        }
                                    </div> : <></>}
                        </div>
                    </div> : <></>}
            {Notsuitale.filter((e) => {
                if (e.category_eng === 'Green leaf and vegetables') {
                    return e
                }
            }).length > 0 || suitable.filter((e) => {
                if (e.category_eng === 'Green leaf and vegetables') {
                    return e
                }
            }).length > 0 ?
                <div className="millets">
                    <p mb-0>Green leaf and vegetables</p>
                    <div className="diet-content">
                        {suitable.filter((e) => {
                            if (e.category_eng === 'Green leaf and vegetables') {
                                return e
                            }
                        }).length > 0 ? <p className="Foodtobetaken  mb-0">Food to be taken</p> : <></>}
                        {suitable.filter((e) => {
                            if (e.category_eng === 'Green leaf and vegetables') {
                                return e
                            }
                        }).length > 0 ?
                            <div className="recommendated_food">

                                {
                                    suitable.filter((e) => {
                                        if (e.category_eng === 'Green leaf and vegetables') {
                                            return e
                                        }
                                    }).map((e, i) => {
                                        return <div key={i} className="recommendated-card">
                                            <img src={e.image_link}></img>
                                            <p className="mb-0"> {e.english_name}</p>
                                        </div>
                                    })
                                }
                            </div> : <></>
                        }
                    </div>
                    <div className="diet-content">
                        {Notsuitale.filter((e) => {
                            if (e.category_eng === 'Green leaf and vegetables') {
                                return e
                            }
                        }).length > 0 ? <p className="FoodNottobetaken  mb-0">Food to be avoided</p> : <></>}
                        {
                            Notsuitale.filter((e) => {
                                if (e.category_eng === 'Green leaf and vegetables') {
                                    return e
                                }
                            }).length > 0 ?
                                <div className="recommendated_food">

                                    {
                                        Notsuitale.filter((e) => {
                                            if (e.category_eng === 'Green leaf and vegetables') {
                                                return e
                                            }
                                        }).map((e, i) => {
                                            return <div key={i} className="recommendated-card">
                                                <img src={e.image_link}></img>
                                                <p>{e.english_name}</p>
                                            </div>
                                        })
                                    }

                                </div> : <></>}
                    </div>

                </div> : <></>}
        </div>
    </>
}

export default Diet