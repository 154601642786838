import React from 'react'
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import { setAuthToken } from './setAuthToken';
import { Add_Profile, addAddress } from '../ProfileSlice';
import { useDispatch } from 'react-redux';
function Signin() {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const dispatch = useDispatch()
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate()

  const handleLogin = async () => {
    if (email) {
      if (password) {
        try {
          let res = await axios.post('https://api.ayurai.in/auth/login', {
            email,
            password
          })

          if (res.status == 200) {
            try {
              setAuthToken(res?.data?.accesToken)
              let profile = await axios.post('https://api.ayurai.in/api/data/getProfile')
              if (profile.status === 200) {
                localStorage.setItem('Userdetails', JSON.stringify({
                  accesToken: res?.data?.accesToken,
                  id: res.data?.id,
                  email: email,
                  Name: res?.data?.Name,
                  idToken: res?.data?.idToken,
                  refreshToken: res?.data?.refreshToken
                }))
                dispatch(Add_Profile(profile?.data?.Data))

                if (Array.isArray(profile.data.Address)) {
                  for (const e of profile.data.Address) {
                    const address = {
                      Name: e.name,
                      Address1: e.addressLine1,
                      Address2: e.addressLine2,
                      City: e.city,
                      State: e.state,
                      Country: e.country,
                      Pin: e.postalCode,
                      email: e.email,
                      Phone: e.phone
                    };
                    dispatch(addAddress(address))
                  }
                }


                localStorage.setItem('jwt-token', res?.data?.accesToken)
                navigate('/')
              }
            }
            catch (error) {
              console.log(error)
            }
          }
        }
        catch (error) {
          toast.error(error?.response?.data?.message, {
            position: "top-center",
            limit: 1,
            transition: Zoom,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark"
          })
        }
      } else {
        toast.error("Password Can't be empty", {
          position: "top-center",
          limit: 1,
          transition: Zoom,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        })
      }
    }

    else {
      toast.error("Email Can't be empty", {
        position: "top-center",
        limit: 1,
        transition: Zoom,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      })
    }
  }
  return (
    <div className='SignIN_page'>
      <div className='Signin'>
        <h4>Login</h4>
        <p>Please Enter your mail id and password</p>
        <TextField id="outlined-basic" label="Email" variant="outlined" onChange={(e) => {
          setEmail(e.target.value)
        }} />

        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />

        </FormControl>
        <p id='fwd' style={{
          cursor: "pointer"
        }} onClick={() => {
          navigate('/ForgotPassword')
        }}>Forgot Password ?</p>
        <Button id='lgbtn' onClick={handleLogin}>Login</Button>
        <p id='rgacc'>Don't have a account ? <span style={{ color: " #0d8aed", cursor: 'pointer' }} onClick={() => {
          navigate("/Signup")
        }}>Register!</span></p>
      </div>
   
    </div>)
}

export default Signin