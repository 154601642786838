import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { IconButton } from '@mui/material'
import { setAuthToken } from '../Auth/setAuthToken'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer,
    MDBIcon,
    MDBRow,
} from "mdb-react-ui-kit";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button } from '@mui/material';
import axios from 'axios'
import { useParams } from 'react-router-dom'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                'green',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                'green',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 2,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));
const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient(90deg, rgba(130,211,184,1) 0%, rgba(0,118,190,1) 100%);',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }), ...(ownerState.completed && {

        backgroundImage:
            'linear-gradient(90deg, rgba(130,211,184,1) 0%, rgba(0,118,190,1) 100%);',
    }),
}));



ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <img src="https://aiwellassets.s3.ap-south-1.amazonaws.com/asset/Order+Placed.png" style={{ width: "2.5rem" }}></img>,
        2: <img src='https://aiwellassets.s3.ap-south-1.amazonaws.com/asset/Order+Processed.png' style={{ width: "2.5rem" }}></img>,
        3: <img src='https://aiwellassets.s3.ap-south-1.amazonaws.com/asset/Order+Shipped.png' style={{ width: "2.5rem" }}></img>,
        4: <img src='https://aiwellassets.s3.ap-south-1.amazonaws.com/asset/Order+Enrouted.png' style={{ width: "2.5rem" }}></img>,
        5: <img src='https://aiwellassets.s3.ap-south-1.amazonaws.com/asset/Order+Delivered.png' style={{ width: "2.5rem" }}></img>
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

function ColorlibStepIcon2(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <img src="https://aiwellassets.s3.ap-south-1.amazonaws.com/asset/Order+Cancelled.png" style={{ width: "2.5rem" }}></img>,
        2: <img src='https://aiwellassets.s3.ap-south-1.amazonaws.com/asset/Order+Processed.png' style={{ width: "2.5rem" }}></img>,
        3: <img src='https://aiwellassets.s3.ap-south-1.amazonaws.com/asset/Order+Shipped.png' style={{ width: "2.5rem" }}></img>,
        4: <img src='https://aiwellassets.s3.ap-south-1.amazonaws.com/asset/Order+Enrouted.png' style={{ width: "2.5rem" }}></img>,
        5: <img src='https://aiwellassets.s3.ap-south-1.amazonaws.com/asset/Order+Delivered.png' style={{ width: "2.5rem" }}></img>
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}
function Order() {
    let { id } = useParams()
    let [orders, setOrders] = useState([])
    let userdetails = localStorage.getItem('Userdetails')
    let refreshtoken = JSON.parse(userdetails)
    let [count, setCount] = useState(0)
    let navigate = useNavigate()
    let [reason, setreason] = useState('')
    const shadowHostRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    let getOrder = async () => {
        try {
            let Token = localStorage.getItem('jwt-token')
            setAuthToken(Token)
            let res = await axios.post('https://api.ayurai.in/api/data/getOneOrders', {
                orderid: id
            })
            if (res.status === 200) {
                setOrders(res.data)
            }
        }
        catch (error) {
            if (error?.response?.data?.error.message === 'jwt expired' || error?.response?.data?.error.status === 500) {
                renew()
            }
        }
    }

    let renew = async () => {

        try {
            let res = await axios.post(`https://api.ayurai.in/auth/renew`, {
                email: refreshtoken.email,
                token: refreshtoken.refreshToken
            })
            if (res.status === 200) {

                let data = res.data

                localStorage.setItem('jwt-token', data.access_token)

                setCount(count + 1)

            }
        }
        catch (error) {
            console.log(error)
        }
    }
    let cancellation_reasons = [
        "Change of Mind",
        "Product Unavailability",
        "Shipping Delays",
        "Wrong Address",
        "Payment Issues",
        "Duplicate Orders",

    ]


    useEffect(() => {
        getOrder()
    }, [count])

    const cancelOrder = async () => {
        try {
            let Token = localStorage.getItem('jwt-token')
            setAuthToken(Token)
            let res = await axios.post('https://api.ayurai.in/api/data/updateOneOrder',{
                key:"orderstatus",
                value:"cancelled",
                orderid:id
            })
            
            if(res.status===200){
                navigate(-1)
            }
        }
        catch (err) {
            console.log(err)
        }
    }
    const steps = orders?.Data?.orderstatus && [orders?.Data?.orderstatus === 'cancelled' ? 'Order Cancelled' : "Order Placed", 'Order Processed', 'Order Shipped', 'On the way', 'Order Delivered'];

    return <>
        <div className='Cart-header'>
            <IconButton onClick={() => {
                navigate(-1)
            }}>
                <ArrowBackIcon />
            </IconButton>
        </div>
        <div className='Order-details-page'>
            <p className='mb-0' style={{ fontSize: "1.5rem" }}>Order Details</p>
            <div className='order-details-section'>
                <div ref={shadowHostRef}>

                    <div className="d-flex justify-content-between align-items-center mb-5">
                        <div>
                        </div>
                        <div className="text-end">
                            <p className="mb-0">
                                Placed on <span>{orders?.Data?.dateandtime}</span>
                            </p>
                            <p className="mb-0">
                                Order ID{" "}
                                <span className="font-weight-bold">
                                    {id}
                                </span>
                            </p>
                        </div>
                    </div>
                    {orders?.Data?.orderstatus &&
                        <Stack sx={{ width: '100%' }} spacing={4}>

                            <Stepper alternativeLabel activeStep={orders?.Data?.orderstatus === 'placed' ? 0 : orders?.Data?.orderstatus === 'confirmed' ? 1 : orders.Data.orderstatus === 'dispatched' ? 2 : orders?.Data?.orderstatus === 'shipped' ? 3 : orders?.Data?.orderstatus === 'delivered' ? 4 : orders?.Data?.orderstatus === 'cancelled'} connector={<ColorlibConnector />}>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel StepIconComponent={orders?.Data?.orderstatus === 'cancelled' ? ColorlibStepIcon2 : ColorlibStepIcon} ><p className='Step'>{label}</p></StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Stack>
                    }

                    {/* <div style={{ margin: 'auto' }}>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div>
            <div
              
            >
                <div style={{ padding: '1rem',border:"1px solid green" }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' ,border:"1px solid green" }}>
                        <div></div>
                        <div style={{ textAlign: 'end' }}>
                            <p style={{ margin: '0' }}>
                                Placed on <span>{orders?.Data?.dateandtime}</span>
                            </p>
                            <p style={{ margin: '0' }}>
                                Order ID{' '}
                                <span style={{ fontWeight: 'bold' }}>
                                    {id}
                                </span>
                            </p>
                        </div>
                    </div>
                    <ul
                        id="progressbar-2"
                        
                        style={{ display: 'flex', justifyContent: 'center', margin: '0', marginTop: '0', marginBottom: '5px', padding: '0', listStyle: 'none',border:"1px solid green" ,width:"auto" }}
                    >
                        <li style={{textAlign: 'center' }} className={orders?.Data?.orderstatus === 'confirmed' && (orders?.Data?.orderstatus === 'dispatched' || orders?.Data?.orderstatus === 'shipped' || orders?.Data?.orderstatus === 'delivered') ? 'step0 active text-center' : 'step0 text-muted text-end'} id="step1"></li>

                        {orders?.Data?.orderstatus === 'confirmed' && (orders?.Data?.orderstatus === 'dispatched' || orders?.Data?.orderstatus === 'shipped' || orders?.Data?.orderstatus === 'delivered') ? <li style={{ width: '25%', textAlign: 'center' }} className="step0 active text-center" id="step2"></li> :
                            <li style={{ textAlign: 'end' }} className="step0 text-muted text-end" id="step4"></li>}
                        {orders?.Data?.orderstatus === 'shipped' || orders?.Data?.orderstatus === 'delivered' ? <li style={{ width: '25%', textAlign: 'center' }} className="step0 active text-center" id="step3"></li> :
                            <li style={{ textAlign: 'end' }} className="step0 text-muted text-end" id="step4"></li>}

                        {orders?.Data?.orderstatus === 'delivered' ? <li style={{ width: '25%', textAlign: 'center' }} className="step0 active text-center" id="step3"></li> :
                            <li style={{  textAlign: 'end' }} className="step0 text-muted text-end" id="step4"></li>}
                    </ul>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <MDBIcon fas icon="clipboard-list" style={{ marginRight: '1rem', marginBottom: '0.75rem', fontSize: '2x' }} />
                            <div className='order-processing'>
                                <p style={{ fontWeight: 'bold', marginBottom: '0.25rem' }}>Order</p>
                                <p style={{ fontWeight: 'bold', margin: '0' }}>Processed</p>
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <MDBIcon fas icon="box-open" style={{ marginRight: '1rem', marginBottom: '0.75rem', fontSize: '2x' }} />
                            <div className='order-processing'>
                                <p style={{ fontWeight: 'bold', marginBottom: '0.25rem' }}>Order</p>
                                <p style={{ fontWeight: 'bold', margin: '0' }}>Shipped</p>
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <MDBIcon fas icon="shipping-fast" style={{ marginRight: '1rem', marginBottom: '0.75rem' }} />
                            <div className='order-processing'>
                                <p style={{ fontWeight: 'bold', marginBottom: '0.25rem' }}>Order</p>
                                <p style={{ fontWeight: 'bold', margin: '0' }}>En Route</p>
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <MDBIcon fas icon="home" style={{ marginRight: '1rem', marginBottom: '0.75rem', fontSize: '2x' }} />
                            <div className='order-processing'>
                                <p style={{ fontWeight: 'bold', marginBottom: '0.25rem' }}>Order</p>
                                <p style={{ fontWeight: 'bold', margin: '0' }}>Delivered</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> */}


                </div>

            </div>
            <div className='ordered-items'>
                <div>
                    <div>
                    <p>Items ({orders?.Data?.cartitems && Object.keys(orders?.Data?.cartitems)?.length})</p>
                    <p className='mb-0'>Purchase Total &nbsp;&nbsp;&nbsp;<span style={{ color: 'green', fontFamily: "cursive" }}>₹{(Number(orders.Data?.total) + Number(orders?.Data?.deliverycharges)) - Number(orders?.Data?.discount)}</span></p>
                    </div>
                    
                    <TableContainer sx={{ maxHeight: 440 }} className='Table mt-3' >
                        <Table stickyHeader aria-label="sticky table" >
                            <TableHead>
                                <TableRow>

                                    <TableCell className='Table-header'
                                        style={{ minWidth: "100px" }}

                                    >
                                        Product
                                    </TableCell>
                                    <TableCell className='Table-header'
                                        align={"center"}
                                        style={{ minWidth: "100px" }}

                                    >
                                        Quantity
                                    </TableCell>
                                    <TableCell className='Table-header'
                                        align={"center"}
                                        style={{ minWidth: "100px" }}

                                    >
                                        Price
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orders?.Data?.cartitems
                                    && Object.entries(orders?.Data?.cartitems
                                    ).map(([type, make]) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={type}>


                                                <TableCell >
                                                    <div className='Product-details'>
                                                        <img src={make.image} ></img>
                                                        <div>
                                                            <p>{make.name}</p>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                    {make.qty}
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                    ₹ {make.price}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className='Shipping-address'>
                    <p>Shipping details</p>
                    <div className='Address-card'>
                        <div className='Address-contact-details'>
                            <p id='addressname'>{orders?.Data?.customers && orders?.Data?.customers[0]?.billing?.name}</p>

                        </div>
                        <p id='address'>{orders?.Data?.address}</p>

                        <div className='Address-contact-details'>
                            <p className='Address-contact-details'>
                                <PhoneIcon style={{ fontSize: "medium" }} />
                                {orders?.Data?.phone}
                            </p><p className='Address-contact-details'>
                                <MailIcon style={{ fontSize: "medium" }} />{orders?.Data?.email}</p></div>
                    </div>

                    {orders?.Data?.orderstatus === 'placed' ? <Button variant="contained" color='error' className='mt-3 w-100 p-2' onClick={handleClickOpen}>Cancel Order</Button> : <></>}

                </div>
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        Reason for Cancelling this Order?
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers>
                        <div className='Order-cancellation'>
                            <div>
                                {
                                    cancellation_reasons.map((e, i) => {
                                        return <p key={i} style={e === reason ? { backgroundColor: "#D4F1F4" } : { backgroundColor: "whitesmoke" }} onClick={() => {
                                            setreason(e)
                                        }}>
                                            {e}
                                        </p>
                                    })
                                }
                            </div>
                            <p>(Please select any of the reason that mentioned above.)</p>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={cancelOrder}>
                            Continue
                        </Button>
                    </DialogActions>
                </BootstrapDialog>
            </div>
        </div>
    </>
}

export default Order