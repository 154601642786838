import React from 'react'
import { getDatabase, ref, onValue, } from "firebase/database";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { db } from '../firebase'
import { Link } from '@mui/material';
import { useEffect, useState } from 'react'
function Articles() {
  let [articles, setarticles] = useState([])
  let getData = () => {
    try {
      const db = getDatabase();
      const Ref = ref(db, `/articles`);
      onValue(Ref, async (snapshot) => {
        try {
          const data = await snapshot.val()
          setarticles(Object.values(data))
        }

        catch (error) {
          console.log(error)
        }

      })

    }
    catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getData()
  }, [])

  return <div className='article-page'>
    {articles.map((e, i) => {
      return <div key={i}>
        <Link href={e?.link} target='_Blank' style={{ color: "black", textTransform: "none" }}>
          <div className='article-card'>
            <img src={e?.image}></img>
            <p className='mb-0'>{e?.header}</p>
          </div>
        </Link>
      </div>
    })}
  </div>
}

export default Articles