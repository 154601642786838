import React from 'react'
import Resetcode from '../Animations/changepass.json'
import Lottie from 'lottie-react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, IconButton } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';  
import { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { contextAPI } from '../Context';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { toast,Zoom} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function ResetPassword() {
    const [showPassword, setShowPassword] = useState(false);
    const [cpassword,setcpassword]=useState("")
    const [password,setPassword]=useState("")
    const {Email}=useParams()
    const {otp} = useContext(contextAPI)
    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
    let navigate= useNavigate()

    const ResetPassword=async()=>{
      if(!otp){
        navigate(-1)
      }
      else{
        if(cpassword===password){
          try{
            let res = await axios.post('https://api.ayurai.in/auth/confirmPassword',{
               name:Email,
               verifyCode:otp,
               newpassword:password,
            })
            if(res.status===200){
              navigate('/Signin')
              toast.success("Password Reset Successfull", {
                position: "top-center",
                limit: 1,
                transition: Zoom,
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark"
              })
            }
    
          }
          catch(err){
            toast.error(err?.response?.data?.error?.message, {
              position: "top-center",
              limit: 1,
              transition: Zoom,
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark"
            })
          }
        }
        else{
          toast.error("NewPassword and ConfirmPassword Should be Same", {
            position: "top-center",
            limit: 1,
            transition: Zoom,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark"
          })
        }
    
      }
    }

    return <>
        <div className='Cart-header'>
            <IconButton onClick={() => {
                navigate(-1)
            }}>
                <ArrowBackIcon />
            </IconButton>
        </div>
        <div className='fp-page'>
            <Lottie animationData={Resetcode} loop={true} id="fp-animation"></Lottie>
            <div className='fg-content'>
                <h2>Reset Passsword?</h2>
                <p>Ensure a unique, secure password with diverse characters</p>
                <FormControl variant="outlined">
        <InputLabel htmlFor="fullWidth">New Password</InputLabel>
        <OutlinedInput
          id="fullWidth"
          
          type={showPassword ? 'text' : 'password'}
          onChange={(e)=>{
             setPassword(e.target.value)
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
        />
        
      </FormControl>
      <FormControl variant="outlined">
        <InputLabel htmlFor="fullWidth">Confirm Password</InputLabel>
        <OutlinedInput
          id="fullWidth"
          
          type={showPassword ? 'text' : 'password'}
          onChange={(e)=>{
             setcpassword(e.target.value)
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
        />
        
      </FormControl>
                <Button id='lgbtn' onClick={()=>{
                  ResetPassword()
                }} >Submit</Button>
            </div>
        </div>
    </>
}

export default ResetPassword