import React from 'react'
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import Badge from '@mui/material/Badge';
import RestoreIcon from '@mui/icons-material/Restore';
import { Button } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Outlet } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));
function samePageLinkNavigation(event) {
    if (
        event.defaultPrevented ||
        event.button !== 0 ||
        event.metaKey ||
        event.ctrlKey ||
        event.altKey ||
        event.shiftKey
    ) {
        return false;
    }
    return true;
}
function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                if (samePageLinkNavigation(event)) {
                    event.preventDefault();
                }
            }}
            {...props}
        />
    );
}

function Package() {

    let navigate = useNavigate()
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        if (event.type !== 'click' || (event.type === 'click' && samePageLinkNavigation(event))) {
            setValue(newValue);
        }
    };

    useEffect(() => {
        navigate('Diet')
    }, [])

    return <>
        <div className='Navbar'>

            <div> <Button onClick={() => {
                navigate('/')

            }}><ArrowBackIcon /></Button></div>
            <div style={{}}><IconButton
                onClick={() => {
                    navigate('/orders')
                }}><RestoreIcon /></IconButton>

                <IconButton
                    onClick={() => {
                        navigate('/Cart')
                    }}>

                    <ShoppingCartIcon />
                </IconButton>

            </div>

        </div>
        <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
        <div className='recommendations-system'>
            {/* <p className='mb-0'>Recommendations</p> */}
            <div className='listtabs'>
                <Tabs value={value} onChange={handleChange} aria-label="nav tabs example" centered
                 
                 >

                    <LinkTab label="Dietary" id='linktab' onClick={() => {
                        navigate('Diet')
                    }}

                    >
                    </LinkTab>
                    <LinkTab label="Nutraceutical" to='UpcomingAppointments' id='linktab'
                        onClick={() => {
                            navigate('Nutraceutical')
                        }} />
                    <LinkTab label="Articles" to='UpcomingAppointments' id='linktab'
                        onClick={() => {
                            navigate('Articles')
                        }} />

                    <LinkTab label="Yoga" id='linktab'
                        onClick={() => {
                            navigate('Yoga')
                        }} />
                </Tabs>
            </div>
            <>
                <Outlet></Outlet>
            </>
        </div>
        </div>
    </>
}

export default Package