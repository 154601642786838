import React from 'react'
import { IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import HelpIcon from '@mui/icons-material/Help';
import { Help } from '@mui/icons-material';
import SyncLockIcon from '@mui/icons-material/SyncLock';
import PolicyIcon from '@mui/icons-material/Policy';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
function SettingsPage() {
    const navigate = useNavigate()
    return <>
        <div className='P-page'>
            <IconButton onClick={() => {
                navigate(-1)
            }}>
                <ArrowBackIcon />
            </IconButton>

        </div>
        <div className='Reports-Sections'>
            <p>Settings</p>
            <div className='settings-list'>

                <div onClick={()=>{
                    window.open('https://www.ayurai.io/privacypolicyaiwell.html')
                }}>
                    <div>
                        <IconButton style={{ backgroundColor: "lightblue" }}><PrivacyTipIcon /></IconButton>
                        <p className='mb-0'> Privacy Policy</p>
                    </div>
                    <NavigateNextIcon />
                </div>

                <div onClick={()=>{
                    window.open('https://www.ayurai.io/returnpolicy.html')
                }}>
                    <div>
                        <IconButton style={{ backgroundColor: "lightblue" }}><SyncLockIcon /></IconButton>
                        <p className='mb-0'>Return & Refund Policy</p>
                    </div>
                    <NavigateNextIcon />
                </div>

                <div onClick={()=>{
                    window.open('https://www.ayurai.io/shippingpolicyaiwell.html')
                }}>
                    <div>
                        <IconButton style={{ backgroundColor: "lightblue" }}><LocalShippingIcon /></IconButton>
                        <p className='mb-0'>Shipping Policy</p>
                    </div>
                    <NavigateNextIcon />
                </div>


                <div onClick={()=>{
                    window.open('https://www.ayurai.io/termsandconditionaiwell.html')
                }}>
                    <div>
                        <IconButton style={{ backgroundColor: "lightblue" }}n><PolicyIcon /></IconButton>
                        <p className='mb-0'>Terms and Conditions</p></div>
                    <NavigateNextIcon />
                </div>

                <div onClick={()=>{
                navigate('/Help')
                }}>

                    <div >
                        <IconButton style={{ backgroundColor: "lightblue" }}><Help></Help></IconButton>
                        <p className='mb-0'>Help</p>
                    </div>
                    <NavigateNextIcon />
                </div>
            </div>
        </div>
    </>
}

export default SettingsPage