import React from 'react'
import { useParams } from 'react-router-dom'
import { IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useEffect, useState } from 'react';

import axios from 'axios';
function ViewReports() {
    let { id } = useParams()
    const navigate = useNavigate()
    const [reports, setReports] = useState([])
    const [loading,setloading]=useState(true)
    const getReports = async () => {
        try {
            let res = await axios.post('https://api.ayurai.in/api/data/GetOneReport', {
                id: id
            })
            if (res.status === 200) {
                setReports(res.data?.DATA?.Reports)
            }
        }
        catch (error) {
            console.log(error)
        }
    }
    var regex = /\.pdf$/i;
    useEffect(() => {
        getReports()
    }
        , [])

    return <>
        <div className='P-page'>
            <IconButton onClick={() => {
                navigate(-1)
            }}>
                <ArrowBackIcon />
            </IconButton>

        </div>
        <div className='Reports-Sections'>
            <div className='viewlistofReports'>
                {
                    reports?.map((e) => {
                        return <div className='Viewreportcard'>

                            { 

                            regex.test(e?.Location)? (
                                <div style={{
                                 margin:"auto"
                                }}>
                                <iframe src={`https://docs.google.com/gview?url=${e?.Location}&embedded=true`} width="900" height="500" onLoad={()=>{
                                    setloading(false)
                                }} ></iframe>

                                {loading && <div>Loading...</div>}
                              
                               </div>
                            ) : (
                            <img src={e?.Location} id="viewreportcardimg" alt="Image" />
                            )}

                        </div>

                    })
                }
            </div>
        </div>
    </>
}

export default ViewReports