import React from 'react'
import Lottie from 'lottie-react'
import bg from '../Animations/drt893i1nw (1).json'
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { contextAPI } from '../Context';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, IconButton } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import StarIcon from '@mui/icons-material/Star';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import EventIcon from '@mui/icons-material/Event';
import CallIcon from '@mui/icons-material/Call';
import VideocamIcon from '@mui/icons-material/Videocam';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { setAuthToken } from '../Auth/setAuthToken';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useState, useEffect } from 'react';
import appointmentmissed from '../Animations/appointmentmissed.json'
import axios from 'axios';
import MessageIcon from '@mui/icons-material/Message';
function ViewAppointments() {
  let [appointments, setappointments] = useState({})
  let { id } = useParams()
  let navigate = useNavigate()
  let userdetails = localStorage.getItem('Userdetails')
  let refreshtoken = JSON.parse(userdetails)
  let [count, setCount] = useState(0)
  let Token = localStorage.getItem('jwt-token')

  const cancelAppointment = async () => {
    try {
      setAuthToken(Token)
      let res = await axios.post('https://api.ayurai.in/api/data/updateOneAppointment', {
        appointmentID: id,
        key: "appointment_status",
        value: "cancelled"

      })
      if (res.status === 200) {
        navigate(-1)
      }
    }
    catch (error) {
      console.log(error.response.data)
    }
  }
  const getApppointments = async () => {
    try {
      setAuthToken(Token)

      let res = await axios.post('https://api.ayurai.in/api/data/getOneAppointments', {
        appointmentID: id
      })

      if (res.status === 200) {
        setappointments(res.data)
      }
    }
    catch (error) {
      // renew()

      if (error?.response?.data?.error.message === 'jwt expired' || error?.status === 500) {
        renew()
      }
    }
  }

  let renew = async () => {

    try {
      let res = await axios.post(`http://localhost:8000/auth/renew`, {
        email: refreshtoken.email,
        token: refreshtoken.refreshToken
      })

      if (res.status === 200) {
        let data = res.data
        localStorage.setItem('jwt-token', data.access_token)

        setCount(count + 1)

      }
    }
    catch (error) {
      // console.log(error,'ll')
    }
  }

  useEffect(() => {
    getApppointments()
  }, [])
  
 
  return <>
    <div className='Navbar' style={{ backgroundColor: '#0076BE', border: "none" }}>
      <div>
        <IconButton onClick={() => {

          navigate('/Appointment_page')
        }} style={{ backgroundColor: "white" }}>
          <ArrowBackIcon />

        </IconButton>
      </div>
      {/* <div><IconButton style={{ backgroundColor: 'white', marginRight: "1rem" }}><FavoriteIcon /></IconButton></div> */}
    </div>
    <div style={{ backgroundColor: '#0076BE', display: "flex", alignItems: "center",justifyContent:"center", flexDirection: "column" }}>

      <Lottie animationData={bg} loop={true} style={{ backgroundColor: '#0076BE', width: "24rem"}} />

      <div className='Doc-img-container'>
        <div className='Doc-img'>

          <img style={{ width: "12rem", height: "12rem", borderRadius: "50%", objectFit: "cover", objectPosition: 'top' }} src={appointments?.Data?.doctor_photo}>

          </img>

        </div>
      </div>
      <div className='Doc-img-container'>
        <div className='Doc-moredetails'>
          <div style={{ backgroundColor: 'white' }} className='Doc-details' >
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className='Doc-basic-detail'>
              <div>
                <p className='mb-3'>{appointments?.Data?.doctor_name}</p>
                <p className='mb-3'>{appointments?.Data?.doctor_speciality}</p>
              </div>
              <div>
                {/* <p className='mb-0'>₹{appointments?.Data?.pay}/hr</p> */}
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: "space-between" }}>
              <div style={{ display: "flex", gap: "1rem" }} className='mt-3'>
                <EventIcon style={{ color: "lightgreen" }}></EventIcon>
                <p>{appointments?.Data?.appointment_date}</p>
              </div>
              <div style={{ display: "flex", gap: "1rem" }} className='mt-3'>
                <AccessTimeIcon style={{ color: "lightgreen" }}></AccessTimeIcon>
                <p>{appointments?.Data?.appointment_time}</p>
              </div>
              <div className='Doc-portfolio'>
                <div>
                  {/* <p>{appointments.Data.doctor_name}</p> */}
                </div>

              </div>
            </div>
          </div>

        </div>
       <div className='othctn'>
        {appointments?.Data?.appointment_status === 'upcoming'  ? <>  
        <div className='joincall' onClick={() => {
          appointments?.Data?.consultation_type ===  'video' ? navigate(`/VideoCall/${appointments?.Data?.appointmentID}`) : navigate(`/VoiceCall/${appointments?.Data?.appointmentID}`)
        }}>
          <div style={{ padding: "5px", backgroundColor: "white", borderRadius: "5px" }}>{appointments?.Data?.consultation_type === 'video' || 'Video' ? <VideocamIcon style={{ color: "blue" }}></VideocamIcon> : <CallIcon style={{ color: "blue" }}></CallIcon>}</div>
          <div>Join the Call</div>
        </div>
        <div className='joincall ' onClick={()=>{
          navigate(`/Chat/${id}/${appointments.Data.doctor_uid}`)
        }}>
        <div  style={{padding:"5px", backgroundColor: "white", borderRadius: "5px" }}><MessageIcon style={{color:"#F6BE00"}}/></div>
          <div style={{fontWeight:'600'}}>Chat</div>
          <ArrowForwardIosIcon />
        </div>
        
          <div className='btm-cancel-btn' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Button variant="contained" color="error" className='appointcancel-btn' onClick={cancelAppointment}>Cancel</Button>
          </div>
        </> : appointments?.Data?.appointment_status === 'completed' ?
          <></> : appointments?.Data?.appointment_status === 'cancelled' || appointments?.Data?.appointment_status === 'cancelled_time' ?
          <div className='cancelled'>
            <div className='cancelled-item'>
            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
              <Lottie animationData={appointmentmissed} loop={true} className='AppointmentBookedanime'  ></Lottie>
              <p id='noappointment' style={{textAlign:"center"}}>Oops! You missed your consultation with the doctor. Do you want to reschedule?</p>
            </div>
            </div>
            </div> : <></>

        }
         {
        appointments?.Prescription && appointments?.Data?.appointment_status==='completed'?
        <div className='joincall' style={{justifyContent:"space-between",backgroundColor:"lightyellow",border:"1px solid yellow"}} onClick={()=>{
          navigate(`/Prescription/${id}`)
        }}>
          
          <div style={{ padding: "5px", backgroundColor: "white", borderRadius: "5px" }}><ContentPasteSearchIcon style={{color:"#F6BE00"}}/></div>
          <div style={{fontWeight:'600'}}>Prescription</div>
          <ArrowForwardIosIcon />
        </div>
        :<></>}
          {
        appointments?.Data?.appointment_status==="completed"? <div className='joincall mt-5' style={{justifyContent:"space-between",backgroundColor:"lightblue",border:"1px solid blue",top:"18rem"}} onClick={()=>{
          navigate(`/Chat/${id}/${appointments.Data.doctor_uid}`)
        }}>
          <div style={{ padding: "5px", backgroundColor: "white", borderRadius: "5px",}}><MessageIcon style={{color:"#F6BE00"}}/></div>
          <div style={{fontWeight:'600',wordBreak:"unset"}}>Chat history</div>
          <ArrowForwardIosIcon />
        </div>
        :<></>}
        </div>
         
      </div>
    </div>
  </>
}

export default ViewAppointments