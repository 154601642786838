import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Context from './Compontents/Context';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import CartSlice, { CartTotal } from './Compontents/Shop/CartSlice';
import ProfileSlice from './Compontents/ProfileSlice';
import AppointmentSlice from './Compontents/Appointments/AppointmentSlice';

const store = configureStore({
  reducer: {
    cart: CartSlice,
    Profile: ProfileSlice,
    Appointment:AppointmentSlice
  }
})

store.dispatch(CartTotal())

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

  <Context>
    <Provider store={store}>
      <App />
    </Provider>
  </Context>

);


