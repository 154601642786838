import './App.css';
import Prakriti from './Compontents/Prakriti';
import Vikiriti from './Compontents/Vikiriti';
import Final from './Compontents/Home';
import Yoga_steps from './Compontents/Recommendations/Yoga_steps';
import Shop from './Compontents/Shop/Shop';
import { useEffect } from 'react';
import Product_Page from './Compontents/Shop/Product_Page';
import Checkout from './Compontents/Shop/Checkout';
import Address from './Compontents/Shop/Address';
import Discount from './Compontents/Shop/Discount';
import Signin from './Compontents/Auth/Signin';
import Signup from './Compontents/Auth/Signup';
import Cart from './Compontents/Shop/Cart';
import TeleConsultation_page from './Compontents/Appointments/TeleConsultation_page';
import Doctor_Page from './Compontents/Appointments/Doctor_Page';
import Forgot from './Compontents/Auth/Forgot';
import Orders from './Compontents/Shop/Orders';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import VerifyCode from './Compontents/Auth/VerifyCode';
import Paymentsuccess from './Compontents/Shop/Paymentsuccess';
import ResetPassword from './Compontents/Auth/ResetPassword';
import BookAppointment from './Compontents/Appointments/BookAppointment';
import ConfirmAppointment from './Compontents/Appointments/ConfirmAppointment';
import VideoCall from './Compontents/Appointments/VideoCall';
import AppointmentBooked from './Compontents/Appointments/AppointmentBooked';
import Appointmentpage from './Compontents/Appointments/Appointmentpage';
import UpcomingAppointments from './Compontents/Appointments/UpcomingAppointments';
import CompletedApppointments from './Compontents/Appointments/CompletedApppointments';
import CancelledAppointments from './Compontents/Appointments/CancelledAppointments';
import VoiceCall from './Compontents/Appointments/VoiceCall';
import ViewAppointments from './Compontents/Appointments/viewAppointments';
import Prescription from './Compontents/Appointments/Prescription';
import Order from './Compontents/Shop/Order';
import Chat from './Compontents/Chat/Chat';
import Lab_test from './Compontents/Shop/Lab-test';
import Lab_test_page from './Compontents/Shop/Lab_test_page';
import Yoga from './Compontents/Recommendations/Yoga';
import Diet from './Compontents/Recommendations/Diet';
import Articles from './Compontents/Recommendations/Articles';
import Sample_report from './Compontents/Shop/Sample_report';
import Profile_page from './Compontents/Profile_page'
import Profile from './Compontents/Profile'
import Package from './Compontents/Package';
import Supplements from './Compontents/Recommendations/Supplements';
import Result from './Compontents/Result';
import FGM from './Compontents/FGM'
import Reports from './Compontents/Shop/Reports';
import ViewReports from './Compontents/Shop/ViewReports';
import SettingsPage from './Compontents/Settings/SettingsPage';
import OtpReset from './Compontents/Auth/OtpReset';
import Pranayama from './Compontents/Recommendations/Pranayama';
import Meditation from './Compontents/Recommendations/Meditation';
import Meditation_Page from './Compontents/Recommendations/Meditation_Page';
import Pranayama_Page from './Compontents/Recommendations/Pranayama_Page';
import HelpCenter from './Compontents/Settings/HelpCenter';
import Wellness from './wellness';
import Policy from './Compontents/Policy';

function App() {
  useEffect(() => {
    let params = new URL(document.location).searchParams;
    let emailTemplate = params.get("key");
    if (emailTemplate === "Newsletter") {
      MailTracker('WebAPP')
    }

  }, []);

  const MailTracker = async (value) => {
    const payload = { key: value };
    try {
      const response = await fetch('https://api.ayurai.in/api/data/NewsLetterMailTracker', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  return <>
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path='/Tele' element={<TeleConsultation_page />} />
        <Route path='/Doc/:id' element={<Doctor_Page />} />
        <Route path='/Prakriti' element={<Prakriti />}></Route>
        <Route path='/vikriti' element={<Vikiriti />}></Route>
        <Route path='/Policy' element={<Policy />} />

        <Route path='/' element={<Final />}>
          <Route path='/' element={<Diet />} />
          <Route path='Nutraceutical' element={<Supplements />} />
          <Route path='Articles' element={<Articles />} />
          <Route path='Yoga' element={<Yoga />} />
          <Route path='meditation' element={<Meditation></Meditation>} />
          <Route path='pranayama' element={<Pranayama />} />
        </Route>

        <Route path='/Shop' element={<Shop />} />
        <Route path='/Orders' element={<Orders />} />
        <Route path='/Checkout' element={<Checkout />} />
        <Route path='/Yoga/:step' element={<Yoga_steps />}></Route>
        <Route path='/Meditation/:step' element={<Meditation_Page />}></Route>
        <Route path='/Pranayama/:step' element={<Pranayama_Page />}></Route>
        <Route path='/Product/:id' element={<Product_Page />}></Route>
        <Route path='/Cart' element={<Cart />}></Route>
        <Route path='/Signin' element={<Signin />}></Route>
        <Route path='/Signup' element={<Signup />}></Route>
        <Route path='/ForgotPassword' element={<Forgot />}></Route>
        <Route path="/Verify/:Email" element={<VerifyCode></VerifyCode>} />
        <Route path="/ResetPassword/:Email" element={<ResetPassword></ResetPassword>} />
        <Route path='/Address' element={<Address />}></Route>
        <Route path='/Discount-coupons' element={<Discount />}></Route>
        <Route path='/Order/:id' element={<Order />} />
        <Route path='/Payment_Success' element={<Paymentsuccess />} />
        <Route path='/AppointmentBooked/:appointmentID' element={<AppointmentBooked />} />
        <Route path='/BookAppointment' element={<BookAppointment />} />
        <Route path='/ConfirmAppointment' element={<ConfirmAppointment />} />
        <Route path='/VideoCall/:channel' element={<VideoCall></VideoCall>} />
        <Route path='/wellness' element={<Wellness />} />
        <Route path='/VoiceCall/:channel' element={<VoiceCall />} />

        <Route path='/Appointment_page' element={<Appointmentpage />}>

          <Route path='UpcomingAppointments' element={<UpcomingAppointments />} />
          <Route path='CompletedAppointments' element={<CompletedApppointments />} />
          <Route path='CancelledAppoinments' element={<CancelledAppointments />} />

        </Route>

        <Route path='/Appointment/:id' element={<ViewAppointments />} />
        <Route path='/lab_test' element={<Lab_test />} />
        <Route path="/Lab_test/:id" element={<Lab_test_page />} />
        <Route path='/Sample_report' element={<Sample_report />} />
        <Route path='/Prescription/:id' element={<Prescription />} />
        <Route path='/Chat/:id/:doc_id' element={<Chat />} />
        <Route path='/Profile' element={<Profile />} />
        <Route path='/Profile_page' element={<Profile_page />} />

        <Route path='/Package' element={<Package />}>
          <Route path='Diet' element={<Diet />} />
          <Route path='Nutraceutical' element={<Supplements />} />
          <Route path='Articles' element={<Articles />} />
          <Route path='pranayama' element={<Pranayama />} />
          <Route path='meditation' element={<Meditation></Meditation>} />
          <Route path='Yoga' element={<Yoga />} />

        </Route>

        <Route path='/FGM' element={<FGM />} />
        <Route path='/Result/:type' element={<Result />} />
        <Route path='/Reports' element={<Reports />} />
        <Route path='/Otp/:Email' element={<OtpReset></OtpReset>} />
        <Route path='/ViewReports/:id' element={<ViewReports />} />
        <Route path="/Settings" element={<SettingsPage />} />
        <Route path='/Help' element={<HelpCenter />} />

      </Routes>
      <div className='footer-policy'
        style={{ padding: '10px', backgroundColor: '#E9F6FF', textAlign: 'center', display: "flex", alignItems: "center", justifyContent: "space-between", position: "fixed", bottom: 0, width: `100%` }}>
        <p className='mb-0'>&copy; 2024 Ayurai Pvt Ltd. All Rights Reserved.</p>
        <p className='mb-0'>
          <a href="/Policy?Policy=1">Privacy Policy</a> |
          <a href="/Policy?Policy=4"> Terms and Conditions</a> |
          <a href="/Policy?Policy=3">Return and Refund Policy</a>
        </p>
      </div>
    </BrowserRouter>
  </>
}

export default App;
