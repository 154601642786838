import React, { useEffect,useState } from 'react'
import { getDatabase, ref, onValue, } from "firebase/database";
import { AllInbox } from '@mui/icons-material';
export default function FGM() {
  let [FGMdata,setData]=useState([])
  let getGut = async () => {
    try {

      const db = getDatabase();
      const refs = [ref(db, '/gut'), ref(db, '/immune'), ref(db, '/physical'),ref(db,'/mental')];
      const dataPromises = refs.map((ref) => {
        return new Promise((resolve, reject) => {
          onValue(ref, (snapshot) => {
            const value = snapshot.val();
            if (value) {
              resolve(value);
            } else {
              reject(new Error("Data not found in ref: " + ref.toString()));
            }
            
          });
          
        });
      
      })
        
      const allData = await Promise.all(dataPromises);
      console.log( allData.map((e)=>Object.values(e)).map((e)=>e).map((e)=>e?.answers))

    }
    catch (error) {
      console.log(error)
    }
  }

  let getPhysical = async () => {
    try {
      const db = getDatabase();
      const Ref = ref(db, '/physical');
      const data = Ref
      onValue(data, async(snapshot) => {
       try{
        const data =await snapshot.val()
        setData((prev)=>[...prev,data])
       }
       catch(error){
        console.log(error)
       }
        
      })

    }
    catch (error) {
      console.log(error)
    }
  }

  let getImmune = async () => {
    try {
      const db = getDatabase();
      const Ref = ref(db, '/immune');
      const data = Ref
      onValue(data, async(snapshot) => {
       try{
        const data =await snapshot.val()
        setData((prev)=>[...prev,data])
       }
       catch(error){
        console.log(error)
       }
        
      })

    }
    catch (error) {
      console.log(error)
    }
  }




  useEffect(() => {
    getGut()
    // getPhysical()
    // getImmune()
  }, [])

  return <>
      
  </>
}
