import React from "react";
import { useParams } from "react-router-dom";
import { getDatabase, ref, onValue, } from "firebase/database";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useEffect, useState } from 'react'
function Yoga_steps() {
    let { step } = useParams()
    let [yogas, setyogas] = useState([])
    let [steps,setsteps]=useState([])
    let navigate=useNavigate()
    let getData = () => {
        try {
            const db = getDatabase();
            const Ref = ref(db, `/yoga/${step}`);
            onValue(Ref, async (snapshot) => {
                try {
                    const data = await snapshot.val()
                    setsteps(Object.values(data.steps))
                    setyogas(data)
                }

                catch (error) {
                    console.log(error)
                }

            })

        }
        catch (error) {
            console.log(error)
        }
    }
  
    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        let params = new URL(document.location).searchParams;
        let emailTemplate = params.get("key");    
        if (emailTemplate === "Newsletter") {
            MailTracker('Yoga');  
        }  
      }, []);
    
      const MailTracker = async (value) => {
        const payload = { key: value };
        try {
          const response = await fetch('https://api.ayurai.in/api/data/NewsLetterMailTracker', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          });
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          console.log(data);
        } catch (error) {
          console.error('Error:', error);
        }
      };

    return <div className="yoga-step-page">
        <ArrowBackIcon className="backarrow" onClick={()=>{
            navigate(-1)
        }}/>
        <p className="step">{step}</p>
        <p className="pose">{yogas.pose}</p>
        <p className="step-details">
            {yogas.detail}
        </p>
        <p className="steps-title">Steps</p>
        <div className="steps-container">
            {
            steps.map((e,i)=>{
                return<div className="step-card" key={i} >
                    <img src={e.img}></img>
                    <p>{e.name}</p>
                </div>
            })
            }
        </div>
    </div>

}

export default Yoga_steps
