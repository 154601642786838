import React from 'react'
import { useContext } from 'react';
import { contextAPI } from '../Context';
import { useNavigate } from 'react-router-dom';
function Meditation() {
    const { meditation } = useContext(contextAPI)
    const navigate=useNavigate()
    return <div className='pranayama-list'>
        {
            meditation.map((e) => {
                return <div className='pranayama-card' onClick={()=>navigate(`/Meditation/${e.name}`)}>
                    <img src={e.icon}></img>
                    <p className='mb-0'>{e.name}</p>
                </div>
            })
        }
    </div>
}

export default Meditation