import React from 'react'
import { useContext } from 'react';
import { contextAPI } from '../Context';
import { useNavigate } from 'react-router-dom';
function Pranayama() {
    let { pranayama } = useContext(contextAPI)
    let navigate=useNavigate()
    return <div className='pranayama-list'>
        {
            pranayama.map((e) => {
                return <div className='pranayama-card' onClick={()=>navigate(`/Pranayama/${e.name}`)}>
                    <img src={e.icon} ></img>
                    <p className='mb-0'>{e.name}</p>
                </div>
            })
        }
    </div>
}

export default Pranayama