import React from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import OrderSuccess from '../Animations/74394-order-success.json'
import Lottie from 'lottie-react';
import { useEffect, useState, useContext } from 'react';
import { setAuthToken } from '../Auth/setAuthToken';
import { contextAPI } from '../Context';
import { useDispatch } from 'react-redux';
import { RemoveCart } from './CartSlice';
import { useSelector } from 'react-redux/es/hooks/useSelector'
export default function Paymentsuccess() {
    const navigate=useNavigate()

  // const postpayment=async(payment)=>{
  //   try {
  //     let Token = localStorage.getItem('jwt-token')
  //     setAuthToken(Token)
  //     let postpayment = await axios.post(`https://api.ayurai.in/api/data/updatePayment`, {
  //       ...payment
  //     })
  //     if (postpayment.status === 200) {
  //       console.log(postpayment.data)
  //     }
  //   }
  //   catch (error) {
  //     if(error?.response?.data?.error.message==='jwt expired'){
  //       renew()
  //    }
  //   }
  // }

  // let verifyPayment = async () => {
  //   try {
  //     let res = await axios.post(`https://api.ayurai.in/api/data/payment/completepayment`, {
  //       txnid
  //     })

  //     if (res?.status === 200) {
        
  //       if (res?.data?.transaction_details) {
  //         if (res?.data?.transaction_details[txnid]?.status === 'success') {
  //           if (Cart.cartitems) {
  //             const orders = [];
  //             Cart?.cartitems?.map((item,i) => {
  //               const companyId = item.company_id;
  //               const existingOrder = orders.find(order => order.company_id === companyId);
  //               if (existingOrder) {
  //                 const key = `item${Object.keys(existingOrder.cartitems).length}`;
  //                 const totalItemPrice = item.cartQuantity * item.price;

  //                 existingOrder.cartitems[key] = {
  //                   image: item.imgurl,
  //                   company_id: item.company_id,
  //                   price: item.cartQuantity * item.price,
  //                   qty: item.cartQuantity,
  //                   name: item.name,
  //                   discount: "0",
  //                   actualprice: item.orgprice || item.price,
  //                   company: item.company,
  //                   sku: item.sku,
  //                   actualqty: item.qty,
  //                 };

  //                 existingOrder.total += totalItemPrice;
  //               } else {
  //                 const newOrder = {
  //                   company_id: companyId,
  //                   companydiscount: 0,
  //                   orderstatus: "placed",
  //                   orderid: `AYURAI_${Date.now()}`,
  //                   dateandtime: getCurrentDateTime(),
  //                   phone: data?.Shipping_Address?.Phone,
  //                   total:item.cartQuantity * item.price,
  //                   address: `${data?.Shipping_Address?.Address1},${data?.Shipping_Address?.Address2},${data?.Shipping_Address?.City},${data?.Shipping_Address?.State},
  //                           ${data?.Shipping_Address?.Country},${data?.Shipping_Address?.Pin}`,
  //                   email: data?.Shipping_Address?.email,
  //                   cartitems: {},

  //                   customer: [
  //                     {
  //                       billing: {
  //                         name: data?.Shipping_Address?.Name,
  //                         addressLine1: data?.Shipping_Address?.Address1,
  //                         addressLine2: data?.Shipping_Address?.Address1,
  //                         postalCode: data?.Shipping_Address?.Pin,
  //                         city: data?.Shipping_Address?.City,
  //                         state: data?.Shipping_Address?.State,
  //                         country: data?.Shipping_Address?.Country,
  //                         contact: data?.Shipping_Address?.Phone,
  //                         email: data?.Shipping_Address?.email
  //                       },
  //                       shipping: {
  //                         name: data?.Shipping_Address?.Name,
  //                         addressLine1: data?.Shipping_Address?.Address1,
  //                         addressLine2: data?.Shipping_Address?.Address1,
  //                         postalCode: data?.Shipping_Address?.Pin,
  //                         city: data?.Shipping_Address?.City,
  //                         state: data?.Shipping_Address?.State,
  //                         country: data?.Shipping_Address?.Country,
  //                         contact: data?.Shipping_Address?.Phone,
  //                         email: data?.Shipping_Address?.email,
  //                         latitude: "",
  //                         longitude: ""
  //                       }
  //                     }
  //                   ]
  //                 };
                  
  //                 newOrder.cartitems['item0'] = {
  //                   image: item.imgurl,
  //                   company_id: item.company_id,
  //                   price:item.cartQuantity * item.price,
  //                   qty: item.cartQuantity,
  //                   name: item.name,
  //                   discount: "0",
  //                   actualprice: item.orgprice || item.price,
  //                   company: item.company,
  //                   sku: item.sku,
  //                   actualqty: item.qty,
  //                 };
  //                 orders.push(newOrder);
                
  //               }
  //               if(i===Cart.cartitems.length-1){           
  //                 orders.map(async (Data) => {
  //                   try {
  //                     let Token = localStorage.getItem('jwt-token')
  //                     setAuthToken(Token)
    
  //                     let response = await axios.post('https://api.ayurai.in/api/data/updateShop', {
  //                        ...Data
  //                     })
                     
  //                   }
  //                   catch (error) {
  //                     if(error?.response?.data?.error.message==='jwt expired'){
  //                       renew()
  //                    }
  //                   }
  //                 })
                  
  //                 postpayment(res?.data)

  //                 dispatch(RemoveCart())

  //               }
  //             })
  //           }
  //           else{
            
  //           }
  //         }
  //       }
  //     }
  //   }

  //   catch (error) {
  //     console.log(error)
  //   }

  // }

  useEffect(() => {
    setTimeout(()=>{
      navigate('/')
    },3000)
  }, [])

  return <>
    <div className="OrderSuccess">
      <Lottie animationData={OrderSuccess} loop={true}></Lottie>
      <h1 className='order-msg'>Your Order is Placed!</h1>
      <p className='mt-2 order-msg-2'>Thank you for choosing us!</p>

    </div>
  </>
}
