import React, { useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MedicationIcon from '@mui/icons-material/Medication';
import { CartTotal, RemoveCart, Shipping_Charges } from './CartSlice';
import { Shipping_Address } from '../ProfileSlice';
import { useNavigate } from 'react-router-dom';
import PhoneIcon from '@mui/icons-material/Phone';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import MailIcon from '@mui/icons-material/Mail';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import FormControl from '@mui/material/FormControl';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Lottie from "lottie-react";
import offer from '../Animations/animation_lluhelxw.json'
import offerapplied from '../Animations/applied.json'
import { toast, Zoom } from 'react-toastify';
import { contextAPI } from '../Context';
import { useEffect, useContext } from 'react';
import { v4 as uuid } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { setAuthToken } from '../Auth/setAuthToken';
import da from 'date-fns/locale/da';
import { Token } from '@mui/icons-material';
function Checkout() {

  let navigate = useNavigate()
  let cart = useSelector((state) => state.cart)
  let [hash, sethash] = useState('')
  let [Transcation_id, setTranscation] = useState('')
  const [isAddress, setAddress] = useState(false)
  let Data = useContext(contextAPI)
  let profile = useSelector((State) => State.Profile)
  const [open, setOpen] = useState(false);
  let Cart = useSelector((state) => state.cart)
  let userdetails = localStorage.getItem('Userdetails')
  let token = JSON.parse(userdetails)
  let dispatch = useDispatch()
  let data = useSelector((state) => state.Profile)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    dispatch(CartTotal())
    dispatch(Shipping_Charges())
  }, [cart])

  const handleAddressChange = (e) => {
    dispatch(Shipping_Address(e.target.value))
  }

  function getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }

  function getDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }


  const surl = `https://app.ayurai.io/Payment_Success/${Transcation_id} `

  let renew = async () => {

    try {
      let res = await axios.post(`https://api.ayurai.in/auth/renew`, {
        email: userdetails.email,
        token: userdetails.refreshToken
      })
      if (res.status === 200) {
        let data = res.data
        localStorage.setItem('jwt-token', data.access_token)
        Placeorder()
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  const Placeorder = async () => {
    if (Cart.cartitems) {
      const orders = {};
 
      Cart?.cartitems?.forEach((item) => {
        const companyId = item.company_id;
        if (!orders[companyId]) {
          orders[companyId] = {
            companyid: companyId,
            companydiscount: 0,
            orderstatus: "placed",
            orderid: generateOrderId(),
            dateandtime: getCurrentDateTime(),
            phone: data?.Shipping_Address?.Phone,
            deliverycharges: item.deliverycharges,
            discount: Data.coupon ? Math.round(cart.Total / 100 * Data.coupon.percentage).toString() : "0",
            userid: token?.id,
            total: 0,
            address: `${data?.Shipping_Address?.Address1},${data?.Shipping_Address?.Address2},${data?.Shipping_Address?.City},${data?.Shipping_Address?.State},
                    ${data?.Shipping_Address?.Country},${data?.Shipping_Address?.Pin}`,
            email: data?.Shipping_Address?.email,
            cartitems: {},
            partners: {
              orderType: "retailorder",
              orderNumber: generateOrderId(),
              orderDate: getDateTime(),
              expDeliveryDate: "",
              remarks1: "",
              remarks2: "",
              paymentGateway: "PayU",
              shippingCost: 0,
              discount: 0,
              walletDiscount: 0,
              promoCodeDiscount: 0,
              prepaidDiscount: 0,
              paymentMode: 5,
              shippingMethod: 1,
              packageWeight: 100,
              packageHeight: 10,
              packageWidth: 10,
              packageLength: 10,
              paymentTransactionNumber: 1430588472,
              items: [],
              customer: [
                {
                  billing: {
                    name: data?.Shipping_Address?.Name,
                    addressLine1: data?.Shipping_Address?.Address1,
                    addressLine2: data?.Shipping_Address?.Address1,
                    postalCode: data?.Shipping_Address?.Pin,
                    city: data?.Shipping_Address?.City,
                    state: data?.Shipping_Address?.State,
                    country: data?.Shipping_Address?.Country,
                    contact: data?.Shipping_Address?.Phone,
                    email: data?.Shipping_Address?.email
                  },
                  shipping: {
                    name: data?.Shipping_Address?.Name,
                    addressLine1: data?.Shipping_Address?.Address1,
                    addressLine2: data?.Shipping_Address?.Address1,
                    postalCode: data?.Shipping_Address?.Pin,
                    city: data?.Shipping_Address?.City,
                    state: data?.Shipping_Address?.State,
                    country: data?.Shipping_Address?.Country,
                    contact: data?.Shipping_Address?.Phone,
                    email: data?.Shipping_Address?.email,
                    latitude: "",
                    longitude: ""
                  }
                }
              ]
            }
          };
        }
        const itemKey = `item${Object.keys(orders[companyId].cartitems).length}`;
        orders[companyId].cartitems[itemKey] = {
          image: item.imgurl,
          companyid: item.company_id.toString(),
          price: item.cartQuantity * item.price.toString(),
          qty: item.cartQuantity.toString(),
          name: item.name,
          discount: "0",
          actualprice: item?.price,
          company: item.company,
          sku: item.sku.toString(),
          id: item.product_id,
          actualqty: item.qty,
        };

        orders[companyId]?.partners?.items?.push({
          OrderItemId: item?.product_id?.toString(),
          Sku: item?.sku?.toString(),
          productName: item?.name,
          Quantity: item?.cartQuantity.toString(),
          Price: item?.cartQuantity * item.price,
          itemDiscount: 0
        });

        orders[companyId].total += item.cartQuantity * item.price;
      });

     
     
      Object.values(orders).forEach(async (order) => {
        try {
          let Token = localStorage.getItem('jwt-token');
          setAuthToken(Token);
          let response = await axios.post('https://api.ayurai.in/api/data/updateShop', order);
          navigate('/Payment_Success');
        } catch (error) {
          if (error?.response?.data?.error.message === 'jwt expired') {
            renew();
          }
        }
      });

      dispatch(RemoveCart());
    }
  };

  // const Placeorder = async () => {
  //   if (Cart.cartitems) {
  //     const orders = {};

  //     Cart?.cartitems?.forEach((item) => {
  //       const companyId = item.company_id;
  //       if (!orders[companyId]) {
  //         orders[companyId] = {
  //           companyid: companyId,
  //           companydiscount: 0,
  //           orderstatus: "placed",
  //           orderid: generateOrderId(),
  //           dateandtime: getCurrentDateTime(),
  //           phone: data?.Shipping_Address?.Phone,
  //           deliverycharges: item.deliverycharges,
  //           discount: Data.coupon ? Math.round(cart.Total / 100 * Data.coupon.percentage).toString() : "0",
  //           userid: token?.id,
  //           total: 0,
  //           address: `${data?.Shipping_Address?.Address1},${data?.Shipping_Address?.Address2},${data?.Shipping_Address?.City},${data?.Shipping_Address?.State},
  //                   ${data?.Shipping_Address?.Country},${data?.Shipping_Address?.Pin}`,
  //           email: data?.Shipping_Address?.email,
  //           cartitems:{},
  //           partners: {
  //             orderType: "retailorder",
  //             orderNumber: generateOrderId(),
  //             orderDate: getDateTime(),
  //             expDeliveryDate: "",
  //             remarks1: "",
  //             remarks2: "",
  //             paymentGateway: "PayU",
  //             shippingCost: 0,
  //             discount: 0,
  //             walletDiscount: 0,
  //             promoCodeDiscount: 0,
  //             prepaidDiscount: 0,
  //             paymentMode: 5,
  //             shippingMethod: 1,
  //             packageWeight: 100,
  //             packageHeight: 10,
  //             packageWidth: 10,
  //             packageLength: 10,
  //             paymentTransactionNumber: 1430588472,
  //             items:[],
  //             customer: [
  //               {
  //                 billing: {
  //                   name: data?.Shipping_Address?.Name,
  //                   addressLine1: data?.Shipping_Address?.Address1,
  //                   addressLine2: data?.Shipping_Address?.Address1,
  //                   postalCode: data?.Shipping_Address?.Pin,
  //                   city: data?.Shipping_Address?.City,
  //                   state: data?.Shipping_Address?.State,
  //                   country: data?.Shipping_Address?.Country,
  //                   contact: data?.Shipping_Address?.Phone,
  //                   email: data?.Shipping_Address?.email
  //                 },
  //                 shipping: {
  //                   name: data?.Shipping_Address?.Name,
  //                   addressLine1: data?.Shipping_Address?.Address1,
  //                   addressLine2: data?.Shipping_Address?.Address1,
  //                   postalCode: data?.Shipping_Address?.Pin,
  //                   city: data?.Shipping_Address?.City,
  //                   state: data?.Shipping_Address?.State,
  //                   country: data?.Shipping_Address?.Country,
  //                   contact: data?.Shipping_Address?.Phone,
  //                   email: data?.Shipping_Address?.email,
  //                   latitude: "",
  //                   longitude: ""
  //                 }
  //               }
  //             ]
  //           }
  //         };
  //       }
  //       const itemKey = `item${Object.keys(orders[companyId].cartitems).length}`;
  //       orders[companyId].cartitems[itemKey] = {
  //         image: item.imgurl,
  //         companyid: item.company_id.toString(),
  //         price: item.cartQuantity * item.price.toString(),
  //         qty: item.cartQuantity.toString(),
  //         name: item.name,
  //         discount: "0",
  //         actualprice: item.orgprice || item.price,
  //         company: item.company,
  //         sku: item.sku.toString(),
  //         id: item.product_id,
  //         actualqty: item.qty,
  //       };
  //       orders[companyId]?.total += item?.cartQuantity * item?.price;


  //     });

  //     Object.values(orders).forEach(async (order) => {
  //       try {
  //         let Token = localStorage.getItem('jwt-token');
  //         setAuthToken(Token);
  //         let response = await axios.post('https://api.ayurai.in/api/data/updateShop', order);
  //         navigate('/Payment_Success');
  //       } catch (error) {
  //         if (error?.response?.data?.error.message === 'jwt expired') {
  //           renew();
  //         }
  //       }
  //     });

  //     dispatch(RemoveCart());
  //   }
  // };

  function generateOrderId() {
    const timestamp = new Date().getTime();
    const randomSuffix = Math.floor(Math.random() * 1000);
    return `AYURAI_${timestamp}${randomSuffix}`;
  }

  const checkout = () => {
    var data = {
      key: 'Ey2EbY',
      hash: hash,
      txnid: Transcation_id,
      amount: Data.coupon ? ((cart.Total - cart.Total / 100 * Data.coupon.percentage) + cart.deliverycharges).toString() : (cart.Total + cart.deliverycharges).toString(),
      firstname: profile?.Shipping_Address?.Name,
      email: profile?.Shipping_Address?.email,
      phone: profile?.Shipping_Address?.Phone,
      productinfo: 'Aiwell',
      surl: 'http://thirdparty.com/testresponse.php',
      furl: 'http://thirdparty.com/testresponse.php',
    };

    var handlers = {
      responseHandler: function (BOLT) {
        if (BOLT.response.txnStatus == "SUCCESS") {
          Placeorder()
          console.log('Your payment has been successful');
        }
        if (BOLT.response.txnStatus == "FAILED") {
          console.log('Payment failed. Please try again.');
        }
        if (BOLT.response.txnStatus == "CANCEL") {
          console.log('Payment failed. Please try again.');
        }
      },
      catchException: function (BOLT) {
        console.log('Payment failed. Please try again.');
      }
    };
    window.bolt.launch(data, handlers);
  }

  const initiatePayment = async () => {
    if (isAddress) {
      try {
        const response = await axios.post('https://api.ayurai.in/api/data/payment/makepayment',
          {
            amount: Data.coupon ? ((cart.Total - cart.Total / 100 * Data.coupon.percentage) + cart.deliverycharges).toString() : (cart.Total + cart.deliverycharges).toString(),
            txnid: Date.now(),
            phone: profile?.Shipping_Address?.Phone,
            email: profile?.Shipping_Address?.email,
            productinfo: "Aiwell",
            firstname: profile?.Shipping_Address?.Name,
          });

        if (response.status === 200) {
          sethash(response.data.hash)
          setTranscation(response.data.TranscationID)
          handleClickOpen()
        }
      } catch (error) {
        console.error('Error initiating payment:', error.response);
      }
    }
    else {
      toast.info("Please Select a Shipping Address", {
        position: "top-center",
        limit: 1,
        transition: Zoom,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
      })
    }
  };

  return <>
    <div className='Cart-header'>
      <IconButton onClick={() => {
        navigate(-1)
      }}>
        <ArrowBackIcon />
      </IconButton>
      &nbsp; &nbsp; &nbsp;
      Cart items</div>
    <div className='Checkout-page'>
      <div className='Checkout-cart'>
        <div className='Checkout-cart-items'>
          {cart.cartitems.map((e, i) => {
            return <>
              <div className='Checkout-cart-item' key={i}>
                <img src={e?.imgurl}></img>
                <div className='Checkout-cart-item-details'>
                  <p className='mb-0'>{e?.name}</p>
                  <div className='medication-qty'><MedicationIcon></MedicationIcon><p className='mb-0'>{e?.qty}</p>
                    <p>×{e?.cartQuantity}</p></div>
                  <div className='checkout-cart-price'>
                    <p className='mb-0'>₹{e?.price}</p>
                    <div><p className='mb-0'>Total&nbsp;&nbsp;&nbsp;<span>₹{e?.cartQuantity * e?.price}</span></p></div>
                  </div>
                </div>
              </div>
            </>
          })
          }
        </div>
      </div>
      <div className='Checkout-Paymentdetails' >
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} id='add-deliver-location'><p className='mb-0'>Deliver to</p> <IconButton onClick={() => {
          navigate('/Address')
        }}><AddLocationIcon /></IconButton></div>
        <FormControl id='address-select'>
          <InputLabel id="demo-simple-select-label">Address</InputLabel>
          <Select
            label="Address"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            onChange={(e) => {
              handleAddressChange(e)
              setAddress(true)
            }}>{
              profile?.Address?.map((e, i) => {
                return <MenuItem key={i} value={e}>
                  <div className='options'>

                    <p className='mb-0'>{e?.Name}</p>

                    <p id='address' className='mb-0'>{`${e?.Address1},${e?.Address2},${e?.City},${e?.State},
                ${e?.Country},${e?.Pin}`}</p>

                    <p style={{ display: "flex", alignItems: "center", gap: "5px" }} className='mb-0'>
                      <PhoneIcon style={{ fontSize: "medium" }} />
                      {e?.Phone}
                    </p>

                    <p style={{ display: "flex", alignItems: "center", gap: "5px" }} className='mb-0'>
                      <MailIcon style={{ fontSize: "medium" }} />{e?.email}</p>

                  </div>
                </MenuItem>
              })}
          </Select>
        </FormControl>
        {Data?.coupon ?
          <div className='Coupon-section' style={{ backgroundColor: '#E8F5E9' }}>
            <div className='Coupon-section-header'>
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }} className='mb-0'>
                <Lottie animationData={offerapplied} style={{ width: "4rem" }} className='mb-0' />
                <div style={{ padding: "1rem", margin: "5px" }} className='mb-0'>
                  <p id='coupon-name' style={{ color: "#6ab96b" }} className='mb-0'>{Data.coupon.code}&nbsp;<span style={{
                    fontWeight: "500", color: 'black', fontSize: "14px"
                  }} className='mb-0'>applied</span></p>
                  <p className='mb-0'>you have saved ₹{Math.round(cart.Total / 100 * Data.coupon.percentage)} for this order</p>
                </div>
              </div>
              <IconButton style={{ alignSelf: "flex-start" }} onClick={() => {
                Data.setcoupon()
              }}>
                <HighlightOffIcon />
              </IconButton>
            </div>

            <div id="explore-coupon" onClick={() => {
              navigate('/Discount-coupons')
            }}>
              <p className='mb-0'>Change</p>
              <NavigateNextIcon></NavigateNextIcon>
            </div>
          </div> :
          <div className='Coupon-section'>
            <div className='Coupon-section-header'>
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Lottie animationData={offer} style={{ width: "4rem" }} />
                <div style={{ padding: "1rem", margin: "5px" }}>
                  <p style={{ width: "8rem" }} id='coupon-code' className='mb-0'>Get 5% off using code</p>
                  <p id='coupon-name' className='mb-0' >AIWELL</p>
                </div>
              </div>
              <p id="apply-coupon" onClick={() => {
                Data.setcoupon(Data.discountedCoupon[0])
              }} className='mb-0'>Apply</p>
            </div>
            <div id="explore-coupon" onClick={() => {
              navigate('/Discount-coupons')
            }}>
              <p className='mb-0'>Explore Coupons</p>
              <NavigateNextIcon></NavigateNextIcon>
            </div>
          </div>}
        <div className='SubTotal-section'>
          <p className='mb-0'>Sub Total</p>
          <p className='mb-0'>₹{cart.Total}.00</p>
          <p className='mb-0'>Deliver Charge</p>
          {cart.deliverycharges > 0 ? <p>₹ {cart.deliverycharges}</p> : <p className='mb-0'><del>₹0.0</del> &nbsp;&nbsp;<span id='freedelivery'>Free</span></p>}
          {
            Data?.coupon &&
            <>
              <p className='mb-0'>Discount</p>
              <p className='mb-0'>-₹{Math.round(cart.Total / 100 * Data.coupon.percentage)}</p>
            </>
          }
          <p className='mb-0'>Total</p>
          {
            Data?.coupon ?
              <p className='mb-0'>₹{cart.Total - cart.Total / 100 * Data.coupon.percentage + cart?.deliverycharges}</p> :
              <p className='mb-0'>{cart.Total + cart?.deliverycharges}</p>
          }
        </div>

        <div className='place-order' onClick={initiatePayment} > Place Order</div>
      </div>

    </div>
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <ShoppingBagIcon style={{
          color: "skyblue", fontSize: "2rem"
        }}></ShoppingBagIcon> Confirm Order
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Takes 5-7 working days to deliver the product!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={checkout}>
            Confirm
          </Button>
        </>

      </DialogActions>
    </Dialog>
  </>
}

export default Checkout 