import React from 'react'
import { getDatabase, ref, onValue, } from "firebase/database";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react'
function Yoga() {
  let [yogas, setyogas] = useState([])
  let navigate=useNavigate()
  let getData = (Data) => {
    try {
      const db = getDatabase();
      const Ref = ref(db, `/yoga`);
      onValue(Ref, async (snapshot) => {
        try {
          const data = await snapshot.val()
          setyogas(Object.values(data))
        }
        catch (error) {
          console.log(error)
        }

      })

    }
    catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getData()
  }, [])

  return <div className='yoga'>
    {
      yogas.map((e, i) => {
        return <div className='yoga-card' key={i} onClick={()=>{
          navigate(`/yoga/${e.name}`)
      }}>
          <div>
          <p>{e.name}</p>
          <p id='pose-type'>{e.pose}</p>
          </div>
          
        </div>

      })
    }
   
  </div>
}

export default Yoga
