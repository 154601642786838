import React from 'react'
import { useSelector } from 'react-redux/es/hooks/useSelector'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button, IconButton } from '@mui/material';
import Lottie from "lottie-react";
import EmptyCart from '../Animations/animation_llq4z1kr.json'
import { addToCart, removeitem, DecreaseQuantity, CartTotal, Shipping_Charges } from './CartSlice';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
function Cart() {
    let cart = useSelector((state) => state.cart)
    let dispatch = useDispatch()
    let navigate = useNavigate()

    let increaseQuantity = (qty) => {
        dispatch(addToCart(qty))
    }
    let decreseQuantity = (qty) => {
        dispatch(DecreaseQuantity(qty))
    }
    let removeItem = (Product) => {
        dispatch(removeitem(Product))
    }

    useEffect(() => {
        dispatch(CartTotal())
    }, [cart])

    return <>
        <div className='Cart-header'>
            <IconButton onClick={() => {
                navigate(-1)
            }}>
                <ArrowBackIcon />
            </IconButton>
            &nbsp; &nbsp; &nbsp;
            Cart</div>
        {cart?.cartitems.length > 0 ?
            <div className='cartcontent'>
                <div className='cartItems'>
                    {
                        cart?.cartitems?.map((e, i) => {
                            return <div key={i} className='Cartitem'>
                                <div className='cartDetails'>
                                    <img src={e?.imgurl}></img>
                                    <div className='cartDetailedinfo'>
                                        <p className='mb-0'>{e?.name}</p>
                                        <p className='mb-0'>Price&nbsp;&nbsp;&nbsp;<span>₹{e.price}</span></p>
                                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }} className='mb-0'>
                                            <p className='mb-0'>Qty</p>
                                            <div className='qty-bar mb-0'><p
                                                onClick={() => {
                                                    decreseQuantity(e)
                                                }} className='mb-0'>-</p>
                                                <p className='mb-0'>{e.cartQuantity}</p><p onClick={() => {
                                                    increaseQuantity(e)

                                                }} className='mb-0'>+</p></div></div>
                                        <p className='mb-0'> Total&nbsp;&nbsp;<span style={{ color: "green" }}>₹{e.cartQuantity * e.price}</span></p>
                                    </div>
                                </div>
                                <IconButton id="closebtn" onClick={() => {
                                    removeItem(e)
                                }}>
                                    <HighlightOffIcon />
                                </IconButton>
                            </div>
                        })
                    }
                </div>
                <div className='cartInfo'>
                    <div className='cartTotal'><p>Total</p>
                        <p>₹{cart.Total}</p></div>
                    <div className='Checkoutbtn'>
                        <Button id='Checkout' onClick={() => {
                            if (!localStorage.getItem('Userdetails')) {
                                navigate('/Signin')
                            }
                            else {
                                navigate('/Checkout')
                            }
                        }} >CheckOut</Button>
                    </div>
                </div>
            </div> :
            <div className='Cart-empty'>
                <Lottie animationData={EmptyCart} className='Cart-empty-animation' />
                <p>Your cart is empty</p>
                <p>add items to get started</p>
                <Button id='Checkout' style={{ marginTop: "0" }} onClick={() => {
                    navigate('/Shop')
                }}>Continue</Button>
            </div>
        }
    </>
}

export default Cart