import React from 'react'
import { IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import Lottie from 'lottie-react';
import helpcenter from '../Animations/helpcenter.json'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useSelector } from 'react-redux';
function HelpCenter() {
    const navigate = useNavigate()
    const userdetails = JSON.parse(localStorage.getItem('Userdetails'))
    const Profile = useSelector((state) => state.Profile.User_Profile)
    const encodedMessage = `Hi,my name is ${Profile.firstname}.I need your assistance.My user ID is ${userdetails.id}.Can you please help me with`
    return <>
        <div className='P-page'>
            <IconButton onClick={() => {
                navigate(-1)
            }}>
                <ArrowBackIcon />
            </IconButton>

        </div>
        <div className='Reports-Sections'>
            <p>Help Center</p>
            <div className='HelpcenterAnimation'>
                <Lottie animationData={helpcenter} loop={true} />
            </div>
            <div className='helpcentertext'>
                <p>How can we help you?</p>
                it looks like you are experienceing problems we are here to help so please get in touch
            </div>
            <div onClick={() => {
                window.location.href = `mailto:contact@ayurai.io?subject=Help&body=${encodedMessage}`
            }} className='supportcard'>
                <EmailIcon style={{color:'blue',fontSize:"30px"}}/>
                <p>Email us</p>
            </div>
            <div onClick={() => {
                window.open(`https://wa.me/9363135851?text=${encodedMessage}`, '_blank');
            }} className='supportcard'>
            <WhatsAppIcon style={{color:'green',fontSize:"30px"}}/>
            <p>Chat with us</p>
            </div>
        </div>
    </>
}

export default HelpCenter