import React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, IconButton } from '@mui/material';
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { contextAPI } from '../Context';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { SearchOutlined } from '@mui/icons-material'
function Discount() {
    let navigate = useNavigate()
    const [search, setsearch] = useState('')
    let data = useContext(contextAPI)

    const Applycoupon=(coupon)=>{
       data.setcoupon(coupon)
       navigate(-1)
    }
    return <>
        <div className='Cart-header'>
            <IconButton onClick={() => {
                navigate(-1)
            }}>
                <ArrowBackIcon />
            </IconButton>
            &nbsp; &nbsp; &nbsp;
            Discount Coupons</div>
        <div className='Search-bar'>
            <div className='search-box'>
                <SearchOutlined />
                <input type="text" placeholder="Search" onChange={(e) => { setsearch(e.target.value) }} />

            </div>
        </div>
        <div className='Discount-coupon-page'>
            {
                data?.discountedCoupon.filter((s)=>{
                    if(search==''){
                        return s
                    }
                    else if(s.code.toLowerCase().includes(search.toLowerCase())){
                        return s
                    }
                }).map((e, i) => {
                    return <>
                        {
                            e?.show &&
                            <div className='discount-card' key={i}>
                                <p  className='mb-0'>{e?.percentage} % &nbsp;OFF</p>
                                <p  className='mb-0'>{e?.subdesc}</p>
                                <div><p  className='mb-0'> Code:&nbsp;{e?.code}</p>
                                    <div className="apply-coupon" onClick={() => {
                                     Applycoupon(e)
                                    }}>
                                     <ArrowForwardIcon style={{fontSize:"medium"}}></ArrowForwardIcon>
                                        <p  className='mb-0'>Apply</p>
                                    </div>

                                </div>
                                <p  className='mb-0'>{e?.detaildesc}</p>
                            </div>
                        }
                    </>
                })
            }
        </div>
    </>
}

export default Discount