import React from 'react'
import { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import HomeIcon from '@mui/icons-material/Home';
import Divider from '@mui/material/Divider';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import ListItemIcon from '@mui/material/ListItemIcon';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import SettingsIcon from '@mui/icons-material/Settings';
import { SearchOutlined } from '@mui/icons-material'
import RestoreIcon from '@mui/icons-material/Restore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useEffect, useContext } from "react";
import { contextAPI } from '../Context';
import { useNavigate } from 'react-router-dom';
function Lab_test() {
  const [state, setState] = useState({
    left: false,
  });
  const [search, setsearch] = useState('')
  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };
  let data = useContext(contextAPI)
  let navigate = useNavigate()
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className='sidebar'>
        <img src='aiwelllogoa512x512.png' id='aiwell-logo'></img>

        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <p id='listitemtext'>
              Home
            </p>
          </ListItemButton>

        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <p id='listitemtext'>
              Profile
            </p>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <InsertDriveFileIcon />
            </ListItemIcon>
            <p id='listitemtext'>
              Reports
            </p>
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <p id='pharm'>E-Pharm</p>
      <List>

        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <ShoppingBagIcon />
            </ListItemIcon>
            <p id='listitemtext'>
              Shop
            </p>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <ShoppingCartIcon />
            </ListItemIcon>
            <p id='listitemtext'>
              My Orders
            </p>
          </ListItemButton>
        </ListItem>

      </List>
      <Divider />
      <p id='tele'>Teleconsultation</p>
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <PersonSearchIcon />
            </ListItemIcon>
            <p id='listitemtext'>
              Find Doctor
            </p>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <PermContactCalendarIcon />
            </ListItemIcon>
            <p id='listitemtext' >
              My Appointments
            </p>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <FavoriteIcon />
            </ListItemIcon>
            <p id='listitemtext' >
              My Doctors
            </p>
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <p id='Settings'>General</p>
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <p id='listitemtext'>
              Settings
            </p>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>

  );


  return <>
    <div className='Navbar'>
      <div> <Button onClick={() => {
        navigate(-1)
      }}>
        <ArrowBackIcon></ArrowBackIcon></Button></div>
      <div><Button><RestoreIcon /></Button><Button><ShoppingCartIcon /></Button></div>
    </div>
    <div className='All-Products'>
      <div className='Search-bar'>
        <div className='search-box'>
          <SearchOutlined style={{ fontSize: '3rem' }} />
          <input type="text" placeholder="Search" onChange={(e) => { setsearch(e.target.value) }} />

        </div>
      </div>
      <div className='lab-test-lists'>{
        data.lab_tests.filter((s) => {
          if (search === '') {
            return s
          }
          else if (s.name.toLowerCase().includes(search.toLowerCase())) {
            return s
          }
        }).sort((a, b) => a.price - b.price).map((e, i) => {
          return (
            <div className='lab-test-card' key={i} onClick={() => {
              navigate(`/Lab_test/${e.product_id}`)
            }}>
              {e.orgprice && e.orgprice != 0 ?
                <div style={{ position: "absolute", display: "flex", alignSelf: "flex-end" }}>
                  <div className='discount-percentage top-0 '>
                    <p className='mb-0'>{(((e.orgprice - e.price) / e.orgprice) * 100).toFixed(0) + "%" + " " + "off"}</p>
                  </div>
                </div> :
                <></>}
              <img src={e.imgurl} className='mb-0'></img>
              <div className='product-card-details'>
                <p className='mb-0'>{e.name}</p>
                <p className='mb-0'>{e.desc}</p>
                <div className='product-card-detail'>
                  <p className='mb-0'>Recommended for</p>
                  <div id="product-recommended-list" onClick={(e) => { e.stopPropagation(); }}>
                    <p className='mb-0'>{e?.type?.split(",")[0]}</p>

                  </div>
                  {/* <p>{e.subtype}</p> */}
                  <p className='mb-0'>₹ {e.price}</p>
                </div>
              </div>
            </div>
          )
        })

      }
      </div>
    </div>
  </>
}

export default Lab_test